<template lang="pug">
b-button-toolbar(
  :justify='justify'
  :key-nav='keyNav'
)
  slot
</template>

<script>
export const ButtonToolbar = {
  props: {
    justify: {
      type: Boolean,
      default: false,
    },
    keyNav: {
      type: Boolean,
      default: false,
    },
  },
}
export default ButtonToolbar
</script>