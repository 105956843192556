<template lang="pug">
BDropdownGroup(
  :header='header'
) {{ text }}
  slot
</template>
<script>
import { BDropdownGroup } from 'bootstrap-vue'

export const DropdownGroup = {
  components: {
    BDropdownGroup,
  },
  props: {
    header: String,
    text: String,
  }
}
export default DropdownGroup
</script>
