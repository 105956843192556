<template lang="pug">
BButton(
  ref='Button'
  :block='block'
  :disabled='busy || disabled'
  :href='href'
  :variant='variant'
  :size='size'
  :target='target'
  :title='title'
  :to='to'
  :type='type'
  @click="onClick"
)
  span(v-if='busy') #[Spinner.mr-1(small)] {{ busyLabel }}
  slot(v-else)
</template>
<script>
import { componentSizes } from '../constants'
import { Spinner } from '../Spinner.vue'

export const Button = {
  // data() {
  //   return {
  //     toConfirm: !!this.confirm,
  //   }
  // },
  components: {
    Spinner,
  },
  methods: {
    focus() {
      this.$refs['Button'].focus()
    },
    onClick() {
      if (this.confirm) {
        const confirmed = confirm(
          typeof this.confirm === 'string'
            ? this.confirm
            : 'Are you sure?',
        )
        if (!confirmed) {
          return
        }
      }
      this.$emit('click')
    },
  },
  props: {
    block: {
      type: Boolean,
      default: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    busyLabel: {
      type: String,
      default: '...',
    },
    confirm: {
      type: String | Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      validator: s => componentSizes.includes(s),
    },
    target: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Button',
    },
    to: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    variant: {
      type: String,
      default: 'secondary',
    },
  },
}

export default Button
</script>