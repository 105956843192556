<template lang="pug">
BFormSelect(
  :disabled="disabled"
  :options="options"
  :state="state"
  v-model="valueInput"
  v-on="$listeners"
)
  slot
</template>
<script>
export const SelectInput = {
  data() {
    return {
      valueInput: this.value,
    }
  },
  methods: {
    syncValue(v) {
      if (this.valueInput === v) return
      this.valueInput = v
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    number: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    state: {
      type: Boolean,
      default: null,
    },
    value: {
      type: String | Number,
    },
  },
  watch: {
    valueInput() {
      this.$emit('input', this.valueInput)
    },
    value: 'syncValue',
  },
}

export default SelectInput
</script>
