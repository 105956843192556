import { server } from '../../server'

export const add = campaignId => server.post(
  `/user/campaign/${campaignId}`
)
export const addCampaigns = campaignIds => server.post(
  `/user/campaigns`, { campaignIds }
)
export const remove = campaignId => server.delete(
  `/user/campaign/${campaignId}`
)
export const removeSelected = campaignIds => server.post(
  `/user/campaign/removals`, { campaignIds }
)
export const removeAll = async () => server.delete(
  `/user/campaigns`
)
export const removeInactive = async () => server.delete(
  `/user/campaigns/inactive`
)
export const get = () => server.get(`/user/campaigns`)
export const manage = ({
  campaignIds,
  action = 'add',
}) => server.put(
  `/user/campaigns/${action}`, { campaignIds }
)
