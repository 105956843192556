export const compareAndReassign = (payload, defaults, alwaysDefault = []) => {
  const newValues = payload
    // only assign those found in default values
    .filter(field => {
      const defaultValue = defaults.find(({
        key
      }) => key === field.key)

      return !!defaultValue
    })
    .map(field => {
      const defaultValue = defaults.find(({
        key
      }) => key === field.key)

      const result = {
        ...defaultValue,
        ...field
      }

      alwaysDefault.forEach(prop => {
        result[prop] = defaultValue[prop]
      })

      return result
    })

  defaults.forEach(field => {
    const newValue = newValues.find(({
      key
    }) => field.key === key)

    if (!newValue) {
      newValues.push({ ...field })
    }
  })

  return newValues
}
