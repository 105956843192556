// Vue2 mixin which detects if the view is small size
export const SMALL_VIEW_WIDTH = 768

export const useViewSize = {
  computed: {
    isSmallView() {
      return this.$data.viewWidth < SMALL_VIEW_WIDTH
    }
  },
  methods: {
    updateSize() {
      this.$data.viewWidth = window.innerWidth
    }
  },
  data() {
    return {
      viewWidth: SMALL_VIEW_WIDTH + 1,
    }
  },
  created() {
    this.updateSize()
    window.addEventListener('resize', this.updateSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSize)
  },
}
