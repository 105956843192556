import { CurrentUser } from '../users/stores/CurrentUser'

export const BlankCombinedStore = {
  modules: {
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    }
  },
}
