<template lang="pug">
div(
  v-if='items.length'
)
  DropdownHeader {{ title || "Actioned items" }}
  DropdownItem(
    v-if='loading'
    variant='primary'
  )
    Loader
  DropdownItem(
    disabled
    v-if='items.length === 0 && !loading'
    variant='primary'
  ) No recent matches
  RecentListItem(
    v-if='items.length > 0'
    v-for='item in items'
    v-bind='item'
    :key='`campaign-actioned-${item.id}`'
    @select='$emit("select", item.id)'
    variant='primary'
  )

</template>
<script>
import { server } from '../../server'
import RecentListItem from '@/components/RecentListItem.vue'
import { shortenIfLong } from '../../../format/string'
import {
  DropdownItem,
  DropdownHeader,
} from '@/components/Dropdown'
import { Loader } from '@/components/Loader.vue'

export default {
  components: {
    DropdownHeader,
    DropdownItem,
    Loader,
    RecentListItem,
  },
  computed: {
    baseMapper() {
      return ({ id, link, text }) => ({
        id,
        linkHref: link || `/campaign/${id}/details`,
        linkTo: `/${id}`,
        text,
      })
    },
    items() {
      return this.rows.map(this.rowMapper)
    },
    rowMapper() {
      if (typeof this.mapper === 'function')
        return (item) => ({
          ...this.baseMapper(item),
          ...this.mapper(item),
        })
      return this.baseMapper
    },
  },
  created() {
    this.getItems()
  },
  data() {
    return {
      loading: false,
      rows: [],
    }
  },
  props: ['limit', 'mapper', 'title'],
  methods: {
    async getItems() {
      this.loading = true
      const {
        data: { campaigns },
      } = await server.get(`/campaigns/actioned`)
      this.rows = campaigns.map(c => ({
        ...c,
        text: `${c.id} - ${shortenIfLong(c.name)}`,
      }))
      this.loading = false
    },
  },
}
</script>
