import { server } from '../server'

export const createSubscription = async ({
  activities,
  profiles,
  filterSet,
}) => server.post('/factory/subscriptions', {
  activities,
  profiles,
  filterSet,
})
export const getSubscriptions = async () =>
  server.get('/factory/subscriptions')

export const removeSubscription = async id =>
  server.delete(`/factory/subscription/${id}`)
