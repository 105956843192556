<template lang="pug">
b-row(
  :align-h='alignH'
  :align-v='alignV'
  :no-gutters='noGutters'
)
  slot
</template>

<script>
import { layoutAlignX, layoutAlignY } from '../constants'

export const Row = {
  props: {
    alignH: {
      type: String,
      default: null,
      validator: h => layoutAlignX.includes(h),
    },
    alignV: {
      type: String,
      default: null,
      validator: v => layoutAlignY.includes(v),
    },
    noGutters: {
      type: Boolean,
      default: false,
    },
  },
}
export default Row
</script>
