import Ui from './Ui.vue'
import { mountVuePage } from '../mountVuePage'
import { CurrentUser } from '../users/stores/CurrentUser'
import { routes } from './routes'

mountVuePage({
  rootComponent: Ui,
  routes,
  title: 'Looker Admin Tools',
  store: {
    modules: {
      currentUser: {
        namespaced: true,
        ...CurrentUser,
      },
    }
  }
})
