import { getSocketServer } from './getSocketServer'
import { getCurrentUser } from './getCurrentUser'
import { getQueryString } from './getQueryString'
import { log } from '@/utilities/log'

const logger = log('getSocketConnectionUrl')

export async function getSocketConnectionUrl(component = 'common') {
  const socketServer = await getSocketServer()
  const currentUser = await getCurrentUser()
  const queryString = getQueryString({
    currentUserEmail: currentUser.email,
    role: 'subscriber',
  })

  const result = `${socketServer}/?${queryString}`

  logger.info('url', { component, result })

  return result
}
