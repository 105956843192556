import * as Actions from '../actions'
export const WorkSession = {
  state: {
    confirming: false,
    interval: null,
    offered: false,
    active: false,
    startedAt: 0,
    resumedAt: 0,
    duration: 0,
    preferenceAutoAssign: true,
    preferencePrioritizeAssigned: true,
    preferencePrioritizeTouched: true,
    summaryDone: [],
    summaryEdited: [],
    summaryEdits: [],
    summaryClaimed: [],
    detailsLoaded: false,
    detailsLoading: false,
    durationLabel: `00:00:00`
  },
  actions: {
    startInterval({ state, getters, dispatch, commit, rootGetters }) {
      const second = 1000
      const minute = 60 * second
      const hour = 60 * minute
      const padNum = num => `0${num}`.slice(-2)
      const interval = setInterval(() => {
        if (state.active) {
          const duration = getters.getDuration()
          const hours = Math.floor(duration / hour)
          const minutes = Math.floor(
            (duration - (hours * hour)) / minute
          )
          const seconds = Math.floor(
            (duration - (hours * hour) - (minutes * minute)) / second
          )
          const {
            preferenceAutoAssign: autoAssign,
            confirming
          } = state
          const {
            availableCount,
            toAutoAssign,
            hasLessThanMin
          } = rootGetters['sheet/getAssignables']()
          const ready = (
            hasLessThanMin &&
            !confirming &&
            availableCount > 0
          )

          commit(
            'durationLabel',
            `${padNum(hours)}:${padNum(minutes)}:${padNum(seconds)}`
          )

          if (ready) {
            if (autoAssign) {
              return Promise.all(
                toAutoAssign.map(
                  row => dispatch('sheet/triggerActions', { rows: [row] }, { root: true })
                )
              )
            } else {
              commit('confirmWork', true)
            }
          }
        }
      }, 3000)
      commit('interval', interval)
    },
    async getDetails({ commit, rootGetters }) {
      commit('detailsLoading')
      const { data } = await Actions.getWorkSessionDetails(
        rootGetters['sheet/selectedSheetId']
      )
      commit('workSession', data)
    },
    async updatePreferences({ dispatch }, preferences) {
      await Actions.updateWorkSessionPreferences(preferences)

      return dispatch('getDetails')
    },
    async start({ dispatch, rootGetters }) {
      await Actions.startWorkSession(rootGetters['sheet/selectedSheetId'])

      return dispatch('getDetails')
    },
    async stop({ dispatch, rootGetters }) {
      await Actions.stopWorkSession(rootGetters['sheet/selectedSheetId'])

      return dispatch('getDetails')
    },
    confirm({ commit }, payload) {
      commit('confirmWork', payload)
    }
  },
  getters: {
    started: state => (state.startedAt > 0),
    getDuration: state => () => {
      const recordedDuration = state.duration
      const lastTimestamp = (
        state.resumedAt > 0
          ? state.resumedAt
          : state.startedAt
      )
      const durationSinceActive = Date.now() - lastTimestamp

      return (recordedDuration + durationSinceActive)
    },
  },
  mutations: {
    workSession(state, payload) {
      state.detailsLoaded = true
      state.summaryDone = payload.summary.done
      state.summaryClaimed = payload.summary.claimed
      state.summaryEdited = payload.summary.edited
      state.summaryEdits = payload.summary.edits
      state.offered = payload.sessionAttributes.offered
      state.startedAt = payload.sessionAttributes.started_at
      state.resumedAt = payload.sessionAttributes.resumed_at
      state.duration = payload.sessionAttributes.duration
      state.active = payload.sessionAttributes.active
      state.preferenceAutoAssign = payload.preferences.auto_assign
      state.preferencePrioritizeAssigned = payload.preferences.prioritize_assigned
      state.preferencePrioritizeTouched = payload.preferences.prioritize_touched
    },
    interval(state, interval) {
      clearInterval(state.interval)
      state.interval = interval
    },
    durationLabel(state, label) {
      state.durationLabel = label
    },
    detailsLoading(state) {
      state.detailsLoaded = false
    },
    confirmWork(state, isConfirming) {
      state.confirming = !!isConfirming
    },
  },
}
