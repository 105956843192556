<template lang="pug">
BDropdownDivider
</template>
<script>
import { BDropdownDivider } from 'bootstrap-vue'

export const DropdownDivider = {
  components: {
    BDropdownDivider,
  },
}
export default DropdownDivider
</script>