import { server } from '../../server'

export const createLadder = (payload) =>
  server.post(`/ladders`, payload)

export const getLadders = (group) => server.get(`/ladders/${group}`)

export const getUsers = async (admin) =>
  server.get('/users', {
    params: {
      admin: !!admin,
    },
  })

export const joinAlliance = ({ epochId, userEmail }) =>
  server.put(`/ladder/${epochId}/alliance`, {
    userEmail,
    action: 'add',
  })
export const leaveAlliance = (id) =>
  server.put(`/ladder/${id}/alliance`)
export const removeFromAlliance = ({ epochId, userEmail }) =>
  server.put(`/ladder/${epochId}/alliance`, {
    userEmail,
    action: 'remove',
  })
export const getLadder = ({ epochId, refresh = false }) =>
  server.get(`/ladder`, {
    params: {
      epochId,
      refresh: refresh ? 'yes' : 'no',
    },
  })
export const removeLadder = (epochId) =>
  server.delete(`/ladder/${epochId}`)
export const updateLadder = ({ epochId, ...payload }) =>
  server.put(`/ladder/${epochId}`, payload)
export const getPreferences = (component) =>
  server.get(
    component
      ? `/user/preferences/${component}`
      : `/user/preferences`,
  )
export const getTrophies = () => server.get('/user/trophies')
export const getUserReminders = () => server.get(`/user/reminders`)
export const updatePreferences = (payload) =>
  server.put(`/user/preferences`, payload)
