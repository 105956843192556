<template lang="pug">
div
  VueNotifications
  SheetView(
    v-if='!loading && loaded'
  )
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    SheetView: () => import('../sheets/components/SheetView.vue')
  },
  computed: {
    ...mapState('sheet', [
      'availableSheetsLoaded',
      'sheetViewsLoaded',
    ]),
    loaded() {
      return this.availableSheetsLoaded && this.sheetViewsLoaded
    },
  },
  created() {
    this.reload()
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapActions('currentUser', {
      loadCurrentUser: 'get',
    }),
    ...mapActions('sheet', [
      'getSheetViews',
      'getAvailableSheets',
    ]),
    async reload() {
      this.loading = true
      await this.loadCurrentUser()
      await this.getSheetViews()
      await this.getAvailableSheets()
      this.loading = false
    },
  },
}
</script>

