import { server } from '../../server'
import campaignClaims from '../../campaigns/actions/claims'
import AttributeActions from '../../campaigns/actions/attribute'
import CampaignActions from '../../campaigns/actions'
import { getPreferences as getUserPreferences } from '../../users/actions'

export default {
  ...campaignClaims,
  getUserPreferences: (component = 'sheet') =>
    getUserPreferences(component),
  getCategoryEdits({ campaignId, category }) {
    return server.get(`/campaign/${campaignId}/edits/${category}`)
  },
  updateAdopsAssignment({ campaignIds, userId }) {
    return server.post(`/campaigns/adops_assignee`, {
      campaignIds,
      adopsAssignmentId: userId,
    })
  },
  updateCampaignBidMultiplier({ campaignId, multiplier = 1 }) {
    return server.post(`/campaign/${campaignId}/multiplier`, {
      bid_multiplier: multiplier,
    })
  },
  switchCampaignMultiplierUpdatesEnabled:
    AttributeActions.switchMultiplierUpdatesEnabled,
  switchCampaignSubType({ campaignId, newSubType }) {
    return server.post(
      `/campaign/${campaignId}/sub_type/${newSubType}`
    )
  },
  addCampaignNote({ campaignId, note }) {
    return server.post(`/campaign/${campaignId}/notes`, { ...note })
  },
  removeCampaignNote: CampaignActions.notes.remove,
  getViewFilters({ sheetId, viewKey }) {
    return server.get(`/sheet/${sheetId}/view/${viewKey}/filters`)
  },
  addViewFilter({ sheetId, viewKey, field, values }) {
    return server.post(
      `/sheet/${sheetId}/view/${viewKey}/filters/${field}`,
      {
        values,
      }
    )
  },
  removeViewFilters({ sheetId, viewKey, field }) {
    return server.delete(
      `/sheet/${sheetId}/view/${viewKey}/filters/${field}`
    )
  },
  getAvailable() {
    return server.get('/sheets/available')
  },
  getSheetRows(id) {
    return server.get(`/sheet/${id}`)
  },
  getSheetPartials(id) {
    return server.get(`/sheet/${id}/partials`)
  },
  getCampaignPartials({ sheetId, ...options }) {
    return server.post(`/sheet/${sheetId}/partials`, options)
  },
  getSheetCampaignRows({
    campaignIds,
    forceLoad = false,
    sheetId,
  }) {
    return server.post(`/sheet/${sheetId}/campaigns`, {
      campaignIds,
      forceLoad,
    })
  },
  removeSheet(id) {
    return server.delete(`/sheet/${id}`)
  },
  forceExtraction(id) {
    return server.post(`/sheet/${id}/extraction`)
  },
  validateExtraction(options) {
    return server.post(`/sheet/validate`, options)
  },
  validateSheetPath({ path, sheetId }) {
    return server.post(`/sheet/validate/path`, { path, sheetId })
  },
  submitSheet(attributes) {
    return server.post(`/sheet`, attributes)
  },
  updateSheet(attributes) {
    return server.put(`/sheet/${attributes.id}`, attributes)
  },
  getViews() {
    return server.get(`/sheet/views`)
  },
  getViewAttributes(key) {
    return server.get(`/sheet/view/${key}`)
  },
  addView(attributes) {
    return server.post(`/sheet/views`, attributes)
  },
  removeView(key) {
    return server.delete(`/sheet/view/${key}`)
  },
  getCampaignDetails: CampaignActions.getCampaignDetails,
}
