<template lang="pug">
b-badge(
  :variant='variant'
  :pill='pill'
)
  slot
</template>
<script>
export const Badge = {
  props: {
    pill: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'secondary',
    },
  },
}
export default Badge
</script>