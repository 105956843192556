import { server } from '../server'

export const useAxiosResponseInterceptor = vueInstance => {
  server.interceptors.response.use(
    r => Promise.resolve(r),
    error => {
      vueInstance.$notify({
        error,
        text: error.response ? error.response.data.message : error.message,
        type: 'danger',
      })
      return Promise.resolve(error)
    }
  )
}
