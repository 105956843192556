<template lang="pug">
BCardGroup(
  :columns='columns'
  :deck='deck'
)
  slot
</template>

<script>
import { BCardGroup } from 'bootstrap-vue'
export const CardGroup = {
  components: {
    BCardGroup,
  },
  props: {
    columns: {
      type: Boolean,
      default: false,
    },
    deck: {
      type: Boolean,
      default: false,
    },
  },
}
export default CardGroup
</script>
