<template lang="pug">
b-nav(
  :align='align'
  :card-header='cardHeader'
  :fill='fill'
  :justified='justified'
  :small='small'
)
  slot
</template>

<script>
import { navbarAlign } from '../constants'

export const Nav = {
  props: {
    align: {
      type: String,
      default: null,
      validator: a => navbarAlign.includes(a),
    },
    cardHeader: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
    justified: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
}
export default Nav
</script>
