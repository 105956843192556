import Numeral from 'numeral'

const _digits = (num, minDigits) => {
  let digits = minDigits

  const multiplier = () => Math.pow(10, digits)
  const targetSize = Math.pow(10, minDigits - 1)

  if (num !== 0) {
    while (Math.abs(num * multiplier()) < targetSize) {
      digits++
    }
  }

  return digits
}

const _toPrecision = (num, minDigits) => {
  num = parseFloat(num)

  const digits = _digits(num, minDigits)
  const multiplier = Math.pow(10, digits)
  const numRounded = Math.round(num * multiplier) / multiplier

  return {
    rounded: numRounded,
    digits,
  }
}

export const formatPrecision = (num = 0, minDigits = 1) => {
  if (isNaN(num)) return '0'
  const { rounded, digits } = _toPrecision(num, minDigits)
  const formatterStr = `0,0.${'0'.repeat(digits)}`

  return Numeral(rounded).format(formatterStr)
}
