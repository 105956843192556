import { kawaConstants } from '@/constants'

export function matchesAccess({
  access,
  path,
  method,
}) {
  const methodMatching =
    access.method === '*' ||
    access.method.toLowerCase() === method.toLowerCase()
  const pathMatching = access.path.endsWith('*')
    ? path.startsWith(access.path.slice(0, -1))
    : access.path === path
  return methodMatching && pathMatching
}

export const matchAccessWith = ({ list, listType, method, path }) => {
  if (listType === kawaConstants.access.denyListType) {
    return !list.some((access) =>
      matchesAccess({ access, method, path }),
    )
  }
  return list.some((access) =>
    matchesAccess({ access, method, path }),
  )
}
