import { standardiseString } from '@/utilities/standardiseString'

export const getDefaultSections = () => [
  {
    key: 'overview',
    visible: true,
    fullWidth: true,
  },
  {
    hasReload: true,
    key: 'exchanges',
    visible: true,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'scripts',
    visible: false,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'spend',
    label: 'Spend and telemetry',
    visible: true,
    fullWidth: false,
  },
  // {
  //   key: 'telemetry',
  //   label: 'Telemetry plot',
  //   visible: false,
  //   fullWidth: false,
  // },
  {
    key: 'adops',
    visible: true,
    fullWidth: true,
  },
  {
    key: 'comments',
    visible: false,
    fullWidth: false,
  },
  {
    key: 'ads',
    visible: true,
    fullWidth: true,
  },
  {
    hasReload: true,
    key: 'auditLogs',
    label: 'Audit logs',
    visible: true,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'advertiser',
    visible: false,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'bidding',
    label: 'Bidding status (CLEDoS)',
    visible: false,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'factory',
    visible: false,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'organization',
    label: 'Organization info',
    visible: false,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'statistics',
    label: 'Statistics',
    visible: false,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'keywords',
    visible: false,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'notes',
    visible: true,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'broadMatch',
    label: 'Broad match',
    limitedFeature: true,
    visible: true,
    fullWidth: true,
  },
  {
    fullWidth: true,
    hasReload: true,
    key: 'affiliatedCampaigns',
    label: 'Affiliated campaigns',
    visible: false,
  },
  {
    fullWidth: false,
    hasReload: true,
    key: 'geoFenceCompetition',
    label: 'Geo fence competition',
    visible: false,
  },
  {
    fullWidth: false,
    hasReload: true,
    key: 'geoFences',
    label: 'Geo fences',
    visible: false,
  },
  {
    fullWidth: true,
    hasReload: true,
    key: 'model',
    label: 'Model attributes',
    visible: false,
  },
  {
    fullWidth: false,
    hasReload: true,
    key: 'margin',
    label: 'Margin details',
    visible: true,
  },
  {
    key: 'budgetPlans',
    label: 'Budget plans',
    visible: true,
    fullWidth: false,
  },
  {
    hasReload: true,
    key: 'tags',
    visible: true,
  },
  {
    key: 'timing',
    visible: false,
  },
  {
    key: 'category',
    visible: true,
  },
  {
    hasReload: true,
    key: 'reminder',
    fullWidth: true,
    visible: true,
  },
  {
    hasReload: true,
    key: 'retargeting',
    label: 'Retargeting audiences',
    fullWidth: false,
    visible: true,
  },
  {
    hasReload: true,
    key: 'sheets',
    fullWidth: false,
    visible: false,
  },
  {
    key: 'dayparting',
    fullWidth: true,
    hasReload: true,
    visible: true,
  },
]

export const getSectionsByKey = () =>
  getDefaultSections().reduce((byKey, section) => {
    byKey[section.key] = {
      ...section,
      title: section.label || standardiseString(section.key),
    }
    return byKey
  }, {})
