import { ApplicationStore } from '../applicationPerformance/ApplicationStore'
import { KeywordsStore } from '../keywords/KeywordsStore'
import { KeywordSetsStore } from '../keywordSets/KeywordSetsStore'
import { CurrentUser } from '../users/stores/CurrentUser'
import { SocketStore } from '../utilities/vueSockets/SocketStore'

export const KeywordsCombinedStore = {
  modules: {
    application: {
      namespaced: true,
      ...ApplicationStore,
    },
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
    keywords: {
      namespaced: true,
      ...KeywordsStore,
    },
    keywordSets: {
      namespaced: true,
      ...KeywordSetsStore,
    },
    socket: {
      namespaced: true,
      ...SocketStore(),
    },
  },
}
