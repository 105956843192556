<template lang="pug">
div.mt-5
  Row
    Col
    Col(md='8').text-center
      ButtonToolbar
        KeywordSetLoader(
          @loaded='onKeywordSetLoaded'
          @reset='onKeywordSetReset'
        )
        ButtonGroup.mx-1
          Button(
            :disabled='keywordSetsLoading'
            v-if='hasKeywordsInput'
            variant='primary'
            @click='onSaveSet'
          ) Save
          Button.text-primary(
            v-if='selectedKeywordSet && !selectedHasOwnPointer'
            @click='onSavePointer'
          )
            Icon(name='bookmark')
          Button(
            v-if='selectedHasOwnPointer'
            @click='onRemoveSelectedPointer'
            variant='primary'
          )
            Icon(name='bookmark-dash-fill')
    //- Col
    //-   pre {{ foreignPointers }}
    //-   pre {{ myPointers }}
  Row.pt-3
    Col(md='8')
      KeywordSetInput(
        :examples='productKeywordExamples'
        name='product'
        v-model='productKeywords'
      )
      KeywordSetInput(
        :examples='descriptiveKeywordExamples'
        name='descriptive'
        v-model='descriptiveKeywords'
      )
      KeywordSetInput(
        :examples='specificKeywordExamples'
        name='specific'
        v-model='specificKeywords'
      )
      Expanding(
        label='Additional'
        :count='additionalKeywords.length'
      )
        KeywordSetInput(
          :examples='additionalKeywordExamples'
          name='additional'
          v-model='additionalKeywords'
        )
    Col
      h5 Controls
      div
        RangeInput.float-right(
          label='Spread'
          v-model='spreadInput'
          :min='1'
          :max='4'
        )
          template(#append)
            Button(
              @click='onListResults'
              :disabled='!resultCount'
              :variant="list ? 'secondary' : 'primary'"
            ) {{ list ? 'Hide' : 'List' }}
              b.ml-1(v-if='resultCount') ({{ resultCount }})
      template(v-if='resultCount')
        .clearfix
        hr
        h5 Results #[Badge(variant='success') {{ resultCount }}]
          ButtonGroup.float-right(
            v-if='list'
            size='sm'
          )
            Button(
              @click='onToggleContextual'
            ) Switch (!)
            Button.text-primary(
              @click='onSelectResults'
              :disabled='!resultCount'
            )
              Icon(
                name='clipboard-plus'
                label='Select results'
              )
        hr
        SwitchInput(
          v-model='trimResultingKeywords'
          label='Trim to 2 keywords'
        )
        hr
        .keyword-results.p-2(
          :id='keywordResultsContainerId'
          v-if='list && resultCount'
        )
          .keyword-result(
            v-for='(result, idx) in results'
            :key='`keyword-result-${idx}`'
          ) {{ contextualInput ? '!' : '' }}{{ result }}
            KeywordAdderSelector(
              :keyword='result'
            )
</template>
<script>
import { Expanding } from '@/components/Expanding.vue'
import { RangeInput } from '@/components/RangeInput.vue'
import { SwitchInput } from '@/components/SwitchInput.vue'
import { Button, ButtonGroup, ButtonToolbar } from '@/components/Button'
import { Col, Row } from '@/components/Layout'
import { Icon } from '@/components/Icon.vue'
import { Badge } from '@/components/Badge.vue'
import { KeywordAdderSelector } from '../keywords/KeywordAdderSelector.vue'
import { KeywordSetInput } from './KeywordSetInput.vue'
import { KeywordSetLoader } from './KeywordSetLoader.vue'
import { combine } from '../../utilities/array'
import { unique } from '@/utilities/unique'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import log from '@/utilities/log'

const logger = log('KeywordSetsView')

const productKeywordExamples = ['car', 'cars', 'vehicle', 'vehicles']
const additionalKeywordExamples = ['any', 'thing']
const descriptiveKeywordExamples = [
  'dealers',
  'dealer',
  'buy',
  'fast',
]
const specificKeywordExamples = [
  'texascars',
  'texas',
  'houston',
  'ford',
]

export const KeywordSetsView = {
  components: {
    Badge,
    Button,
    ButtonGroup,
    ButtonToolbar,
    Col,
    Expanding,
    Icon,
    KeywordAdderSelector,
    KeywordSetInput,
    KeywordSetLoader,
    RangeInput,
    Row,
    SwitchInput,
  },
  computed: {
    ...mapState('keywordSets', [
      'keywordSetsLoading',
      'selectedKeywordSet',
      'foreignPointers',
      'myPointers',
    ]),
    ...mapGetters('keywordSets', [
      'selectedHasOwnPointer',
    ]),
    hasKeywordsInput() {
      return (
        this.productKeywords.length ||
        this.descriptiveKeywords.length ||
        this.specificKeywords.length ||
        this.additionalKeywords.length
      )
    },
    keywordInputPlaceholder() {
      return 'Paste or type, hit enter to add'
    },
    resultCount() {
      // let count = 0
      // const spread = this.spreadInput
      // const pLen = this.productKeywords.length
      // const dLen = this.descriptiveKeywords.length
      // const sLen = this.specificKeywords.length
      // const aLen = this.additionalKeywords.length

      // if (spread > 0) count = pLen + aLen
      // if (spread > 1) count += 2 * (pLen * sLen)
      // if (spread > 2) count += 2 * (pLen * dLen)
      // if (spread > 3) count += 2 * (sLen * (pLen * dLen))

      return this.results.length
    },
    results() {
      const spread = this.spreadInput
      let results = this.productKeywords.concat(this.specificKeywords)
      if (spread > 1) {
        results = results.concat(
          combine(this.specificKeywords, this.productKeywords),
          combine(this.productKeywords, this.specificKeywords),
        )
      }

      let productDescriptive = []
      let descriptiveProduct = []
      if (spread > 2) {
        productDescriptive = combine(
          this.productKeywords,
          this.descriptiveKeywords,
        )
        descriptiveProduct = combine(
          this.descriptiveKeywords,
          this.productKeywords,
        )
        results = results.concat(
          productDescriptive,
          descriptiveProduct,
        )
      }
      if (spread > 3) {
        results = results.concat(
          combine(this.specificKeywords, productDescriptive),
          combine(productDescriptive, this.specificKeywords),
        )
      }

      if (this.trimResultingKeywords) {
        results = results.map((result) => {
          const parts = result.split(' ')
          if (parts.length > 2) {
            return parts.slice(0, 2).join(' ')
          }
          return result
        })
      }

      return unique(results)
    },
  },
  data() {
    return {
      additionalKeywordExamples,
      additionalKeywords: [],
      additionalKeywordsInput: '',
      contextualInput: false,
      descriptiveKeywordExamples,
      descriptiveKeywords: [],
      descriptiveKeywordsInput: '',
      keywordResultsContainerId: 'keyword-results',
      list: false,
      productKeywordExamples,
      productKeywords: [],
      productKeywordsInput: '',
      specificKeywordExamples,
      specificKeywords: [],
      specificKeywordsInput: '',
      spreadInput: 3,
      trimResultingKeywords: true,
    }
  },
  methods: {
    ...mapActions('keywordSets', [
      'createKeywordSet',
      'createKeywordSetPointer',
      'reload',
      'removeSelectedPointer',
    ]),
    ...mapMutations('keywordSets', [
      'setSelectedKeywordSet',
    ]),
    onKeywordSetLoaded(keywordSet) {
      const {
        additionalKeywords = [],
        descriptiveKeywords = [],
        productKeywords = [],
        specificKeywords = [],
      } = keywordSet
      logger.info('onKeywordSetLoaded', keywordSet)
      this.additionalKeywords = additionalKeywords
      this.descriptiveKeywords = descriptiveKeywords
      this.productKeywords = productKeywords
      this.specificKeywords = specificKeywords
    },
    onKeywordSetReset() {
      this.additionalKeywords = []
      this.descriptiveKeywords = []
      this.productKeywords = []
      this.specificKeywords = []
      this.setSelectedKeywordSet(null)
    },
    onListResults() {
      this.list = !this.list
    },
    async onRemoveSelectedPointer() {
      const { success, message } = await this.removeSelectedPointer()

      this.$notify({
        message,
        success,
      })
    },
    async onSavePointer() {
      const title = prompt('Enter a title for this keyword set pointer')
      if (!title) return
      await this.createKeywordSetPointer({
        title,
        set: this.selectedKeywordSet.setName,
      })
    },
    async onSaveSet() {
      const { success, message } = await this.createKeywordSet({
        additionalKeywords: this.additionalKeywords,
        descriptiveKeywords: this.descriptiveKeywords,
        productKeywords: this.productKeywords,
        specificKeywords: this.specificKeywords,
      })

      this.$notify({
        text: message,
        success,
      })
    },
    onSelectResults() {
      // select text in #keyword-results
      this.$notify({
        text: 'Selecting results...',
        success: true,
      })
      const range = document.createRange()
      range.selectNodeContents(
        document.getElementById(this.keywordResultsContainerId),
      )
      const selection = window.getSelection()
      selection.removeAllRanges()
      selection.addRange(range)
    },
    onToggleContextual() {
      this.contextualInput = !this.contextualInput
    },
  },
}
export default KeywordSetsView
</script>

<style>
.keyword-results {
  border: 1px solid #ccc;
  max-height: 400px;
  overflow-y: scroll;
}

.keyword-result {
  margin-top: 0.3em;
}

/* Give transparent gray border to keyword result on hover */
.keyword-result:hover {
  background: rgba(25, 25, 25, .5);
  /* background-color: #ccc; */
}
</style>
