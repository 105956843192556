import { CurrentUser } from '@/users/stores/CurrentUser'

export const HasukiCombinedStore = {
  modules: {
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
  }
}
