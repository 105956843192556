// import isAsyncFunction from '../../isAsyncFunction'
import VueNotifications from './VueNotifications.vue'
import { notificationEvents } from './notificationEvents'
import log from '../utilities/log'
const logger = log('VueNotificationsPlugin')

export const VueNotificationsPlugin = {
  install(Vue) {
    const data = Vue.observable({
      notifications: [],
    })
    Vue.prototype.$notifications = data.notifications
    Vue.prototype.$notify = function({
      success = true,
      delay = 5000,
      message,
      text = message || 'Unknown message.',
      type = success ? 'success' : 'danger',
    }) {
      const id = Date.now()
      if (['danger', 'warning'].includes(type)) {
        console.warn('alert', arguments)
      }
      logger.info(`notify`, { id, text, type })
      data.notifications = [
        ...data.notifications,
        { id, text, type },
      ]
      notificationEvents.$emit('notification', {
        ...data,
        action: 'add',
      })
      setTimeout(() => {
        data.notifications.splice(
          data.notifications.findIndex(e => e.id === id),
          1,
        )
        notificationEvents.$emit('notification', {
          ...data,
          action: 'remove',
        })
      }, delay)
    }
    Vue.mixin({
      onErrorCaptured(error, component, details) {
        logger.warn('VueNotificationsPlugin errorCaptured', {
          error,
          component,
          details,
        })
        this.$notify({
          error,
          message: error.message,
          type: 'danger',
        })
      },
    })
    Vue.component('VueNotifications', VueNotifications)
  },
}
