<template lang="pug">
div
  KawaNavigation.border-success(
    variant='dark'
    type='dark'
  )
    template(#collapse)
      NavbarBrand Hasuki bulk tools
  Container.pb-5.pt-2
    VueNotifications
    router-view
</template>
<script>
import { Container, NavbarBrand } from '@/components'
import { VueNotifications } from '../vueNotifications/VueNotifications.vue'
import { KawaNavigation } from '@/components/KawaNavigation.vue'
import { routes } from './routes'

export default {
  components: {
    Container,
    KawaNavigation,
    NavbarBrand,
    VueNotifications,
  },
  computed: {
    routes() {
      return routes
    },
  },
}
</script>
