<template lang="pug">
.adder-keyword-selector(
  :class='classes'
  @mouseover='onOver'
  @click='onClick'
)
</template>
<script>
import { mapActions, mapState } from 'vuex'
import log from '@/utilities/log'

const logger = log('KeywordAdderSelector')

export const KeywordAdderSelector = {
  computed: {
    ...mapState('keywords', ['keywords']),
    classes() {
      return `${this.isAdded ? 'bg-success' : 'bg-muted'}`
    },
    isAdded() {
      return this.keywords.includes(this.keyword)
    },
  },
  data() {
    return {
      enabledInput: false,
    }
  },
  methods: {
    ...mapActions('keywords', ['addKeyword', 'removeKeyword']),
    onOver() {
      logger.info('onOver')
      if (!this.isAdded) {
        this.addKeyword(this.keyword)
      }
    },
    onClick() {
      logger.info('onClick', this.keyword)
      if (this.isAdded) {
        this.removeKeyword(this.keyword)
      }
    },
  },
  props: {
    keyword: String,
  },
}

export default KeywordAdderSelector
</script>
<style>
.keyword-result:hover .adder-keyword-selector {
  background: rgba(100, 100, 100, 0.2);
}
.adder-keyword-selector {
  float: right;
  max-width: 5em;
  min-width: 3em;
  border-radius: .1em;
  height: 100%;
  min-height: 1.5em;
}
</style>
