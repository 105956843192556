import { rankOfProjectedSpendToday } from './rankOfProjectedSpendToday'
import { rankOfPerformanceToday } from './rankOfPerformanceToday'
import { rankOfPerformanceYesterday } from './rankOfPerformanceYesterday'
import { rankOfSpendLeftoverYesterday } from './rankOfSpendLeftoverYesterday'
import { rankOfSpendLimit } from './rankOfSpendLimit'

export const defaultSortBy = 'projectedSpendToday'
export const defaultSortDirection = 1
export const sortCalculatorDefinitions = {
  projectedSpendToday: {
    label: 'Projected spend leftover today',
    abbreviatedLabel: 'ST',
    rank: rankOfProjectedSpendToday,
    isDefault: true,
  },
  spendLeftoverYesterday: {
    label: 'Spend leftover yesterday',
    abbreviatedLabel: 'SY',
    rank: rankOfSpendLeftoverYesterday,
  },
  spendLimit: {
    label: 'Daily spend limit',
    abbreviatedLabel: 'DS',
    rank: rankOfSpendLimit,
  },
  performanceToday: {
    label: 'Performance today',
    abbreviatedLabel: 'PT',
    rank: rankOfPerformanceToday,
  },
  performanceYesterday: {
    label: 'Performance yesterday',
    abbreviatedLabel: 'PY',
    rank: rankOfPerformanceYesterday,
  },
}
