<template lang="pug">
b-col(
  :align-self='alignSelf'
  :cols='cols'
  :sm='sm'
  :md='md'
  :lg='lg'
  :xl='xl'
)
  slot
</template>

<script>
import { layoutAlignY } from '../constants'

export const Col = {
  props: {
    alignSelf: {
      type: String,
      default: null,
      validator: a => layoutAlignY.includes(a),
    },
    // Equivalent to `xs`
    cols: {
      type: [Number, String],
      default: null,
    },
    sm: {
      type: [Boolean, Number, String],
      default: null,
    },
    md: {
      type: [Boolean, Number, String],
      default: null,
    },
    lg: {
      type: [Boolean, Number, String],
      default: null,
    },
    xl: {
      type: [Boolean, Number, String],
      default: null,
    },
  },
}
export default Col
</script>
