<template lang="pug">
BTabs(
  :active-nav-item-class='activeNavItemClass'
  :card='card'
  :fill='fill'
  :no-fade='noFade'
  :pills='pills'
  v-model='tabIndex'
  @changed='onChanged'
)
  template(
    v-if='$slots["tabs-end"]'
    #tabs-end
  )
    slot(name='tabs-end')
  slot
</template>
<script>
export const Tabs = {
  data() {
    return {
      tabIndex: this.value,
    }
  },
  methods: {
    onChanged(currentTabs, previousTabs) {
      this.$emit('changed', { currentTabs, previousTabs })
    },
    syncTabIndex(v) {
      if (this.tabIndex === v) return
      this.tabIndex = v
    },
  },
  props: {
    activeNavItemClass: {
      type: String,
      default: '',
    },
    card: Boolean,
    fill: Boolean,
    noFade: Boolean,
    pills: Boolean,
    value: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    tabIndex() {
      this.$emit('input', this.tabIndex)
    },
    value: 'syncTabIndex',
  },
}
export default Tabs
</script>
