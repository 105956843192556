import { getUsers } from '../actions'

export const UsersStore = {
  state: {
    loading: false,
    users: [],
  },
  mutations: {
    loading(state, value) {
      state.loading = value
    },
    users(state, payload) {
      state.users = [...payload]
    },
  },
  actions: {
    async getUsers({ commit }, admin = false) {
      commit('loading', true)
      const {
        data: { users },
      } = await getUsers(admin)
      commit('users', users)
      commit('loading', false)
      return users
    },
  },
}
