<template lang="pug">
b-input-group-addon(
  :append='append'
  :id='id'
  :is-text='isText'
)
  slot
</template>
<script>
export const InputGroupAddon = {
  props: {
    append: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
}
export default InputGroupAddon
</script>
