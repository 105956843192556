import { server } from '../../server'

export default {
  updateFrequencyCap(campaignId, { howManyTimes, hours }) {
    return server.post(`/campaign/${campaignId}/fcap`, {
      how_many_times: howManyTimes,
      hours,
    })
  },
  updateMultiplier({ campaignId, multiplier = 1 }) {
    return server.post(`/campaign/${campaignId}/multiplier`, {
      bid_multiplier: multiplier,
    })
  },
  updateRecency(campaignId, recencyId) {
    return server.post(`/campaign/${campaignId}/recency`, {
      recency: recencyId,
    })
  },
  updateGeoFencingRecency(campaignId, recencyId) {
    return server.post(`/campaign/${campaignId}/geo_fencing_recency`, {
      geo_fencing_recency: recencyId,
    })
  },
  updateSifiCategory({ campaignId, categoryId, category }) {
    return server.put(`/campaign/${campaignId}/sifi_category`, {
      categoryId,
      category,
    })
  },
  switchSubType({ campaignId, newSubType }) {
    return server.post(
      `/campaign/${campaignId}/sub_type/${newSubType}`,
    )
  },
  async switchModel({ campaignId, enable }) {
    return server.post(`/campaign/${campaignId}/model_enabled`, {
      enable: enable ? 1 : 0,
    })
  },
  async switchCampaignsModelEnabled({ campaignIds, enable }) {
    return server.post(`/campaigns/model_enabled`, {
      enable,
      campaignIds,
    })
  },
  async switchCampaignsMultiplierEnabled({ campaignIds, enable }) {
    return server.post(`/campaigns/multiplier_enabled`, {
      enable,
      campaignIds,
    })
  },
  async switchMultiplierUpdatesEnabled({ campaignId, enable }) {
    return server.post(`/campaign/${campaignId}/multiplier_enabled`, {
      enable,
    })
  },
  async removeMonthlyLimit(campaignId) {
    return server.delete(`/campaign/${campaignId}/monthly_limit`)
  },
}
