import { standardiseString } from '../../format/string'
import { activities } from '../_factory/factory-constants'
import { getActivityProfiles, getProfiles, removeUserWorkers } from './actions'

export const FactoryWorkersStore = {
  state: {
    profilesPerActivity: {},
  },
  getters: {
    activityOptions: () =>
      activities.map(value => ({
        value,
        text: standardiseString(value),
      })),
    activityProfilesLoaded: (
      { profilesPerActivity },
      _g,
      { currentUser },
    ) => ({ activity, email = currentUser.email }) =>
      profilesPerActivity[email] &&
      !!profilesPerActivity[email][activity],
    activityProfiles: (
      { profilesPerActivity },
      _g,
      { currentUser },
    ) => ({ activity, email = currentUser.email }) =>
      profilesPerActivity[email] &&
      profilesPerActivity[email][activity]
        ? profilesPerActivity[email][activity]
        : [{ key: null, name: 'Select profile' }],
  },
  actions: {
    async getProfiles(
      { commit, rootState: { currentUser } },
      email = currentUser.email,
    ) {
      const {
        data: { profiles },
      } = await getProfiles(email)
      commit('profiles', { profiles, email })
      return profiles
    },
    async getActivityProfiles(
      { commit, rootState },
      { activity, email = rootState.currentUser.email },
    ) {
      const {
        data: { profiles },
      } = await getActivityProfiles({ activity, email })
      commit('activityProfiles', { activity, email, profiles })
      return profiles
    },
    async removeAllUserWorkers({
      rootState: { currentUser: { email } }
    }, userEmail = email) {
      const response = await removeUserWorkers(userEmail)

      return response
    },
  },
  mutations: {
    activityProfiles(state, { activity, email, profiles }) {
      state.profilesPerActivity = {
        ...state.profilesPerActivity,
        [email]: {
          ...state.profilesPerActivity[email],
          [activity]: profiles,
        },
      }
    },
    profiles(state, { email, profiles }) {
      state.profilesPerActivity = {
        ...state.profilesPerActivity,
        [email]: profiles,
      }
    },
  },
}
