<template lang="pug">
Form(
  :disabled='!inputIsValid'
  @submit.prevent='triggerCampaignIdSubmittal'
)
  FormGroup(
    :state='inputIsValid'
    invalid-feedback='Campaign ID needs to be a positive number...'
  )
    InputGroup(
      :size='inputSize || "lg"'
    )
      template(#prepend)
        Dropdown(
          text='Recent'
          v-if='typeof mapCampaignLink === "function"'
        )
          RecentList(
            component='campaign'
            :mapper='mapCampaignLink'
            title='Recent'
          )
      FormInput(
        v-model='campaignIdInput'
        placeholder=`eg. 12345`
        :state='inputIsValid'
      )
      InputGroupAppend
        Button(
          type='submit'
          :disabled='!inputIsValid'
          :size='inputSize || "lg"'
        ) Submit
</template>

<script>
import { campaignIdIsValid } from '../campaignIdIsValid'
import { mapActions } from 'vuex'
import RecentList from '@/components/RecentList.vue'
import { Button } from '@/components/Button'
import { Dropdown } from '@/components/Dropdown'
import { Form, FormGroup, FormInput } from '@/components/Form'
import { InputGroup, InputGroupAppend } from '@/components/InputGroup'

export default {
  components: {
    Button,
    Dropdown,
    Form,
    FormGroup,
    FormInput,
    InputGroup,
    InputGroupAppend,
    RecentList,
  },
  computed: {
    campaignId() {
      return parseInt(this.campaignIdInput)
    },
    inputIsValid() {
      return campaignIdIsValid(this.campaignId)
    },
  },
  data() {
    return {
      campaignIdInput: null,
    }
  },
  methods: {
    ...mapActions('campaign', ['select']),
    triggerCampaignIdSubmittal() {
      this.select({ campaignId: this.campaignId })
      this.$emit('selected', this.campaignId)
    },
  },
  props: [
    'inputSize',
    'mapCampaignLink',
  ],
}
</script>
