import { CurrentUser } from '../users/stores/CurrentUser'
import { Campaign } from '../campaigns/stores/Campaign'
import { Sheet } from '../sheets/store/Sheet'
import { Statistics } from '../ranking/stores/Statistics'
import { WorkSession } from '../workSession/store/WorkSession'
import { SocketStore } from '../utilities/vueSockets/SocketStore'
import { Bench } from '../bench/stores/Bench'
import { CommentsStore } from '../comments/CommentsStore'

export const SheetCombinedStore = {
  modules: {
    bench: {
      namespaced: true,
      ...Bench,
    },
    campaign: {
      namespaced: true,
      ...Campaign,
    },
    comments: {
      namespaced: true,
      ...CommentsStore,
    },
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
    sheet: {
      namespaced: true,
      ...Sheet,
    },
    socket: {
      namespaced: true,
      ...SocketStore(),
    },
    statistics: {
      namespaced: true,
      ...Statistics,
    },
    workSession: {
      namespaced: true,
      ...WorkSession,
    },
  }
}
