import Ui from './MatcherUi.vue'
import { MatcherCombinedStore } from './MatcherCombinedStore'
import { mountVuePage } from '../mountVuePage'
import { routes } from './routes'

mountVuePage({
  faviconName: 'matching',
  rootComponent: Ui,
  routes,
  store: MatcherCombinedStore,
  title: 'Matcher',
})
