import { Bench } from '../bench/stores/Bench'
import { Campaign } from '../campaigns/stores/Campaign'
import { CurrentUser } from '../users/stores/CurrentUser'
import { View } from '../campaigns/stores/View'
import { CommentsStore } from '../comments/CommentsStore'
import { SocketStore } from '../utilities/vueSockets/SocketStore'
import { ApplicationStore } from '../applicationPerformance/ApplicationStore'
import { FlagsStore } from '../campaignFlags/FlagsStore'
import { Statistics } from '../ranking/stores/Statistics'
import { FactorsStore } from '@/factorModels/FactorsStore'
import { FactoryWorkersStore } from '@/factoryWorkers/FactoryWorkersStore'

export const CampaignCombinedStore = {
  modules: {
    application: {
      namespaced: true,
      ...ApplicationStore,
    },
    bench: {
      namespaced: true,
      ...Bench,
    },
    campaign: {
      namespaced: true,
      ...Campaign,
    },
    comments: {
      namespaced: true,
      ...CommentsStore,
    },
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
    factors: {
      namespaced: true,
      ...FactorsStore,
    },
    factoryWorkers: {
      namespaced: true,
      ...FactoryWorkersStore,
    },
    flags: {
      namespaced: true,
      ...FlagsStore,
    },
    view: {
      namespaced: true,
      ...View,
    },
    socket: {
      namespaced: true,
      ...SocketStore(),
    },
    statistics: {
      namespaced: true,
      ...Statistics,
    },
  },
}
