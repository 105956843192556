export default (val, { item, plainText }) => {
  if (plainText) {
    return val
  }
  const style =
    val === 'None' ? 'opacity: 0.3;' : 'font-weight: normal;'

  return (
    `<span title="${item.historyText}" style='${style}'>` +
    `${val}</span>`
  )
}
