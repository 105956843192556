import { server } from '../../server'

export const get = ({
  campaignIds,
  daysBack = 3,
  serviceAccounts = false,
}) =>
  server.post(`/campaigns/edits`, {
    campaignIds,
    daysBack,
    serviceAccounts,
  })
export const getCampaignCategoryEdits = async ({
  campaignId,
  category,
}) => server.get(`/campaign/${campaignId}/edits/${category}`)
