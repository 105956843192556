<template lang="pug">
BDropdown(
  :dropup='dropup'
  @click='$emit("click")'
  :disabled='disabled'
  :menu-class='menuClass'
  :size='size'
  :text='text'
  :right='right'
  :split='split'
  :variant='variant'
)
  //- Button content
  template(
    v-if='$slots["button-content"]'
    #button-content
  )
    slot(name='button-content')

  //- List of items if provided
  template(
    v-if='items.length'
    v-for='item in items'
  )
    DropdownDivider(
      v-if='item.divider'
      :key='item.key'
    )
    DropdownDivider(
      v-else-if='item.header'
      :key='item.key'
    ) {{ item.text }}
    DropdownItemButton(
      v-else-if='item.click'
      :key='item.key'
      v-bind='item'
      @click='item.click'
    ) {{ item.text }}
    DropdownItem(
      v-else
      :key='item.key'
      v-bind='item'
    ) {{ item.text }}
  //- Content of the dropdown, should include item list
  slot
</template>
<script>
import { BDropdown } from 'bootstrap-vue'
import DropdownDivider from './DropdownDivider.vue'
import DropdownItem from './DropdownItem.vue'
import DropdownItemButton from './DropdownItemButton.vue'

export const Dropdown = {
  components: {
    BDropdown,
    DropdownDivider,
    DropdownItem,
    DropdownItemButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    dropup: Boolean,
    items: {
      type: Array,
      default: () => [],
    },
    menuClass: {
      type: [Array, Object, String],
      default: undefined,
    },
    right: Boolean,
    size: {
      type: String,
      default: 'md',
    },
    split: Boolean,
    text: String,
    variant: {
      type: String,
      default: 'secondary',
    },
  },
}

export default Dropdown
</script>
