import fullCellPointerStyle from './fullCellPointerStyle'
/**
 * Returns a formatted cell value for row actions
 */

export const marginOverridesFormatter = ({
  marginOverridesEnabledUi
}, plainText) => {
  if (plainText) {
    return marginOverridesEnabledUi
  }
  const enabled = marginOverridesEnabledUi === 'Yes'
  return (
    `<div ` +
    `style='${fullCellPointerStyle};` +
    `font-weight:${enabled ? 'bold' : 'normal'}' ` +
    `>${marginOverridesEnabledUi}</div>`
  )
}
