<template lang="pug">
span(:class='textClass') {{ textFormatted }}
</template>

<script>
import formatBoolean from '../../format/boolean'

export const BooleanValueText = {
  computed: {
    textFormatted() {
      return formatBoolean(this.value)
    },
    textClass() {
      return this.value ? 'text-success' : 'text-danger'
    },
  },
  props: {
    value: Boolean,
  },
}

export default BooleanValueText
</script>
