<template lang="pug">
BTextarea(
  ref='Textarea'
  :disabled='disabled'
  :formatter='formatter'
  :lazy-formatter='lazyFormatter'
  :max-rows='maxRows'
  :placeholder='placeholder'
  :rows='rows'
  :state='state'
  @keydown.enter='onEnter'
  v-model='valueInput'
)
</template>

<script>
export const Textarea = {
  data() {
    return {
      valueInput: this.value,
    }
  },
  methods: {
    focus() {
      this.$refs['Textarea'].focus()
    },
    syncValue(v) {
      if (this.valueInput === v) return
      this.valueInput = v
    },
    onEnter() {
      this.$emit('enter', this.value)
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    formatter: Function,
    lazyFormatter: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    maxRows: {
      type: Number,
      default: 10,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 5,
    },
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    valueInput() {
      this.$emit('input', this.valueInput)
    },
    value: 'syncValue',
  },
}

export default Textarea
</script>
