<template lang='pug'>
Icon(name='caret-right-fill')
</template>

<script>
import { Icon } from '@/components/Icon.vue'
export const CampaignAutoReportIcon = {
  components: {
    Icon,
  },
}
export default CampaignAutoReportIcon
</script>