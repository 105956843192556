<template lang='pug'>
Link(
  :href='downloadHref'
) {{ label }}
</template>

<script>
import { Link } from './Link.vue'

export const DownloadUrl = {
  components: {
    Link,
  },
  computed: {
    downloadHref() {
      return `/download/${this.downloadId}`
    }
  },
  props: {
    downloadId: Number | String,
    label: {
      type: String,
      default: 'Download as CSV',
    },
  },
}

export default DownloadUrl
</script>
