import { KeywordListing } from '../keywords/KeywordListing.vue'
import { KeywordSetsView } from '../keywordSets/KeywordSetsView.vue'
import { TopLogsView } from '../topLogs/TopLogsView.vue'

export const routes = [
  {
    path: '/',
    name: 'keywords',
    component: KeywordSetsView,
    title: 'Keywords',
  },
  {
    path: '/top-logs',
    name: 'topLogs',
    component: TopLogsView,
    title: 'Top Logs',
  },
  {
    path: '/listing',
    name: 'listingRemovals',
    component: KeywordListing,
    title: 'Listing & removals',
  },
]
