import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../../public/external/bootstrap/custom.scss'
import './utilities/components/common.css'
import { setFavicon } from './utilities/setFavicon'
import { getSocketConnectionUrl } from './utilities/getSocketConnectionUrl'
import { VueSocket } from './utilities/vueSockets'
import { VueNotificationsPlugin } from './vueNotifications/VueNotificationsPlugin'
import { update as updateTitle } from './utilities/title'
import { AuthPlugin } from './auth/AuthPlugin'
import { createRouter } from './routes'

export async function mountVuePage({
  container = '#container',
  faviconName = 'campaigner',
  rootComponent,
  routes = [],
  socketComponent,
  socketComponents = socketComponent ? [socketComponent] : [],
  store,
  title,
}) {
  Vue.use(VueRouter)
  Vue.use(VueNotificationsPlugin)
  Vue.use(Vuex)
  Vue.use(BootstrapVue)
  Vue.use(AuthPlugin)

  store = new Vuex.Store(store)
  for (const socketComponent of socketComponents) {
    Vue.use(
      VueSocket(socketComponent),
      await getSocketConnectionUrl(socketComponent),
      store,
    )
  }

  const instance = new Vue({
    el: container,
    store,
    render: (html) => html(rootComponent),
    router: createRouter({ routes }),
    mounted() {
      this.$initializeAuth()
    }
  })

  setFavicon(faviconName)

  if (title) {
    updateTitle(title)
  }

  return instance
}
