import Logger from '../../utilities/log'
import {
  capitaliseFirstLetter,
  standardiseString,
} from '../../../format/string'
import { getData } from '../../campaigns/actions/dimensions'
import { dimensions } from '../constants'
import { getViews, updateView, addView, removeView } from '../../users/actions/views'

const logger = Logger('Store:Bench')
const DEFAULT_VIEW_NAME = 'default'
const DEFAULT_RANGE = 7
const DEFAULT_PREFERENCES = {
  lastView: DEFAULT_VIEW_NAME
}
logger.info('init')

const getDefaultState = () => ({
  dataVersion: 0,
  dimensionData: [],
  loading: false,
  rangeDaysFromToday: DEFAULT_RANGE,
  selectedDimensions: [],
  selectedViewName: DEFAULT_VIEW_NAME,
  version: 0,
  views: [getDefaultView()],
})
const getDefaultView = () => ({
  dimensions: [],
  id: DEFAULT_VIEW_NAME,
  name: DEFAULT_VIEW_NAME,
  title: capitaliseFirstLetter(DEFAULT_VIEW_NAME),
  range: DEFAULT_RANGE,
})

export const Bench = {
  state: getDefaultState(),
  getters: {
    availableDimensionOptions: state => dimensions.filter(
      d => !state.selectedDimensions.includes(d)
    ).map(d => ({
      value: d,
      text: standardiseString(d),
    })),
    dataIsLoaded: (state, getters) => (
      state.selectedDimensions.length > 0 &&
      getters.dimensionsToLoad.length === 0
    ),
    dimensionsToLoad: state => state.selectedDimensions.filter(d => !(
      state.dimensionData.find(({ range, dimension }) => (
        range === state.rangeDaysFromToday &&
        dimension === d
      ))
    )),
    preferences: (_s, _g, _rs, rootGetters) => ({
      ...DEFAULT_PREFERENCES,
      ...rootGetters['currentUser/preferencesFor']('bench'),
    }),
    selectedView: state => state.views.find(
      ({ name }) => name === state.selectedViewName
    ),
    viewOptions: state => state.views.map(
      ({ title, name }) => ({ text: title, value: name })
    ),
  },
  mutations: {
    addDimension(state, value) {
      if (dimensions.includes(value)) {
        state.selectedDimensions = [
          ...state.selectedDimensions,
          value,
        ]
        state.version += 1
      }
    },
    loading(state, value) {
      state.loading = (
        typeof value === 'boolean'
          ? value
          : !state.loading
      )
    },
    removeDimension(state, value) {
      const dimensionIdx = state.selectedDimensions.indexOf(value)
      if (dimensionIdx >= 0) {
        state.selectedDimensions.splice(dimensionIdx, 1)
        state.version += 1
      }
    },
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    resetDimensionData(state) {
      state.dimensionData = []
    },
    selectedViewName(state, view) {
      state.selectedViewName = view
    },
    setData(state, payload) {
      payload.forEach(dataset => {
        const storeIdx = state.dimensionData.findIndex(({
          dimension,
        }) => dataset.dimension === dimension)
        if (storeIdx >= 0) {
          state.dimensionData.splice(storeIdx, 1, dataset)
        } else {
          state.dimensionData.push(dataset)
        }
      })
    },
    setRangeDaysFromToday(state, value) {
      state.rangeDaysFromToday = parseInt(value)
      state.version += 1
    },
    setView(state, name) {
      const view = state.views.find(({ name: n }) => n === name)
      if (view) {
        logger.info(`setView`, view)
        state.selectedViewName = name
        state.selectedDimensions = view.dimensions.filter(
          dimension => dimensions.includes(dimension)
        )
        state.rangeDaysFromToday = view.range
      }
    },
    setViews(state, payload) {
      const defaultView = payload.find(({ name }) => name === 'default')
      state.views = defaultView
        ? [...payload]
        : [...payload, getDefaultView()]
    },
    syncDataVersion(state) {
      state.dataVersion = state.version
    },
  },
  actions: {
    async applyView({ commit, getters, state }) {
      const { lastViewName } = getters.preferences
      if (
        lastViewName &&
        state.views.find(
          ({ name }) => (name === lastViewName)
        )
      ) {
        commit('setView', lastViewName)
      } else {
        commit('setView', state.selectedViewName)
      }
    },
    async getData({
      commit,
      dispatch,
      getters,
      rootState,
      state,
    }, force = false) {
      if (force) {
        commit('resetDimensionData')
      }
      if (getters.dimensionsToLoad.length > 0) {
        commit('loading', true)
        const { data } = await getData({
          campaignId: rootState.campaign.id,
          dimensions: getters.dimensionsToLoad,
          range: state.rangeDaysFromToday,
        })
        commit('setData', data.dimensionData)
        commit('loading', false)
        commit('syncDataVersion')
        logger.info('getData result', data)
        await dispatch('updateSelectedView')
      }

      return true
    },
    async getPreferences({ dispatch }) {
      await dispatch(
        'currentUser/getPreferences',
        'bench',
        { root: true }
      )
    },
    async getViews({ commit, dispatch }) {
      const { data: { views } } = await getViews()
      await dispatch('getPreferences')
      commit('setViews', views)
      logger.info(`getViews`, views)

      return views
    },
    async handleDimensionAddition({ commit, dispatch }, dimension) {
      commit('addDimension', dimension)
      await dispatch('getData')
      await dispatch('getViews')
    },
    async handleDimensionRemoval({ commit, dispatch }, dimension) {
      commit('removeDimension', dimension)
      await dispatch('updateSelectedView')
      await dispatch('getViews')
    },
    async handleViewAddition({ commit, dispatch, state }, { title, name }) {
      const { data: { success } } = await addView({
        dimensions: state.selectedDimensions,
        name,
        range: state.rangeDaysFromToday,
        title,
      })
      logger.info(`handleViewAddition`, { title, name, success })
      await dispatch('getViews')
      await dispatch('handleViewSelection', name)
      await dispatch('applyView')

      return success
    },
    async handleViewRemoval({ dispatch, getters }) {
      const { id } = getters.selectedView

      await removeView(id)
      await dispatch(`getViews`)
      await dispatch('handleViewSelection', DEFAULT_VIEW_NAME)
      await dispatch('applyView')

      return true
    },
    async handleViewSelection({ commit, dispatch }, viewName) {
      commit('setView', viewName)
      await dispatch('updateLastViewName')
      await dispatch('getData')

      return true
    },
    async updateLastViewName({ dispatch, state }) {
      logger.info('updateLastViewName', state.selectedViewName)
      return dispatch('currentUser/updatePreference', {
        component: 'bench',
        name: 'lastViewName',
        value: state.selectedViewName,
      }, { root: true })
    },
    async updateSelectedView({ state, getters }) {
      const { id } = getters.selectedView
      const {
        data: { success }
      } = await updateView({
        id,
        dimensions: state.selectedDimensions,
        range: state.rangeDaysFromToday,
      })
      return success
    }
  },
}
