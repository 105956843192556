<template lang="pug">
Container
  BasicLayout.text-center
    h2 Top log search
    InputGroup
      FormInput(
        :disabled='loading'
        v-model='positiveSearchInput'
        placeholder='Type words to search'
        @input='onSearchInput'
      )
      FormInput(
        :disabled='loading'
        v-model='negativeSearchInput'
        placeholder='Type words to exclude'
        @input='onSearchInput'
      )
    hr
    RangeInput(
      :disabled='loading'
      v-model='limitInput'
      :min='100'
      :max='1000'
      :step='100'
      :label='`Limit: ${limitInput}`'
    )
    hr
    Table(
      v-if='rows.length'
      :items='rows'
      :fields='fields'
      :maxHeight='500'
      hideTotal
    )
      template(#cell(action)='data')
        KeywordAdderSelector.bg-secondary(
          :keyword='data.item.name'
        )
    Alert(v-else) Search for keywords in top logs.

</template>

<script>
import { Alert } from '@/components/Alert.vue'
import { BasicLayout, Container } from '@/components/Layout'
import { FormInput } from '@/components/FormInput.vue'
import { InputGroup } from '@/components/InputGroup'
import { Table } from '@/components/Table.vue'
import { RangeInput } from '@/components/RangeInput.vue'
import { searchTopLogs } from './searchTopLogs'
import { KeywordAdderSelector } from '../keywords/KeywordAdderSelector.vue'

const contentTypeToDisplay = 'keyword'

export const TopLogsView = {
  components: {
    Alert,
    BasicLayout,
    Container,
    FormInput,
    InputGroup,
    KeywordAdderSelector,
    RangeInput,
    Table,
  },
  computed: {
    inputIsValid() {
      return (
        this.positiveSearchInput.length > 0 ||
        this.negativeSearchInput.length > 0
      )
    },
    rows() {
      return this.searchResults.find(
        ({ content }) => content === contentTypeToDisplay
      )?.search_results ?? []
    },
  },
  data() {
    return {
      fields: [
        { key: 'name', label: 'Keyword', sortable: true },
        { key: 'score', label: 'Users', sortable: true },
        { key: 'action', label: '' },
      ],
      negativeSearchInput: '',
      positiveSearchInput: '',
      searchResults: [],
      searchTimer: null,
      limitInput: 100,
      loading: false,
    }
  },
  methods: {
    onSearchInput() {
      clearTimeout(this.searchTimer)
      if (this.inputIsValid && !this.loading) {
        this.searchTimer = setTimeout(() => this.search(), 400)
      }
    },
    async search() {
      this.loading = true
      const { data: { results } } = await searchTopLogs({
        positive: this.positiveSearchInput,
        negative: this.negativeSearchInput,
        limitInput: this.limitInput,
      })
      this.searchResults = results
      this.loading = false
    },
  },
  mounted() {},
  props: {},
  watch: {},
}

export default TopLogsView
</script>
