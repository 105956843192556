<template lang="pug">
span
  Icon(
    :class='iconClass'
    :name='iconName'
  )
</template>

<script>
import { Icon } from '@/components/Icon.vue'
export const BooleanIcon = {
  components: {
    Icon,
  },
  computed: {
    iconClass() {
      return this.value ? 'text-success' : 'text-danger'
    },
    iconName() {
      return this.value ? 'check-circle-fill' : 'x-circle'
    },
  },
  props: {
    value: Boolean,
  },
}

export default BooleanIcon
</script>
