<template lang="pug">
div
  KawaNavigation
  router-view
</template>
<script>
import KawaNavigation from '@/components/KawaNavigation.vue'
export default {
  components: {
    KawaNavigation,
  },
}
</script>