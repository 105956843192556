import { server } from '../../server'

export const get = id => server.get(
  `/campaign/${id}/margins`
)
export const update = async ({
  campaignId,
  marginTypeId,
  rate
}) => server.put(
  `/campaign/${campaignId}/margin`, { marginTypeId, rate }
)
