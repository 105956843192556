import { server } from '../../server'

const MANY_DOMAINS_LIMIT = 100

export const removeAll = campaignId =>
  server.delete(`/campaign/${campaignId}/listed_domains`)

export const manage = ({ campaignId, action, domains }) =>
  server.put(`/campaign/${campaignId}/listed_domains`, {
    action,
    domains,
  })

export const manageByNames = ({ campaignId, action, listType, names }) =>
  names.length > MANY_DOMAINS_LIMIT
    ? manageManyByNames({ campaignId, action, listType, names })
    : server.put(`/campaign/${campaignId}/listed_domains/names`, {
      action,
      names,
    })

export const manageManyByNames = ({ campaignId, action, listType, names }) =>
  action === 'add'
    ? listDomainsByNames({
      campaignIds: [campaignId], domains: names, listType
    })
    : unlistDomainsByNames({
      campaignIds: [campaignId],
      domains: names,
      listType
    })

export const listDomainsByNames = async ({
  campaignIds,
  domains,
  listType,
  replace,
}) =>
  server.post(`/campaigns/listed_domain_names`, {
    campaignIds,
    domains,
    listType,
    replace,
  })

export const unlistDomainsByNames = async ({
  campaignIds,
  domains,
  listType,
}) =>
  server.post(`/campaigns/unlisted_domain_names`, {
    campaignIds,
    domains,
    listType,
  })
