<template lang="pug">
BDropdownText {{ text }}
  slot
</template>
<script>
import { BDropdownText } from 'bootstrap-vue'

export const DropdownText = {
  components: {
    BDropdownText,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  }
}
export default DropdownText
</script>
