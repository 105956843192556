// eslint-disable-next-line no-undef
export const kawaEnvironment = KAWA_ENVIRONMENT
// eslint-disable-next-line no-undef
export const kawaVersion = KAWA_VERSION
// eslint-disable-next-line no-undef
export const kawaConstants = KAWA_CONSTANTS
// eslint-disable-next-line no-undef
export const masterUrl = __MASTER_URL__
// eslint-disable-next-line no-undef
export const serverUrl = __SERVER_URL__
export const clientSubPath = ''
