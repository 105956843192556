import { CurrentUser } from '@/users/stores/CurrentUser'
import { TestSheetStore } from './store'

export const TestSheetCombinedStore = {
  modules: {
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
    sheet: {
      namespaced: true,
      ...TestSheetStore,
    },
  },
}
