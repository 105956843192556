import { modelFactorMultiplierTypeId } from '../campaigns/campaign-constants'
import log from '../utilities/log'
import {
  getCampaignFactors,
  setCampaignModelEnabled,
  setCampaignModelType,
  setFactorModelAttribute,
  setModelFactorsEnabled,
} from './actions'

const logger = log('FactorsStore')

export const FactorsStore = {
  state: {
    active: false,
    attributeEditLogs: [],
    campaignId: null,
    editLogs: [],
    enabled: false,
    factorModelAttributes: null,
    factors: [],
    loading: false,
    modelFactorsEnabled: false,
    modelIsFactorMultipliers: false,
    modelType: null,
    recorded: false,
  },
  getters: {
    modelTypeIsFactorMultipliers: (state) =>
      state.modelType?.id === modelFactorMultiplierTypeId,
  },
  mutations: {
    attributeEditLogs(state, value) {
      state.attributeEditLogs = [...value]
    },
    editLogs(state, value) {
      state.editLogs = [...value]
    },
    enabled(state, value) {
      state.enabled = !!value
    },
    factorModelAttributes(state, value) {
      state.factorModelAttributes = value
    },
    factors(state, payload = []) {
      state.factors = payload
    },
    campaignId(state, value) {
      state.campaignId = value
    },
    modelFactorsEnabled(state, value) {
      state.modelFactorsEnabled = !!value
    },
    modelIsFactorMultipliers(state, value) {
      state.modelIsFactorMultipliers = !!value
    },
    modelType(state, value) {
      state.modelType = value
    },
    loading(state, value) {
      state.loading = !!value
    },
    recorded(state, value) {
      state.recorded = !!value
    },
  },
  actions: {
    async getData({ commit, state }, campaignId = state.campaignId) {
      commit('loading', true)
      const { data } = await getCampaignFactors(campaignId)
      logger.info('getData', data)
      commit('campaignId', campaignId)
      commit('attributeEditLogs', data.factorModelAttributeEditLogs)
      commit('editLogs', data.editLogs)
      commit('factors', data.factors)
      commit('factorModelAttributes', data.factorModelAttributes)
      commit('enabled', data.modelEnabled)
      commit('modelFactorsEnabled', data.modelFactorsEnabled)
      commit(
        'modelIsFactorMultipliers',
        data.modelIsFactorMultipliers,
      )
      commit('modelType', data.modelType)
      commit('recorded', data.modelFactorsRecorded)
      commit('loading', false)
    },
    async getIfNotLoaded({ state, dispatch }, campaignId) {
      if (state.campaignId !== campaignId && !state.loading) {
        await dispatch('getData', campaignId)
      }
    },
    async setCampaignFactorMultipliersEnabled(
      { commit, dispatch },
      enable,
    ) {
      commit('loading', true)
      await dispatch('setCampaignModelEnabled', enable)
      commit('loading', false)
      await dispatch('getData')
    },
    async setCampaignModelEnabled({ state }, value) {
      const { data } = await setCampaignModelEnabled({
        campaignId: state.campaignId,
        enable: value,
      })
      return data
    },
    async setFactorModelAttribute({ commit, state }, options) {
      commit('loading', true)
      const { data } = await setFactorModelAttribute({
        campaignId: state.campaignId,
        ...options,
      })
      commit('loading', false)

      return data
    },
    async setModelFactorsEnabled({ commit, dispatch, state }, value) {
      commit('loading', true)
      const { data } = await setModelFactorsEnabled({
        campaignId: state.campaignId,
        enable: value,
      })
      commit('loading', false)
      await dispatch('getData')
      return data
    },
    async setModelType(
      { state: { campaignId } },
      modelType = modelFactorMultiplierTypeId,
    ) {
      const { data } = await setCampaignModelType({
        campaignId,
        modelType,
      })
      return data
    },
  },
}
