import { unavailableLabel } from '../../format/string'
import log from '../utilities/log'

const logger = log('ApplicationStore')

/* {
  "vertica": {
    "concurrency": 30,
    "utilization": 0,
    "overflow": 0,
    "running": 0,
    "waiting": 0
  },
  "postgres": {
    "concurrency": 120,
    "utilization": 0,
    "overflow": 0,
    "running": 0,
    "waiting": 0
  },
  "reportingDay": 8291,
  "reportingHour": 5,
  "serverTime": "September 13th 2022, 5:50 am",
  "timeInt": 1663066235926,
  "version": "6.21.0"
} */

export const ApplicationStore = {
  state: {
    postgres: {},
    reportingDay: 0,
    reportingHour: 0,
    reportingQueueLength: null,
    reportsCompleted: null,
    serverTime: '',
    serverTimeInt: 0,
    serverVersion: '',
    vertica: {},
    // eslint-disable-next-line no-undef
    clientVersion: KAWA_VERSION,
  },
  getters: {
    dbStatusClass: (state) => (db) =>
      state[db].overflow > 0
        ? 'danger'
        : state[db].utilization > 0.8
          ? 'warning'
          : 'success',
    infoObtained: (state) => state.serverTimeInt > 0,
    serverTimeLocal: (state) =>
      state.serverTimeInt > 0
        ? state.serverTime
        : unavailableLabel,
    versionsMatch: (state) =>
      state.serverVersion === state.clientVersion,
  },
  mutations: {
    info(
      state,
      {
        postgres,
        reportingDay,
        reportingHour,
        serverTime,
        timeInt,
        version,
        vertica,
      },
    ) {
      state.postgres = postgres
      state.reportingDay = reportingDay
      state.reportingHour = reportingHour
      state.serverTime = serverTime
      state.serverTimeInt = timeInt
      state.serverVersion = version
      state.vertica = vertica
    },
    reporting(state, {
      queue,
      reportsCompleted,
    }) {
      state.reportingQueueLength = queue
      state.reportsCompleted = reportsCompleted
    },
  },
  actions: {
    socket_serverTime: {
      root: true,
      handler({ commit }, payload) {
        commit('info', payload)
      },
    },
    socket_kawaInformation: {
      root: true,
      handler({ commit }, payload) {
        logger.info('kawaInformation', payload)
        commit('reporting', payload)
      },
    },
  },
}
