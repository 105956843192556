export const yesNoControlFormatter = (v, { plainText }) =>
  plainText
    ? v
    : `<span class="text-${
        v === 'Yes'
          ? 'secondary'
          : v === 'No'
          ? 'warning'
          : 'muted font-weight-light'
      }">${v}</span>`
