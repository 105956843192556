<template lang="pug">
BProgress(:height='height' :max='maxValue')
  BProgressBar(
    v-for='bar of barAttributes'
    :animated='bar.animated'
    :class='bar.className'
    :key='`progress-bar-${bar.key}`'
    :striped='bar.striped'
    :value='bar.value'
    :variant='bar.variant'
  )
</template>

<script>
export const ProgressBar = {
  computed: {
    barAttributes() {
      return this.bars.map(({
        className = 'regular-progress-bar',
        key,
        value = 0,
        variant = 'default',
        ...bar
      }, idx) => ({
        className,
        key: key || `progressBar-${idx}`,
        value,
        variant,
        ...bar
      }))
    },
  },
  props: {
    bars: Array,
    height: {
      type: String,
      default: '6px',
    },
    maxValue: {
      type: Number,
      default: 100,
    },
  },
}
export default ProgressBar
</script>
