<template lang="pug">
DropdownItem.recent-list-item(
  @click='$emit("select", id)'
  :to='linkTo'
  :href='linkHref'
)
  span.d-flex(v-if='isCampaign && attributes')
    CampaignStatusIndicator(:campaign='attributes')
    span.flex-fill.mx-1 {{ id }} - {{ attributes.nameShort }}
    ButtonGroup(size='sm')
      CampaignAutoReportButton(
        @click.native.stop
        :campaignId='id'
      )
      CampaignDetailsButton(
        @click.native.stop
        :campaignId='id'
      )
  span(v-else) {{ text }}
</template>
<script>
import CampaignAutoReportButton from '../campaigns/components/CampaignAutoReportButton.vue'
import CampaignDetailsButton from '../campaigns/components/CampaignDetailsButton.vue'
import CampaignStatusIndicator from '../campaigns/components/CampaignStatusIndicator.vue'
import { ButtonGroup } from './Button'
import { DropdownItem } from './Dropdown'

export const RecentListItem = {
  components: {
    ButtonGroup,
    CampaignAutoReportButton,
    CampaignDetailsButton,
    CampaignStatusIndicator,
    DropdownItem,
  },
  computed: {
    isCampaign() {
      return this.component === 'campaign'
    },
  },
  props: [
    'attributes',
    'component',
    'id',
    'linkHref',
    'linkTo',
    'text',
  ],
}

export default RecentListItem
</script>

<style>
.recent-list-item .campaign-status-indicator {
  opacity: 0.6;
}
</style>