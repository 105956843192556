import { mountVuePage } from '../mountVuePage'
import { KeywordsCombinedStore } from './KeywordsCombinedStore'
import { routes } from './routes'

mountVuePage({
  faviconName: 'keywords',
  store: KeywordsCombinedStore,
  rootComponent: () => import('./KeywordsUi.vue'),
  routes,
})
