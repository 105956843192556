<template lang="pug">
b-input-group(
  :append='append'
  :id='id'
  :prepend='prepend'
  :size='size'
)
  //- Prepended content
  template(
    v-if='$slots["prepend"]'
    #prepend
  )
    slot(name='prepend')

  //- Appended content
  template(
    v-if='$slots["append"]'
    #append
  )
    slot(name='append')

  //- Main content, such as form inputs
  slot
</template>
<script>
import { componentSizes } from '../constants'

export const InputGroup = {
  props: {
    append: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    prepend: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
      validator: value => componentSizes.includes(value),
    },
  },
}
export default InputGroup
</script>
