<template lang="pug">
.keyword-set.my-1
  Row
    Col.pt-2(:md='width')
      h5(
        v-if='showTitle'
      ) {{ capitaliseFirstLetter(name) }} keywords
      Textarea(
        v-model='keywordsInput'
        :placeholder='keywordInputPlaceholder'
        @enter='onAddKeywords'
      )
    Col.pt-2(:md='width')
      .keyword-set-examples(v-if='!value.length && examples.length')
        h5 Examples
        .keyword-set-list.text-muted
          .keyword-example(
            v-for='(keyword, idx) in examples'
            :key='`example-${name}-${idx}`'
          ) {{ keyword }}
      .keyword-set-keywords(v-else)
        h5 Keywords #[Badge(variant='success') {{ value.length }}]
          Button.float-right(
            @click='onRemoveAll'
            size='sm'
          )
            Icon(name='x')
        .keyword-set-list
          .keyword.clearfix(
            v-for='(keyword, idx) in value'
            :key='`keyword-${name}-${idx}`'
            @click='onRemoveKeyword(idx)'
          ) {{ keyword }}
  hr
</template>

<script>
import { Badge } from '@/components/Badge.vue'
import { Button } from '@/components/Button'
import { Col, Row } from '@/components/Layout'
import { Icon } from '@/components/Icon.vue'
import { Textarea } from '@/components/Textarea.vue'
import { capitaliseFirstLetter } from '../../format/string'
import { removeDuplicates } from '../../utilities/array'
export const KeywordSetInput = {
  components: {
    Badge,
    Button,
    Col,
    Icon,
    Row,
    Textarea,
  },
  computed: {
    width() {
      return this.fullWidth ? 12 : 6
    },
    keywordInputPlaceholder() {
      return 'Paste or type, hit enter to add'
    },
    showTitle() {
      return !!this.name
    }
  },
  data() {
    return {
      keywordsInput: '',
    }
  },
  methods: {
    capitaliseFirstLetter,
    onAddKeywords() {
      const newKeywords = this.keywordsInput
        .split(/\n/)
        .map(keyword => keyword.trim())
        .filter(keyword => keyword.length)
      this.resetInput()
      this.$emit('input', removeDuplicates([...this.value, ...newKeywords]))
    },
    onRemoveAll() {
      this.$emit('input', [])
    },
    onRemoveKeyword(idx) {
      this.$emit(
        'input',
        this.value.filter((_k, i) => i !== idx)
      )
    },
    resetInput() {
      this.keywordsInput = ''
    },
  },
  props: {
    examples: {
      type: Array,
      default: () => [],
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    name: String,
    value: {
      type: Array,
      default: () => [],
    },
  },
}

export default KeywordSetInput
</script>

<style>
.keyword-set-list {
  border: 1px solid #ccc;
  padding: .5em;
  min-width: 10em;
}
.keyword {
  cursor: pointer;
}
.keyword:hover {
  background-color: red;
}

.keyword-example {
  font-style: italic;
}
</style>