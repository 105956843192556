<template lang="pug">
b-popover(
  :placement='placement'
  :show.sync='showSync'
  :target='target'
  :title='title'
  :triggers='triggers'
  :variant='variant'
)
  //- Title content
  template(
    v-if='$slots.title'
    #title
  )
    slot(name='title')

  //- Main content
  slot
</template>

<script>
import { popperPlacements } from './constants'

export { PopoverIcon } from './PopoverIcon.vue'
export const Popover = {
  props: {
    placement: {
      type: String,
      default: 'right',
      validator: p => popperPlacements.includes(p),
    },
    show: {
      type: Boolean,
      default: false,
    },
    target: {
      type: [HTMLElement, SVGElement, Function, Object, String],
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    triggers: {
      type: [Array, String],
      default: 'click',
    },
    variant: {
      type: String,
      default: null,
    },
  },
  // Create a `showSync` data prop and use watchers
  // to preserve the show.sync functionality
  data() {
    return {
      showSync: this.show,
    }
  },
  watch: {
    show(newValue) {
      this.showSync = newValue
    },
    showSync(newValue) {
      this.$emit('update:show', newValue)
    },
  },
}
export default Popover
</script>
