import { Campaign } from '../campaigns/stores/Campaign'
import { CurrentUser } from '../users/stores/CurrentUser'
import { View } from '../campaigns/stores/View'
import { FactoryWorkersStore } from '../factoryWorkers/FactoryWorkersStore'
import { FactorySubscriptionsStore } from '../factorySubscriptions/FactorySubscriptionsStore'
import { FactoryStateStore } from '../factoryState/FactoryStateStore'
import { UsersStore } from '../users/stores/UsersStore'

export const FactoryCombinedStore = {
  modules: {
    campaign: {
      namespaced: true,
      ...Campaign,
    },
    currentUser: {
      namespaced: true,
      ...CurrentUser
    },
    factoryWorkers: {
      namespaced: true,
      ...FactoryWorkersStore,
    },
    factorySubscriptions: {
      namespaced: true,
      ...FactorySubscriptionsStore,
    },
    factoryState: {
      namespaced: true,
      ...FactoryStateStore,
    },
    users: {
      namespaced: true,
      ...UsersStore,
    },
    view: {
      namespaced: true,
      ...View,
    },
  }
}
