import { addKeywordsToCampaigns } from '../campaigns/actions/keywords'
import { getCampaignValidationDetails } from './actions'

export const KeywordsStore = {
  state: {
    campaigns: [],
    keywords: [],
    loading: false,
  },
  getters: {
    campaignIds: ({ campaigns }) => campaigns.map(c => c.id),
  },
  mutations: {
    setCampaigns(state, campaigns) {
      const campaignIds = []
      state.campaigns = campaigns.filter(c => {
        if (campaignIds.includes(c.id)) return false
        campaignIds.push(c.id)
        return true
      })
    },
    setKeywords(state, keywords) {
      state.keywords = [...keywords]
    },
    setLoading(state, loading) {
      state.loading = !!loading
    },
  },
  actions: {
    addKeyword({ commit, state }, keyword) {
      if (state.keywords.includes(keyword)) return
      commit('setKeywords', [keyword, ...state.keywords])
    },
    removeKeyword({ commit, state }, keyword) {
      const keywords = [...state.keywords]
      const idx = state.keywords.indexOf(keyword)
      if (idx === -1) return
      keywords.splice(idx, 1)
      commit('setKeywords', keywords)
    },
    removeCampaign({ commit, state }, campaignId) {
      const campaigns = [...state.campaigns]
      const idx = campaigns.findIndex(c => c.id === campaignId)
      if (idx === -1) return
      campaigns.splice(idx, 1)
      commit('setCampaigns', campaigns)
    },
    async getCampaigns({ commit, state }, campaignIds) {
      commit('setLoading', true)
      const {
        data: { campaigns = [] },
      } = await getCampaignValidationDetails(campaignIds)
      const newCampaigns = [
        ...campaigns,
        ...state.campaigns,
      ]
      commit('setCampaigns', newCampaigns)
      commit('setLoading', false)
    },
    async addKeywordsToCampaigns({ commit, getters, state }) {
      commit('setLoading', true)
      const { data: { message, success } } = await addKeywordsToCampaigns({
        campaignIds: getters.campaignIds,
        keywords: state.keywords,
      })
      commit('setLoading', false)
      return { message, success }
    },
  },
}
