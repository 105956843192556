<template lang="pug">
BListGroup(
  :flush='flush'
  :horizontal='horizontal'
)
  slot
  ListItem(
    v-if='items.length'
    v-for='item in items'
    :key='item.id'
    :variant='item.variant'
  )
    slot(
      v-if="$slots.item"
      name='item'
      :item='item'
    )
    template(v-else) {{ item.text }}
</template>
<script>
import { ListItem } from './ListItem.vue'

export const List = {
  components: {
    ListItem,
  },
  props: {
    flush: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: [Boolean, String],
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}

export default List
</script>