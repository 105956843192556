<template lang="pug">
BDatepicker.date-input(
  :boundary='boundary'
  :date-format-options='dateFormatOptions'
  :disabled='disabled'
  :max='max'
  :min='min'
  :placeholder='placeholder'
  :state='state'
  v-model='valueInput'
)
</template>

<script>
export const DateInput = {
  data() {
    return {
      valueInput: this.value,
    }
  },
  methods: {
    handleInput(v) {
      if (v !== this.value) {
        this.$emit('input', v)
      }
    },
    syncValue(v) {
      if (v !== this.valueInput) {
        this.valueInput = v
      }
    },
  },
  props: {
    boundary: {
      type: [HTMLElement, String],
      default: 'scrollParent',
    },
    dateFormatOptions: {
      type: Object,
      default: () => ({
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        weekday: 'short',
      }),
    },
    disabled: Boolean,
    max: String | Date,
    min: String | Date,
    placeholder: String,
    state: {
      type: Boolean,
      default: null,
    },
    value: String,
  },
  watch: {
    value: 'syncValue',
    valueInput: 'handleInput',
  },
}

export default DateInput
</script>

<style>
.date-input > label {
  min-width: 10em;
}
</style>