<template lang="pug">
#notifications-container
  Alert(
    dismissible
    v-for='notification in notifications'
    :key='`notification-${notification.id}`'
    show
    :variant='notification.type'
  ) {{ notification.text }}

</template>

<script>
import log from '@/utilities/log'
import { Alert } from '@/components/Alert.vue'
import { useAxiosResponseInterceptor } from './useAxiosResponseInterceptor'
import { notificationEvents } from './notificationEvents'

const logger = log('VueNotifications')

export const VueNotifications = {
  components: {
    Alert,
  },
  created() {
    useAxiosResponseInterceptor(this)
    notificationEvents.$on('notification', ({
      action,
      notifications,
    }) => {
      logger.info('notification', notifications, action)
      this.notifications = notifications
    })
  },
  data() {
    return {
      notifications: [],
    }
  },
}

export default VueNotifications
</script>
<style>
#notifications-container {
  bottom: 5em;
  position: fixed;
  right: 5em;
  z-index: 2000;
}
</style>
