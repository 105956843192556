export const getRowUpdatePayload = ({
  event = 'default',
  group = 'default',
  payload,
  row,
  userEmail,
}) => {
  const map = {
    comments: {
      removed() {
        const idx = row.comments.findIndex(
          c => c.lineIdx === payload.lineIdx
        )
        if (idx > -1) {
          row.comments.splice(idx, 1)
        }
        return {
          commentCount: row.comments.length,
          comments: [
            ...row.comments
          ],
        }
      },
    },
    notes: {
      created() {
        return {
          notes: [{ ...payload, userEmail }, ...row.notes],
        }
      },
      removed() {
        const noteIdx = row.notes.findIndex(
          note => note.id === payload.noteId
        )
        if (noteIdx > -1) {
          row.notes.splice(noteIdx, 1)
        }
        return {
          notes: [...row.notes],
        }
      },
    }
  }

  if (map[group] && typeof map[group][event] === 'function') {
    return map[group][event]()
  }
  return null
}
