import fullCellPointerStyle from './fullCellPointerStyle'
/**
 * Returns a formatted cell value for notes
 */

export default (_v, { item: data, plainText }) => {
  if (plainText) {
    return data.noteCount
  }

  const title = (
    data.noteCount > 0
      ? `${data.noteCount} note${data.noteCount > 1 ? 's' : ''}`
      : 'No campaign notes'
  )

  return data.rowUpdating
    ? '●'
    : (
    `<div ` +
      `style='${fullCellPointerStyle}' ` +
      `title='${title}'` +
    `>${data.noteCount > 0 ? data.noteCount : ''}</div>`
      )
}
