import {
  createLadder,
  getLadders,
  getLadder,
  getTrophies,
  joinAlliance,
  leaveAlliance,
  removeLadder,
  removeFromAlliance,
  updateLadder,
} from '../../users/actions'
import { categoryGroups } from '../categoryGroups'

const DEFAULT_PREFERENCES = {
  allianceList: false,
  compactList: true,
  ladderType: 'regular',
  showProgress: true,
}

export const Statistics = {
  state: {
    categoryGroups,
    days: [],
    displayed: false,
    displayGroup: 'current',
    epochs: [],
    loaded: false,
    loadingTrophies: false,
    loadedTrophies: false,
    listing: false,
    listed: false,
    loading: false,
    selectedEpochId: null,
    today: null,
    trophies: [],
    trophyCaseDisplayed: false,
    updatedAt: null,
  },
  getters: {
    epochById: state => epochId => state.epochs.find(
      ({ id }) => id === epochId
    ),
    epochDataLoaded: (_state, getters) => epochId => (
      getters.epochById(epochId)?.rows instanceof Array
    ),
    mySelectedEpochRanking: (state, getters) => getters.myEpochRanking(
      state.selectedEpochId
    ),
    myAlliance: (_state, getters, rootState) => getters.selectedEpochAlliances.find(
      ({ members }) => members.includes(rootState.currentUser.email)
    ),
    myAllianceHasRoom: (_state, getters) => (
      getters.myAlliance &&
      getters.myAlliance.hasRoom
    ),
    myAllianceLeaderIsMe: (state, getters, rootState) => (
      getters.myAlliance &&
      getters.myAlliance.leader === rootState.currentUser.email
    ),
    myEpochRanking: (_state, getters, rootState) => epochId => {
      const rows = ((getters.epochById(epochId) || {}).rows || [])
      const myEmail = rootState.currentUser.email
      const myRanking = rows.find(
        ({ userEmail }) => (myEmail === userEmail)
      ) || {
        rank: null,
        score: null,
      }

      return myRanking
    },
    noEpochsAvailable: state => (
      state.listed && state.epochs.length === 0
    ),
    nonAllianceEpochMembers: (state, getters) => (
      getters.selectedEpochRows.filter(
        ({ allianceMember }) => allianceMember !== true
      )
    ),
    preferences: (_s, _g, _rs, rootGetters) => ({
      ...DEFAULT_PREFERENCES,
      ...rootGetters['currentUser/preferencesFor']('ladder'),
    }),
    selectedEpoch: state => state.epochs.find(
      ({ id }) => state.selectedEpochId === id
    ),
    selectedEpochAlliances: (_state, getters) => (
      getters.selectedEpoch.alliances || []
    ),
    selectedEpochHasAlliances: (_state, getters) => (
      getters.selectedEpochAlliances.length > 0
    ),
    selectedEpochTodayMultiplier: (state, getters) => {
      const todayIdx = getters.selectedEpoch.days.indexOf(state.today)

      return (getters.selectedEpoch.dayMultipliers[todayIdx] || 100)
    },
    selectedEpochDataLoaded: (_state, getters) => (
      getters.selectedEpoch &&
      getters.selectedEpoch.loaded
    ),
    selectedEpochRange: (_state, getters) => {
      return (
        getters.selectedEpoch
          ? (
            `${getters.selectedEpoch.startAtTime} > ` +
            `${getters.selectedEpoch.endAtTime}`
            )
          : 'N/A')
    },
    selectedEpochRows: (_state, getters) => (
      getters.selectedEpoch.rows || []
    ),
    selectedEpochDays: (_state, getters) => (
      getters.selectedEpoch.days || []
    ),
    selectedEpochDaysUntilToday: (_state, getters) => (
      getters.selectedEpoch.daysUntilToday || []
    ),
    selectedEpochPath: (_state, getters) => {
      return (
        getters.selectedEpoch
          ? getters.selectedEpoch.path
          : ''
      )
    },
    selectedEpochWeights: (_state, getters) => (
      getters.selectedEpoch.weights || {}
    ),
    topEpochRanking: (_state, getters) => epochId => {
      const epoch = getters.epochById(epochId)
      const resultShape = {
        score: 0,
        userEmail: null
      }
      if (epoch && epoch.rows && epoch.rows.length) {
        const result = epoch.rows.reduce((top, row) => {
          if (row.score > top.score) {
            return row
          } else return top
        }, resultShape)
        return result
      } else return resultShape
    },
    topSelectedEpochRanking: (state, getters) => (
      getters.topEpochRanking(state.selectedEpochId)
    ),
    topEpochAlliance: (state, getters) => epochId => {
      const epoch = getters.epochById(epochId)
      const resultShape = {
        score: 0,
        members: [],
      }
      if (epoch && epoch.alliances.length > 0) {
        return epoch.alliances.reduce((top, alliance) => {
          if (alliance.score > top.score) {
            return alliance
          } else return top
        }, resultShape)
      } else return resultShape
    },
    topSelectedEpochAlliance: (state, getters) => (
      getters.topEpochAlliance(state.selectedEpochId)
    ),
  },
  mutations: {
    displayed(state) {
      state.displayed = !state.displayed
    },
    displayGroup(state, payload = 'current') {
      if (state.displayGroup !== payload) {
        state.displayGroup = payload
        state.listed = false
      }
    },
    epochs(state, payload) {
      state.epochs = payload
    },
    epoch(state, payload) {
      const epochIdx = state.epochs.findIndex(({ id }) => (
        payload.id === id
      ))
      const now = Date.now()

      if (epochIdx >= 0) {
        state.epochs.splice(epochIdx, 1, {
          ...payload,
          loaded: true,
          updatedAt: now,
        })
        state.updatedAt = now
      }
    },
    epochTitle(state, newTitle) {
      const epoch = state.epochs.find(({ id }) => (
        state.selectedEpochId === id
      ))

      epoch.title = newTitle
      state.epochs = [...state.epochs]
    },
    listing(state, payload = false) {
      state.listing = payload
      if (payload === false) {
        state.listed = true
      }
    },
    loadingTrophies(state, payload = false) {
      state.loadingTrophies = payload
    },
    loading(state, payload = false) {
      state.loading = payload
      if (payload === false) {
        state.loaded = true
      }
    },
    select(state, epochId) {
      state.selectedEpochId = epochId
    },
    today(state, payload) {
      state.today = payload
    },
    trophies(state, payload = []) {
      state.trophies = payload
      state.loadedTrophies = true
    },
    toggleTrophyCase(state) {
      state.trophyCaseDisplayed = !state.trophyCaseDisplayed
    },
    weight(state, { group, value }) {
      const epoch = state.epochs.find(({ id }) => (
        state.selectedEpochId === id
      ))

      epoch.weights[group] = value
      state.epochs = [...state.epochs]
    }
  },
  actions: {
    async leaveAlliance({ state, dispatch }) {
      await leaveAlliance(state.selectedEpochId)

      return dispatch('loadEpochLadder', {
        epochId: state.selectedEpochId
      })
    },
    async recruitToAlliance({ state, dispatch }, userEmail) {
      await joinAlliance({
        epochId: state.selectedEpochId,
        userEmail,
      })

      return dispatch('loadEpochLadder', {
        epochId: state.selectedEpochId,
      })
    },
    async removeFromAlliance({ state, dispatch }, userEmail) {
      await removeFromAlliance({
        epochId: state.selectedEpochId,
        userEmail,
      })

      return dispatch('loadEpochLadder', {
        epochId: state.selectedEpochId
      })
    },
    async listEpochs({ commit, dispatch, getters, state }) {
      commit('listing', true)

      const {
        data: {
          today,
          epochs,
        },
      } = await getLadders(state.displayGroup)

      commit('epochs', epochs)
      commit('today', today)
      commit('listing', false)

      if (epochs.length > 0) {
        const preferedLadderType = getters.preferences.ladderType
        const [epochToSelect] = [...epochs].sort((a, b) => (
          (b.ladderType === preferedLadderType ? 1 : 0) -
          (a.ladderType === preferedLadderType ? 1 : 0)
        ))

        dispatch('selectEpoch', epochToSelect.id)
      }
    },
    async loadEpochLadder({
      commit,
    }, { epochId, refresh = false }) {
      commit('loading', true)
      const {
        data: {
          alliances,
          epoch,
          statistics,
        },
      } = await getLadder({ epochId, refresh })

      commit('epoch', {
        ...epoch,
        alliances,
        rows: statistics,
      })
      commit('loading', false)
    },
    async preload({ dispatch, state }) {
      if (!state.listed) {
        await dispatch(
          'currentUser/getPreferences',
          'ladder',
          { root: true }
        )
        await dispatch('listEpochs')
      }
    },
    async removeSelectedEpoch({ commit, dispatch, state }) {
      await removeLadder(state.selectedEpochId)

      commit('select', null)
      dispatch('listEpochs')
    },
    async selectEpochByPath({ dispatch, getters, state }, epochPath) {
      const epoch = state.epochs.find(
        ({ id }) => id === epochPath
      )

      if (epoch) {
        await dispatch('selectEpoch', epoch.id)
      }

      return getters.selectedEpochPath
    },
    selectEpoch({ commit, dispatch, getters }, epochId) {
      commit('select', epochId)

      if (getters.selectedEpochDataLoaded) {
        commit('loading', false)
      } else {
        return dispatch('loadEpochLadder', { epochId })
      }
    },
    async submitNewEpoch({ dispatch }, payload) {
      const {
        data: {
          epochId
        }
      } = await createLadder(payload)
      await dispatch('listEpochs')

      dispatch('selectEpoch', epochId)
    },
    async getTrophies({ commit, state }, force = false) {
      if (force || !state.loadedTrophies) {
        commit('loadingTrophies', true)
        const { data: { trophies } } = await getTrophies()
        commit('trophies', trophies)
        commit('loadingTrophies', false)
      }
    },
    async toggleDisplayed({ commit, dispatch, state }) {
      commit('displayed')
      if (state.displayed) {
        await dispatch('preload')
      }
    },
    async socket_userStatisticsUpdated({
      commit,
      dispatch,
      state
    }, {
      alliances,
      epochId,
      statistics,
    }) {
      if (!state.listed) {
        await dispatch('listEpochs')
      }
      const epoch = state.epochs.find(({ id }) => id === epochId)

      if (epoch) {
        commit('epoch', {
          ...epoch,
          alliances,
          rows: statistics,
        })
        if (!state.selectedEpochId) {
          commit('select', epochId)
          commit('loading', false)
        }
      }
    },
    async updateEditWeight({
      commit,
      dispatch,
      state,
    }, {
      group,
      value,
    }) {
      commit('weight', { group, value })

      const {
        data: { success },
      } = await updateLadder({
        epochId: state.selectedEpochId,
        weight: {
          group,
          value,
        }
      })

      dispatch('loadEpochLadder', {
        epochId: state.selectedEpochId,
        refresh: true,
      })

      return success
    },
    async updateLadderTitle({
      commit,
      dispatch,
      state,
    }, newTitle) {
      commit('epochTitle', newTitle)
      await updateLadder({
        epochId: state.selectedEpochId,
        title: newTitle,
      })
      dispatch('loadEpochLadder', {
        epochId: state.selectedEpochId,
      })
    },
    updatePreference({ dispatch }, {
      name,
      value,
    }) {
      return dispatch('currentUser/updatePreference', {
        component: 'ladder',
        name,
        value,
      }, {
        root: true
      })
    }
  }
}
