import { fullCellPointerStyle } from './fullCellPointerStyle'

export default (
  _v,
  {
    item: {
      activeAdsCount,
      activeInvalidAdsCount,
      allActiveAdsAreValid,
      noActiveAdsAreValid,
    },
    plainText,
  },
) => {
  if (plainText) {
    return (
      `${activeAdsCount} active | ` +
      `${activeInvalidAdsCount} not live`
    )
  }
  const style = allActiveAdsAreValid
    ? 'font-weight: normal;'
    : 'font-weight: bold;'

  const title =
    `The campaign has ` +
    `${activeAdsCount} active ad${activeAdsCount !== 1 ? 's' : ''}`

  return (
    `<div class='${
      noActiveAdsAreValid ? 'text-warning' : 'text-secondary'
    }' style='${fullCellPointerStyle}${style}' title='${title}'>` +
      `${activeAdsCount}${
        activeInvalidAdsCount > 0
          ? ` (${activeInvalidAdsCount} not live)`
          : ''
      }` +
    `</div>`
  )
}
