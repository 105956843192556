/**
 * Returns a formatted cell value for row actions
 */

export default (data, plainText) => {
  const res = (
    (!data.sealed && !data.claimed)
      ? {
          label: `Take`,
          class: 'available'
        }
      : (
          data.sealed
            ? (
                data.sealedByMe
                  ? {
                      label: `Release`,
                      class: 'my-seal'
                    }
                  : {
                      label: `Done by ${data.sealedBy}`,
                      class: 'seal'
                    }
              )
            : (
                data.claimedByMe
                  ? {
                      label: `Mark done`,
                      class: 'my-claim'
                    }
                  : {
                      label: `Taken by ${data.claimedBy}`,
                      class: 'claim'
                    }
              ))
  )
  if (plainText) {
    return res.label
  }

  const releaseButton = (data.claimed || data.sealed)
    ? `<button class='button-unstyled ml-2 text-primary' ` +
      `title='Direct release.' ` +
      `type='button' ` +
      `data-action='release'>&#10005;` +
      `</button>`
    : ''

  return (
    `<span class='grid-action-label grid-action-state-${res.class}'>` +
      `${data.rowUpdating ? '●' : res.label}` +
    `</span>` +
    releaseButton
  )
}
