import { kawaEnvironment } from '../constants'

export default () => {
  const el = document.getElementById('application-log-level')

  return +(
    el
      ? el.getAttribute('value')
      : kawaEnvironment !== 'production'
        ? 1
        : 0
  )
}
