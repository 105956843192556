import server from '../server'

export const searchTopLogs = async ({
  positive,
  negative,
  limit = 100,
}) =>
  server.get('/top_logs/search', {
    params: {
      limit,
      positive,
      negative,
    },
  })
