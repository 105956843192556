<template lang="pug">
div
  Alert.text-center(
    :variant='variant'
  )
    Spinner.mr-1(small)
    | {{ label }}
</template>
<script>
import { Alert } from './Alert.vue'
import { Spinner } from './Spinner.vue'

export const Loader = {
  components: {
    Alert,
    Spinner,
  },
  props: {
    label: {
      type: String,
      default: 'Loading...',
    },
    variant: {
      type: String,
      default: 'secondary',
    },
  },
}
export default Loader
</script>