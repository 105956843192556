<template lang="pug">
span
  SwitchInput(
    disabled
    hideLabel
    v-model='connected'
    :class='socketConnected ? "text-success" : "text-danger"'
    :title='socketConnected ? "Sockets connected." : "Disconnected."'
  )
</template>
<script>
import { mapState } from 'vuex'
import { SwitchInput } from '@/components/SwitchInput.vue'

export default {
  components: {
    SwitchInput,
  },
  computed: {
    ...mapState('socket', ['socketConnected']),
    connected: {
      get() {
        return this.socketConnected
      },
      set() {},
    }
  },
}
</script>