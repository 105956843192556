<template lang="pug">
b-container(
  :fluid='fluid'
)
  slot
</template>
<script>
export const Container = {
  props: {
    fluid: {
      type: Boolean,
      default: false,
    },
  },
}
export default Container
</script>