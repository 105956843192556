import { View } from '../campaigns/stores/View'
import { CurrentUser } from '../users/stores/CurrentUser'

export const OptimCombinedStore = {
  modules: {
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
    view: {
      namespaced: true,
      ...View,
    },
  },
}
