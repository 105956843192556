import Ui from './BoardUi.vue'
import { BoardCombinedStore } from './BoardCombinedStore'
import { mountVuePage } from '../mountVuePage'
import { routes } from './routes'

mountVuePage({
  rootComponent: Ui,
  routes,
  store: BoardCombinedStore,
})
