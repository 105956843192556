import { server } from '../../server'
import { keywordLowBid } from '../../../campaigns/constants'

export const add = ({ campaignId, keywords }) =>
  server.post(`/campaign/${campaignId}/keywords`, { keywords })

export const addKeywordsToCampaigns = ({ campaignIds, keywords }) =>
  server.post(`/campaigns/keywords`, { campaignIds, keywords })

export const removeKeywordsFromCampaigns = ({
  campaignIds,
  keywords,
}) =>
  server.post('/campaigns/remove_keywords', {
    campaignIds,
    keywords,
  })

export const getAll = campaignId =>
  server.get(`/campaign/${campaignId}/keywords`)

export const updateBid = ({ campaignId, keywordMd5, bid }) =>
  server.put(`/campaign/${campaignId}/keyword/${keywordMd5}`, { bid })

export const updateBids = ({ campaignId, keywords, bid }) =>
  server.put(`/campaign/${campaignId}/keyword_bids`, {
    keywords,
    bid,
  })

export const resetBids = ({ campaignId, keywords }) =>
  updateBids({
    campaignId,
    keywords,
    bid: 0,
  })

export const remove = ({ campaignId, keywords }) =>
  server.post('/campaigns/remove_keywords', {
    campaignIds: [campaignId],
    keywords,
  })

export const strangle = ({ campaignId, keywords }) =>
  updateBids({
    bid: keywordLowBid,
    campaignId,
    keywords,
  })
