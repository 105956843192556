import Observer from './Observer'

export const VueSocket = (component = 'common') => ({
  install(Vue, connection, store) {
    if (!connection) throw new Error('[Vue-Socket.io] cannot locate connection')
    const observer = new Observer({
      component,
      connection,
      store
    })
    Vue.prototype.$socket = observer.Socket
  }
})
