<template lang="pug">
b-navbar-brand(
  :active='active'
  :active-class='activeClass'
  :href='href'
  :to='to'
)
  slot
</template>
<script>
export const NavbarBrand = {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: [Object, String],
      default: null,
    },
  },
}
export default NavbarBrand
</script>
