import fullCellPointerStyle from './fullCellPointerStyle'
/**
 * Returns a formatted cell for campaign 42L 21L
 */

export default ({ subType, data, plainText }) => {
  const title = 'Change'
  const value = (subType === '42l') ? data.is42Ui : data.is21Ui

  if (plainText) {
    return value
  }

  const valueBool = (subType === '42l' ? data.is42L : data.is21L)
  const rowClass = (
    valueBool ? 'sheet-row-subtype-active' : 'sheet-row-subtype-inactive'
  )

  return (
    `<div ` +
      `style='${fullCellPointerStyle}' ` +
      `class='${rowClass}' ` +
      `title='${title}'>${value}` +
    `</div>`
  )
}
