import { server } from '../../server'

export const add = ({
  campaignId,
  shared,
  text,
}) => server.post(`/campaign/${campaignId}/notes`, {
  shared,
  text
})
export const get = campaignId => server.get(
  `/campaign/${campaignId}/notes`
)

export const remove = ({ campaignId, noteId }) => server.delete(
  `/campaign/${campaignId}/note/${noteId}`
)
