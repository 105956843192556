import {
  createSubscription,
  getSubscriptions,
  removeSubscription,
} from './actions'

export const FactorySubscriptionsStore = {
  state: {
    subscriptions: [],
  },
  mutations: {
    subscriptions(state, payload) {
      state.subscriptions = [...payload]
    },
  },
  actions: {
    async createSubscription(
      { dispatch },
      { activities, filterSet, profiles }
    ) {
      const {
        data: { success },
      } = await createSubscription({
        activities,
        filterSet,
        profiles,
      })
      dispatch('getSubscriptions')
      return success
    },
    async getSubscriptions({ commit }) {
      const {
        data: { subscriptions },
      } = await getSubscriptions()
      commit('subscriptions', subscriptions)
      return subscriptions
    },
    async removeSubscription({ dispatch }, subscriptionId) {
      const {
        data: { success },
      } = await removeSubscription(subscriptionId)
      dispatch('getSubscriptions')
      return success
    },
  },
}
