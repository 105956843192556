export const routes = [
  { path: '/', component: () => import('@/sheets/components/SheetLoading.vue') },
  {
    path: '/of/:routedSheetPath/show/:routedSheetView',
    component: () => import('@/sheets/components/SheetTable.vue'),
  },
  {
    path: '/of/:routedSheetPath',
    redirect: (to) => {
      return `/of/:routedSheetPath/show/default`
    },
  },
]
