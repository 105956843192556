import axios from 'axios'
import { token, tokenAuthKey, tokenUserKey } from './auth/token'
import { masterUrl, serverUrl } from './constants'
import { clientSubPath } from '@/constants'
import log from './utilities/log'
import { loginPath } from './auth/constants'
// import { unauthorizedPath } from './auth/constants'

const logger = log('server:axios')

const assignInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use(function beforeRequest(
    config,
  ) {
    config.headers['x-authentication-token'] =
      token(tokenAuthKey).get()
    config.headers['x-authentication-requestor'] =
      token(tokenUserKey).get()

    return config
  })

  axiosInstance.interceptors.response.use(null, (error) => {
    if (AUTHENTICATION_FAILURE_CODES.includes(error.response?.status)) {
      logger.warn(
        'Authentication failure, redirecting to login',
        error.response,
        window.location,
      )
      if (
        clientSubPath &&
        !window.location.hash?.includes(loginPath)
      ) {
        window.location = `${clientSubPath}/#${loginPath}?e=${error.response?.status}`
      } else if (
        !clientSubPath &&
        window.location.pathname !== loginPath
      ) {
        window.location = `${loginPath}?e=${error.response?.status}`
      }
    }
    if (AUTHORIZATION_FAILURE_CODES.includes(error.response?.status)) {
      logger.warn(
        'Authorization failure, informing',
        error.response,
      )
      // window.location = `/#${unauthorizedPath}`
      return Promise.resolve({ data: {} })
    }
    return Promise.reject(error)
  })
}

const server = axios.create({
  baseURL: `${serverUrl}/api`,
})

const master = axios.create({
  baseURL: masterUrl,
})

assignInterceptors(server)
assignInterceptors(master)

export { master, server }
export default server

export const AUTHENTICATION_FAILURE_CODES = [401]
export const AUTHORIZATION_FAILURE_CODES = [403]
