<template lang="pug">
Icon.text-primary(
  name='info-circle'
  style='cursor: pointer;'
)
</template>
<script>
import { Icon } from './Icon.vue'
export const PopoverIcon = {
  components: { Icon }
}
export default PopoverIcon
</script>