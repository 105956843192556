<template lang="pug">
div
  KawaNavigation(
    variant='dark'
  )
  Container(fluid)
    h2 Test sheet ui (v{{ version }})
    Grid(
      v-if='rows.length > 0'
      ref='sheetRef'
      downloadable
      show-footer
      :fields='fields'
      :items='rows'
      :height='500'
      :row-formatter='rowFormatter'
      @filters-changed='handleFiltersChanged'
      @grid-click='handleGridClick'
      @rows-changed='handleGridRowsChanged'
      @selected-rows-changed='handleRowSelectionUpdate'
    )
    hr
    InputGroup
      FormInput(type='number' v-model='rowsToAdd')
      Button(
        @click='addRows(+rowsToAdd)'
      ) Add {{ rowsToAdd }} rows
    ButtonGroup
      Button(
        @click='onSelectSampleRows'
      ) Select some rows
      Button(
        v-if='selectedRowIdxs.length > 0'
        @click='onUpdateSelectedRows'
        variant='primary'
      ) Update selected
      Button.text-danger(
        v-if='selectedRowIdxs.length > 0'
        @click='onUnselectRows'
      ) Unselect
    //- pre last click: {{ lastClick }}
    //- pre selected idxs: {{ selectedRowIdxs }}
    pre selected rows: {{ selectedRows }}
    pre filters {{ filters }}
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import {
  Button,
  ButtonGroup,
  Container,
  FormInput,
  InputGroup,
  KawaNavigation,
} from '@/components'
import { Grid } from '@/components/Grid'
import log from '@/utilities/log'

const logger = log('testsheet')

export default {
  components: {
    Button,
    ButtonGroup,
    Container,
    FormInput,
    Grid,
    InputGroup,
    KawaNavigation,
  },
  computed: {
    ...mapState('sheet', [
      'updateVersion',
      'rows',
      'version',
      'filters',
    ]),
    ...mapGetters('sheet', ['fields']),
    selectedRows() {
      return this.rows.filter((r) =>
        this.selectedRowIds.includes(r.id),
      )
    },
  },
  data() {
    return {
      lastClick: null,
      rowsToAdd: 2,
      selectedRowIdxs: [],
      selectedRowIds: [],
    }
  },
  methods: {
    ...mapActions('sheet', ['getRows', 'addRows', 'liveUpdate']),
    ...mapMutations('sheet', ['updateRow', 'setFilters']),
    applyFilters() {
      logger.info('applyFilters', this.filters)
      this.$refs.sheetRef?.setFilters(this.filters)
    },
    handleFiltersChanged({ filters }) {
      this.setFilters(filters)
    },
    handleGridRowsChanged() {
      logger.info('handleGridRowsChanged')
    },
    handleGridClick(_e, clickOptions) {
      logger.info('handleGridClick', clickOptions)
      this.lastClick = clickOptions
    },
    handleRowSelectionUpdate(event, { rows, rowIndexes }) {
      logger.info('handleRowSelectionUpdate', {
        event,
        rows,
        rowIndexes,
        grid: this.grid,
        sheet: this.$refs.sheetRef,
      })

      this.selectedRowIdxs = rowIndexes
      this.selectedRowIds = rows.map((r) => r.id)
    },
    onSelectSampleRows() {
      logger.info('onSelectSampleRows')
      const sampleIds = this.rows.slice(0, 5).map((r) => r.id)
      this.$refs.sheetRef.selectRowsByIds(sampleIds)
    },
    onUnselectRows() {
      this.$refs.sheetRef.selectRowsByIds([])
    },
    onUpdateSelectedRows() {
      this.selectedRows.forEach((row) => {
        logger.info('onUpdateSelectedRows', row)
        this.updateRow({
          id: row.id,
          payload: { status: 'update', version: row.version + 1, time: new Date() },
        })
      })
    },
    rowFormatter(item) {
      return {
        cssClasses: item.status === 'new' ? 'text-default' : 'text-primary'
      }
    }
  },
  mounted() {
    this.getRows()
    this.liveUpdate()
    setTimeout(() => {
      this.applyFilters()
    }, 1000)
  },
}
</script>
