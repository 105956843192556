import { server } from '../../server'

export const getSpendProjections = (campaignId) =>
  server.get(`/campaign/${campaignId}/projections`)

export const getSpendIndicators = ({ campaignId, ...query }) =>
  server.get(`/campaign/${campaignId}/spend_indicators`, {
    params: query,
  })

export const getSpendDetails = (campaignId) =>
  server.get(`/campaign/${campaignId}/spend`)
