import Ui from './BlankUi.vue'
import { BlankCombinedStore } from './BlankCombinedStore'
import { mountVuePage } from '../mountVuePage'
import { routes } from './routes'

mountVuePage({
  rootComponent: Ui,
  routes,
  socketComponents: ['common'],
  store: BlankCombinedStore,
})
