<template lang="pug">
BCheckbox(
  :disabled='disabled'
  v-model='valueInput'
  :size='size'
  switch
)
  slot
  span(v-if='!hideLabel') {{ label }}
    strong.ml-1(
      v-if='!hideLabelStatus'
      :class='valueClass'
    ) [{{ valueInput ? 'ON' : 'OFF' }}]
</template>

<script>
export const SwitchInput = {
  data() {
    return {
      valueInput: !!this.value,
    }
  },
  computed: {
    valueClass() {
      return this.valueInput ? 'text-primary' : 'text-secondary'
    },
  },
  methods: {
    handleInput(v) {
      if (v !== this.value) {
        this.$emit('input', v)
      }
    },
    syncValue(v) {
      if (v !== this.valueInput) {
        this.valueInput = v
      }
    },
  },
  props: {
    disabled: Boolean,
    label: String,
    value: Boolean,
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideLabelStatus: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md'
    },
  },
  watch: {
    value: 'syncValue',
    valueInput: 'handleInput',
  }
}

export default SwitchInput
</script>
