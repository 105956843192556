const {
  editCategoryGroups,
  editCategoryGroupsAvailable,
} = require('../../campaigns/constants')
const formatString = require('../../format/string')

const mappingArray = Object.keys(editCategoryGroups).map(category => ({
  group: editCategoryGroups[category],
  key: category,
  name: formatString.standardiseString(category),
}))

export const categoryGroups = editCategoryGroupsAvailable
  .reduce((obj, group) => ({
    ...obj,
    [group]: mappingArray.filter(({ group: g }) => g === group)
  }), {})
