<template lang="pug">
NavItemDropdown(
  id='themeDropdown'
  right
)
  template(#button-content)
    Icon(
      :name='currentIconName'
    )
  DropdownItem(
    @click='setTheme("light")'
    )
    Icon(
      name='BIconSunFill'
      label='Light'
      class='icon-margin'
    )
  DropdownItem(
    @click='setTheme("dark")'
    )
    Icon(
      name='BIconMoonStarsFill'
      label='Dark'
      class='icon-margin'
    )
  DropdownItem(
    @click='setTheme("auto")'
  )
    Icon(
      name='BIconCircleHalf'
      label='System Default'
      class='icon-margin'
    )
</template>

<script>
import { DropdownItem } from '@/components/Dropdown'
import { Icon } from '@/components/Icon.vue'
import { NavItemDropdown } from '@/components/Nav'

export default {
  components: {
    DropdownItem,
    Icon,
    NavItemDropdown,
  },
  data() {
    return {
      currentIconName: '',
      currentThemeLabel: '',
    }
  },
  methods: {
    setIconTheme(theme) {
      const themeIconMap = {
        light: 'BIconSunFill',
        dark: 'BIconMoonStarsFill',
        auto: 'BIconCircleHalf',
      };
      this.currentIconName = themeIconMap[theme] || '';
    },
    setTheme(theme) {
      if (
        theme === 'auto' &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      ) {
        document.documentElement.setAttribute('data-bs-theme', 'dark')
      } else {
        document.documentElement.setAttribute('data-bs-theme', theme)
      }
      localStorage.setItem('theme', theme)
      window.dispatchEvent(new Event('themeChanged'))
      this.setIconTheme(theme)
    },
    getPreferredTheme() {
      const storedTheme = localStorage.getItem('theme')
      if (storedTheme) {
        return storedTheme
      }
      return window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light'
    },
    watchPreferredColorScheme() {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (e) => {
          const preferredTheme = e.matches ? 'dark' : 'light';
          const storedTheme = localStorage.getItem('theme')
          if (storedTheme !== 'light' && storedTheme !== 'dark') {
            this.setTheme(preferredTheme)
          }
        })
    },
  },
  created() {
    const theme = localStorage.getItem('theme') || this.getPreferredTheme()
    this.setTheme(theme)
  },
  mounted() {
    this.watchPreferredColorScheme()
  },
}
</script>

<style>
.icon-margin svg {
  margin-right: 0.5rem;
}
</style>
