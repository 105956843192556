<template lang="pug">
BForm(
  v-on='$listeners'
  :action='action'
  :disabled='disabled'
  :hidden='hidden'
)
  slot
</template>
<script>
export const Form = {
  props: {
    action: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
}

export default Form
</script>