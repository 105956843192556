import { getNewClientPath } from '@/utilities/getNewClientPath'

export const routes = [
  {
    path: '/',
    component: () => import('./CampaignerView.vue'),
    title: 'Campaigner',
    relatedPath: '/campaign',
  },
  {
    path: '/colors',
    component: () => import('./Colors.vue'),
  },
  {
    path: '/campaign/:id',
    redirect: '/campaign/:id/details',
  },
  {
    path: '/campaign/:id/:reportType/:rangeType/:rangeAutoDate/:rangeFrom/:rangeTo',
    component: () =>
      import('../campaigns/components/CampaignWithReportsView.vue'),
  },
  {
    path: '/campaign/:id/:reportType',
    component: () =>
      import('../campaigns/components/CampaignWithReportsView.vue'),
  },
  {
    href: getNewClientPath('/explorer'),
    title: 'Explorer',
  },
  {
    href: getNewClientPath('/reactor'),
    title: 'Reactor',
  },
  {
    href: getNewClientPath('/spend'),
    title: 'Spend',
  },
]
