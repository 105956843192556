export const routes = [
  {
    path: '/',
    component: () =>
      import('../broadMatch/BroadMatchCampaignSelection.vue'),
  },
  {
    path: '/:id',
    component: () => import('./MatcherView.vue'),
  },
]
