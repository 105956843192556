<template lang="pug">
BCollapse(
  :id='id'
  :is-nav='isNav'
  :visible='show'
)
  slot
</template>
<script>
export const Collapse = {
  props: {
    id: {
      type: String,
      default: null,
    },
    isNav: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: null,
    },
    visible: {
      type: Boolean,
      default: null,
    }
  },
  data() {
    return {
      show: this.value ?? this.visible ?? false,
    }
  },
  methods: {
    announceInput() {
      this.$emit('input', this.show)
    },
    syncInput(v) {
      if (v === this.show) return
      this.show = v
    },
  },
  watch: {
    show: 'announceInput',
    value: 'syncInput',
    visible: 'syncInput',
  },
}

export default Collapse
</script>
