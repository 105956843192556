<template lang="pug">
BDropdownItem(
  @click="$emit('click')"
  :disabled='disabled'
  :active='active'
  :href='href'
  :to='to'
) {{ text }}
  slot
</template>
<script>
import { BDropdownItem } from 'bootstrap-vue'

export const DropdownItem = {
  components: {
    BDropdownItem,
  },
  props: {
    active: Boolean,
    disabled: Boolean,
    href: String,
    to: String,
    text: {
      type: String,
      default: '',
    },
  },
}

export default DropdownItem
</script>