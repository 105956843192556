import { Board } from '../boards/stores/Board'
import { Statistics } from '../ranking/stores/Statistics'
import { CurrentUser } from '../users/stores/CurrentUser'

export const BoardCombinedStore = {
  modules: {
    board: {
      namespaced: true,
      ...Board,
    },
    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
    statistics: {
      namespaced: true,
      ...Statistics,
    },
  }
}
