import { kawaConstants } from '@/constants'

export function determineAutoReportTypes({
  campaignTypeId,
  campaignGoalType,
}) {
  const { reporting: { reportTypePerCampaignType } } = kawaConstants
  let groupType
  switch (campaignGoalType) {
    case 'CPA': {
      groupType = 'conversion'
      break
    }
    case 'VCR': {
      groupType = 'video_completion'
      break
    }
    default: {
      groupType = 'performance'
    }
  }

  return reportTypePerCampaignType[campaignTypeId]
    ? reportTypePerCampaignType[campaignTypeId][groupType]
    : ['ad_performance']
}
