<template lang="pug">
BFormInput(
  :id='id'
  :disabled="disabled"
  :number="number"
  ref='FormInput'
  :required="required"
  :placeholder="placeholder"
  :state='state'
  :type='type'
  v-model='valueInput'
  v-on="$listeners"
)
  slot
</template>
<script>
export const FormInput = {
  data() {
    return {
      valueInput: this.value,
    }
  },
  methods: {
    focus() {
      this.$refs['FormInput'].focus()
    },
    syncValue(v) {
      if (this.valueInput === v) return
      this.valueInput = v
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    number: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: String | Number,
    },
  },
  watch: {
    valueInput() {
      this.$emit('input', this.valueInput)
    },
    value: 'syncValue',
  },
}

export default FormInput
</script>
