import { formatPrecision } from './formatPrecision'

export const formatPercentWithPrecision = (num = 0) => {
  const percentage = num * 100

  return percentage === 0 || isNaN(percentage)
    ? '0%'
    : percentage > -1 && percentage < 1
      ? formatPrecision(percentage, 2) + '%'
      : Math.round(percentage) + '%'
}
