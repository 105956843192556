<template lang="pug">
div
  KawaNavigation(
    variant='light'
    type='light'
  )
    NavbarNav.ml-auto
      NavForm
        Dropdown(
          v-if='!listing'
          style='margin-right: 1em;'
          title='Epochs'
          :text='epochDropdownLabel'
        )
          DropdownItemButton(
            v-for='epoch in epochs'
            :active='selectedEpochId === epoch.id'
            :key='epoch.id'
            @click='triggerEpochSelection(epoch)'
          ) {{ epoch.title }} [#[strong {{ epoch.ladderType }}]]
          DropdownDivider
          DropdownItemButton(
            v-k-modal='modalId'
          ) Create new ladder
  Container.py-4(fluid)
    Modal(
      :id='modalId'
      :ref='modalId'
      title='New ladder'
      v-slot='{ close }'
    )
      RankingEpochNew(
        @submitted='close'
      )
    router-view
</template>

<script>
import { Container } from '@/components/Layout'
import { KawaNavigation } from '@/components/KawaNavigation.vue'
import { NavForm } from '@/components/Nav'
import { NavbarNav } from '@/components/Navbar'
import { KModal } from '@/directives'
import { mapState, mapActions, mapGetters } from 'vuex'
import {
  Dropdown,
  DropdownItemButton,
  DropdownDivider,
} from '@/components/Dropdown'
import { Modal } from '@/components/Modal.vue'

export default {
  components: {
    Container,
    Dropdown,
    DropdownDivider,
    DropdownItemButton,
    KawaNavigation,
    KawaMenuDropdown: () =>
      import('@/components/KawaMenuDropdown.vue'),
    Modal,
    NavForm,
    NavbarNav,
    RankingEpochNew: () =>
      import('@/ranking/components/RankingEpochNew.vue'),
  },
  directives: {
    KModal,
  },
  data() {
    return {
      modalId: 'new-epoch-modal',
    }
  },
  mounted() {
    this.display()
  },
  computed: {
    ...mapState('statistics', [
      'epochs',
      'listing',
      'loading',
      'selectedEpochId',
    ]),
    ...mapGetters('statistics', [
      'selectedEpochDataLoaded',
      'selectedEpoch',
      'selectedEpochPath',
    ]),
    epochDropdownLabel() {
      return this.selectedEpochDataLoaded
        ? `Selected: ${this.selectedEpoch.title} ` +
            ` [${this.selectedEpoch.ladderType}]`
        : 'Select ladder or range'
    },
    routedEpochPath() {
      return this.$route.params.routedEpochPath
    },
  },
  watch: {
    selectedEpochPath(to, from) {
      if (to && to !== this.routedEpochPath) {
        this.$router.push({
          path: `/${to}`,
        })
      }
    },
    routedEpochPath(to, from) {
      if (this.selectedEpochPath !== to) {
        this.display()
      }
    },
  },
  methods: {
    ...mapActions('statistics', [
      'selectEpoch',
      'selectEpochByPath',
      'toggleDisplayed',
    ]),
    async display() {
      const routeTo = this.routedEpochPath

      await this.toggleDisplayed()
      if (routeTo && routeTo.length > 0) {
        const selectedPath = await this.selectEpochByPath(routeTo)

        if (routeTo !== selectedPath) {
          this.$router.push({
            path: `/${selectedPath}`,
          })
        }
      }
    },
    onSubmitted() {
      this.$refs[this.modalId].hide()
    },
    triggerEpochSelection(epoch) {
      if (this.selectedEpochId !== epoch.id) {
        this.selectEpoch(epoch.id)
      }
    },
  },
}
</script>
