import Ui from './CampaignerUi.vue'
import { CampaignerCombinedStore } from './CampaignerCombinedStore'
import { mountVuePage } from '../mountVuePage'
import { routes } from './routes'

mountVuePage({
  container: '#container',
  rootComponent: Ui,
  routes,
  socketComponents: ['common'],
  store: CampaignerCombinedStore,
})
