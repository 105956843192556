<template lang="pug">
b-nav-item(
  @click='$emit("click")'
  :active='active'
  :active-class='activeClass'
  :disabled='disabled'
  :href='href'
  :to='to'
)
  slot
</template>

<script>
export const NavItem = {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: [Object, String],
      default: null,
    },
  },
}
export default NavItem
</script>
