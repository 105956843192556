<template lang="pug">
BDropdownItemButton(
  @click='$emit("click")'
  :active='active'
  :variant='variant'
)
  slot
</template>
<script>

import { BDropdownItemButton } from 'bootstrap-vue'

export const DropdownItemButton = {
  components: {
    BDropdownItemButton,
  },
  props: {
    active: Boolean,
    variant: {
      type: String,
      default: 'secondary',
    },
  }
}

export default DropdownItemButton
</script>