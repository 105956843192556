<template lang="pug">
span
  InputGroup
    template(#prepend)
      Button(
        :disabled='searching'
        v-if='searchActive'
        @click='searchInput = ""'
      )
        Icon(name='eraser-fill')
    FormInput(
      :disabled='searching'
      style='max-width: 120px'
      placeholder='Search...'
      v-model='searchInput'
      @input='onSearchInput'
    )
    InputGroupAppend
      Dropdown(:variant="searchActive ? 'success' : 'primary'")
        template(#button-content)
          span(v-if='searching') Searching...
          span(v-else-if='searchActive') Hits ({{ searchResultItems.length }})
          span(v-else) Sets ({{ keywordSetItems.length }})
        DropdownItem(
          v-for='keywordSet in keywordSetsDisplayed'
          :key='`keyword-set-${keywordSet.key}`'
          @click='onSelectKeywordSet(keywordSet)'
        ) #[Badge {{ keywordSet.createdAtUi }}] {{ keywordSet.name }}
        DropdownItem(
          disabled
          v-if='keywordSetItems.length > maxSetItems'
        ) {{ keywordSetItems.length - maxSetItems }} more...
      Dropdown
        template(#button-content)
          span Pointers ({{ myPointers.length }} | {{ foreignPointers.length }})
        DropdownHeader My pointers
        DropdownItem(
          v-for='pointer in myPointers'
          :key='`pointer-${pointer.id}`'
          @click='onSelectPointer(pointer)'
          variant='primary'
        ) #[Badge {{ pointer.createdAtUi }}] {{ pointer.name }}
        DropdownDivider
        template(v-if='foreignPointers.length')
          DropdownHeader Pointers of others
          DropdownItem(
            v-for='pointer in foreignPointers'
            :key='`pointer-${pointer.id}`'
            @click='onSelectPointer(pointer)'
          ) #[Badge {{ pointer.createdAtUi }}] {{ pointer.name }}
      Button(@click='onReset') Reset
</template>

<script>
import { Badge } from '@/components/Badge.vue'
import { Button } from '@/components/Button'
import { FormInput } from '@/components/FormInput.vue'
import { Icon } from '@/components/Icon.vue'
import {
  InputGroup,
  InputGroupAppend,
} from '@/components/InputGroup'
import {
  Dropdown,
  DropdownItem,
  DropdownHeader,
  DropdownDivider,
} from '@/components/Dropdown'
import { mapActions, mapState } from 'vuex'
import server from '../server'
const maxSetItems = 20

export const KeywordSetLoader = {
  components: {
    Badge,
    Button,
    Dropdown,
    DropdownDivider,
    DropdownHeader,
    DropdownItem,
    FormInput,
    Icon,
    InputGroup,
    InputGroupAppend,
  },
  computed: {
    ...mapState('keywordSets', [
      'keywordSets',
      'selectedKeywordSet',
      'myPointers',
      'foreignPointers',
    ]),
    keywordSetItems() {
      return this.searchActive
        ? this.searchResultItems
        : this.keywordSets
    },
    keywordSetsDisplayed() {
      return this.keywordSetItems.slice(0, maxSetItems)
    },
    searchActive() {
      return this.searchInput.length > 1
    },
    searchCriteria() {
      return this.searchInput
        .replace(/[^a-zA-Z0-9-:]/g, ' ')
        .split(' ')
    },
    searchResultItems() {
      return this.keywordSets.filter((set) =>
        this.searchResults.includes(set.key),
      )
    },
  },
  data() {
    return {
      maxSetItems,
      searching: false,
      searchInput: '',
      searchResults: [],
      searchTimer: null,
    }
  },
  methods: {
    ...mapActions('keywordSets', [
      'getKeywordSets',
      'getKeywordSetPointers',
      'reload',
      'selectKeywordSet',
    ]),
    async onReset() {
      this.searchInput = ''
      this.$emit('reset')
      this.reload()
    },
    async onSearchInput() {
      clearTimeout(this.searchTimer)
      if (this.searchActive && this.searchCriteria.length > 0) {
        this.searchTimer = setTimeout(() => {
          this.searchKeywordSets()
        }, 300)
      }
    },
    async onSelectKeywordSet({ key }) {
      const keywordSet = await this.selectKeywordSet(key)

      if (keywordSet?.setName === key) {
        this.$notify({
          text: `Loaded keyword set: [${keywordSet.setName}]`,
          success: true,
        })
        this.$emit('loaded', keywordSet)
        return
      }
    },
    async onSelectPointer(pointer) {
      const { set: name } = pointer
      const keywordSet = await this.selectKeywordSet(name)

      if (keywordSet?.setName === name) {
        this.$notify({
          text: `Loaded keyword set: [${keywordSet.setName}]`,
          success: true,
        })
        this.$emit('loaded', keywordSet)
        return
      }
    },
    async searchKeywordSets() {
      this.searching = true
      const { data: results } = await server.get(
        `/keywords/sets/search`,
        {
          params: {
            criteria: this.searchCriteria,
          },
        },
      )
      this.searchResults = results || []
      this.searching = false
    },
  },
  mounted() {
    this.reload()
  },
}

export default KeywordSetLoader
</script>
