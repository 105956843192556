<template lang="pug">
b-input-group-text(:class='classes')
  slot
</template>
<script>
export const Caption = {
  computed: {
    classes() {
      return this.block ? '' : 'd-inline-flex align-middle'
    },
  },
  props: {
    block: {
      type: Boolean,
      default: false,
    },
  },
}
export default Caption
</script>
