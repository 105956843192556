<template lang="pug">
BBreadcrumbItem(
  :active='active'
  :href='href'
  :title='title'
  :target='target'
)
  slot
</template>
<script>
import { BBreadcrumbItem } from 'bootstrap-vue'
export const BreadcrumbItem = {
  components: { BBreadcrumbItem },
  props: {
    active: Boolean,
    href: String,
    title: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
}
export default BreadcrumbItem
</script>
