<template lang="pug">
div
  KawaNavigation(
    title='Ranking ladders'
    variant='primary'
    :routes='routes'
  )
  Container(fluid)
    router-view
</template>

<script>
import { Container } from '@/components/Layout'
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'
import { routes } from './routes'

export default {
  components: {
    Container,
    KawaNavigation: () => import('@/components/KawaNavigation.vue'),
  },
  computed: {
    ...mapState('statistics', ['displayed']),
    ...mapGetters('statistics', ['selectedEpochPath']),
    routedEpochPath() {
      return this.$route.params.routedEpochPath
    },
    routes() {
      return [
        {
          path: '/',
          name: 'rankingLadders',
          title: 'Ranking ladders',
          relatedPath: '/',
        },
      ]
    }
  },
  watch: {
    selectedEpochPath(to, from) {
      if (to && to !== this.routedEpochPath) {
        this.$router.push({
          path: `/${to}`,
        })
      }
    },
  },
  mounted() {
    if (!this.displayed) {
      this.displayGroup('archived')
      this.display()
    }
  },
  methods: {
    ...mapActions('statistics', [
      'selectEpochByPath',
      'toggleDisplayed',
    ]),
    ...mapMutations('statistics', ['displayGroup']),
    async display() {
      const routeTo = this.routedEpochPath

      await this.toggleDisplayed()
      if (routeTo && routeTo.length > 0) {
        const selectedPath = await this.selectEpochByPath(routeTo)

        if (routeTo !== selectedPath) {
          this.$router.push({
            path: `/${selectedPath}`,
          })
        }
      }
    },
  },
}
</script>
