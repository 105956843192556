export default ({
  active,
  hoursLeft,
  label,
  serving,
  spendLabel,
  spent,
}, plainText) => {
  const text = `${label}` +
    `${(!spent && active) ? ` (${hoursLeft}hrs left)` : (spent ? ' (Spent)' : '')}`
  if (plainText) {
    return text
  }
  return (
    `<span ` +
      `title="${spendLabel}" ` +
      `class='${(serving || spent) ? 'text-success' : 'text-secondary'}'` +
    `>${text}</span>`
  )
}
