import { unauthorizedPath } from '@/auth/constants'
import VueRouter from 'vue-router'

export const createRouter = ({
  routes,
}) => {
  const router = new VueRouter({
    routes: [
      ...routes.filter(r => !!r.path),
      {
        path: unauthorizedPath,
        component: () => import('@/auth/Unauthorized.vue'),
      },
    ],
  })

  return router
}
