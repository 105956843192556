import Socket from 'socket.io-client'

export default class {
  constructor({ connection, store }) {
    this.Socket = Socket(connection, {
      reconnection: true,
      reconnectionDelay: 10000,
      reconnectionAttempts: 10,
      reconnectionDelayMax: 20000,
    })
    this.store = store
    this.onEvent()
  }

  get actionNamePrefix() {
    return `socket_`
  }

  get events() {
    return Object.keys(this.store._actions)
      .map((namespaced) => {
        const action = namespaced.split('/').pop()
        const event = action.replace(this.actionNamePrefix, '')

        return {
          action,
          event,
          listen: action.startsWith(this.actionNamePrefix),
          namespaced,
        }
      })
      .filter(({ listen }) => listen)
  }

  onEvent() {
    this.events.forEach(({ namespaced, event }) =>
      this.Socket.on(event, (payload) =>
        this.store.dispatch(namespaced, payload),
      ),
    )
  }
}
