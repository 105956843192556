<template lang="pug">
div
  VueNotifications
  router-view
</template>
<script>
import VueNotifications from '../vueNotifications/VueNotifications.vue'
import { parseUrlQuery } from '@/utilities/parseUrlQuery'

export default {
  components: {
    VueNotifications,
  },
  mounted() {
    const routeDetails = parseUrlQuery(window.location.href)
    let message = 'Please login to continue.'
    switch (+routeDetails.e) {
      case 401: {
        message = 'Invalid username or password.'
        break
      }
      case 403: {
        message = 'Unauthorized access.'
        break
      }
    }
    this.$notify({
      message,
      success: false,
    })
  },
}
</script>
