import fullCellPointerStyle from './fullCellPointerStyle'
/**
 * Returns a formatted cell value for row actions
 */

export default (_v, { item: { hasSubType, currentSubType }, plainText }) => {
  if (plainText) {
    return currentSubType
  }

  const title = 'Change'
  const rowClass = (
    hasSubType ? 'sheet-row-subtype-active' : 'sheet-row-subtype-inactive'
  )

  return (
    `<div ` +
      `style='${fullCellPointerStyle}' ` +
      `class='${rowClass}' ` +
      `title='${title}'>${currentSubType}` +
    `</div>`
  )
}
