import { CurrentUser } from '../users/stores/CurrentUser'
import { Statistics } from '../ranking/stores/Statistics'

export const EpochsCombinedStore = {
  modules: {

    currentUser: {
      namespaced: true,
      ...CurrentUser,
    },
    statistics: {
      namespaced: true,
      ...Statistics,
    },
  }
}
