<template lang="pug">
div
  BModal(
    :id='id'
    ref='Modal'
    :title='title'
    ok-only=true
    ok-variant='secondary'
    ok-title='Close'
    :size='size'
    @close='close'
    @hide='close'
    @show='show'
    v-model='valueInput'
  )
    slot(:close='close' :show='show')
</template>
<script>
export const Modal = {
  computed: {
    modal() {
      return this.$refs['Modal']
    },
  },
  data() {
    return {
      valueInput: !!this.value,
    }
  },
  methods: {
    close() {
      return this.toggle(false)
    },
    hide() {
      return this.close()
    },
    open() {
      return this.show()
    },
    show() {
      return this.toggle(true)
    },
    toggle(v) {
      if (this.valueInput !== v) {
        this.valueInput = v
        this.$emit('input', v)
        this.$emit(v ? 'show' : 'close')
        this.modal?.[v ? 'show' : 'hide']()
      }
      return this.valueInput
    },
  },
  props: {
    id: String,
    size: String,
    title: {
      default: '',
      type: String,
    },
    value: {
      default: () => false,
      type: Boolean,
    },
  },
  watch: {
    value(newValue) {
      this.toggle(newValue)
    },
  },
}

export default Modal
</script>
