<template lang="pug">
b-skeleton-table(
  :animation='animation'
  :columns='columns'
  :hide-header='hideHeader'
  :rows='rows'
  :show-footer='showFooter'
  :table-props='tableProps'
)
</template>

<script>
export const SkeletonTable = {
  props: {
    animation: {
      type: String,
      default: null,
    },
    columns: {
      type: Number,
      default: 5,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 3,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    tableProps: {
      type: Object,
      default: () => ({}),
    },
  },
}
export default SkeletonTable
</script>