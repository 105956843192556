<template lang="pug">
BDropdownHeader {{ text }}
  slot
</template>
<script>
import { BDropdownHeader } from 'bootstrap-vue'

export const DropdownHeader = {
  components: {
    BDropdownHeader,
  },
  props: {
    text: String,
  }
}
export default DropdownHeader
</script>
