/**
 * Returns a formatted cell value for bid multiplied
 */
export default (_v, { item, plainText }) => {
  if (plainText) {
    return item.bid_multiplied_label
  }
  return `<span ` +
    `class='text-${item.multiplierUpdatesEnabled ? 'default' : 'danger'}' ` +
    `title='Multiplier updates are ${item.multiplierUpdatesEnabled ? 'enabled' : 'DISABLED'}.'>` +
    `${item.bid_multiplied_label}</span>`
}
