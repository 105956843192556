<template lang="pug">
b-spinner(
  :label='label'
  :small='small'
  :variant='variant'
)
</template>
<script>
export const Spinner = {
  props: {
    label: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: '',
    },
  },
}
export default Spinner
</script>
