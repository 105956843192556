<template lang="pug">
Caption
  Link#download-link(@click='onDownload')
    Icon(
      :label='label'
      name='download'
      title='Download as CSV'
    )
</template>

<script>
import { Caption } from '@/components/Caption.vue'
import { Icon } from '@/components/Icon.vue'
import { Link } from '@/components/Link.vue'
import { downloadCSVContent } from 'NEW/utilities/download/downloadCSVContent'

export const DownloadCsv = {
  components: {
    Caption,
    Icon,
    Link,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: 'CSV',
    },
  },
  methods: {
    onDownload() {
      return downloadCSVContent({
        fields: this.fields,
        items: this.items,
      })
    },
  },
}

export default DownloadCsv
</script>
