<template lang="pug">
Button.text-primary(
  :href='detailsLink'
  :size='size'
  target='_blank'
  title='Open campaign details in a new tab'
)
  CampaignDetailsIcon
</template>

<script>
import { Button } from '@/components/Button'
import CampaignDetailsIcon from './CampaignDetailsIcon.vue'
import { componentSizes } from '@/components/constants'
import { getCampaignDetailsHref } from '../getCampaignDetailsLink'

export const CampaignDetailsButton = {
  components: {
    Button,
    CampaignDetailsIcon,
    getCampaignDetailsHref,
  },
  computed: {
    detailsLink() {
      return getCampaignDetailsHref(this.campaignId)
    },
  },
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: null,
      validator: s => componentSizes.includes(s),
    },
  },
}
export default CampaignDetailsButton
</script>
