<template lang="pug">
b-sidebar(
  :id='id'
  :right='right'
  :title='title'
  lazy
  shadow
  :width='width'
  v-model='showSidebar'
)
  slot
</template>

<script>
export const Sidebar = {
  data() {
    return {
      showSidebar: false,
    }
  },
  methods: {
    close() {
      this.toggle(false)
    },
    hide() {
      return this.close()
    },
    show() {
      this.toggle(true)
    },
    toggle(v) {
      if (v !== this.showSidebar) {
        this.showSidebar = !this.showSidebar
        this.$emit('input', this.showSidebar)
        this.$emit(v ? 'show' : 'close')
      }
    },
  },
  props: {
    id: String,
    right: Boolean,
    title: {
      type: String,
      default: 'Sidebar',
    },
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '25em',
    },
  },
  watch: {
    value: 'toggle',
  }
}
export default Sidebar
</script>