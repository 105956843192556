import {
  addSectionGroup,
  getViewAttributes,
  removeSectionGroup,
  resetSectionGroups,
  updateGroupSection,
  updateSectionGroupOrder,
  updateView,
} from '../actions/views'
import { compareAndReassign } from '../../utilities/compareAndReassign'
import { getDefaultSections } from '../getDefaultSections'
import { moveArrayElement } from '../../utilities/moveArrayElement'
import { standardiseString } from '../../../format/string'
import log from '../../utilities/log'

const logger = log('ViewStore')

export const View = {
  state: {
    key: 'default',
    name: 'Default',
    sections: getDefaultSections(),
    sectionGroup: 'default',
    sectionGroups: [
      {
        key: 'default',
        label: 'Detail sections',
        isDefault: true,
        sections: []
      },
    ],
    showSpendCumulative: false,
    loaded: false,
  },
  getters: {
    allSections: (state, _g, rootState) =>
      state.sections.map((section, idx) => ({
        ...section,
        group: (
          state.sectionGroups.find((group) =>
            group.sections.includes(section.key),
          ) || {
            key: 'default',
          }
        ).key,
        idx,
        isShown: (!section.limitedFeature || !rootState.currentUser.limitFeatures),
        title: section.label || standardiseString(section.key),
      })),
    currentSectionGroup({ sectionGroups }, { currentSectionGroupIdx }) {
      return sectionGroups[currentSectionGroupIdx]
    },
    currentSectionGroupIdx(state) {
      return state.sectionGroups.findIndex(
        (g) => g.key === state.sectionGroup,
      )
    },
    hiddenSections: (_state, getters) =>
      getters.allSections.filter(({ isShown, visible }) => (!visible && isShown)),
    nextVisibleSectionIdx: (state, getters) => (idx, group = state.sectionGroup) => {
      const currentVisibleIndex = getters.visibleGroupSections(group).findIndex(
        ({ idx: i }) => i === idx,
      )
      if (currentVisibleIndex + 1 < getters.visibleGroupSections(group).length) {
        return getters.visibleGroupSections(group)[currentVisibleIndex + 1].idx
      }
      return -1
    },
    prevVisibleSectionIdx: (state, getters) => (idx, group = state.sectionGroup) => {
      const currentVisibleIndex = getters.visibleGroupSections(group).findIndex(
        ({ idx: i }) => i === idx,
      )
      if (currentVisibleIndex - 1 >= 0) {
        return getters.visibleGroupSections(group)[currentVisibleIndex - 1].idx
      }
      return -1
    },
    sectionGroupKeys: state => state.sectionGroups.map(g => g.key),
    visibleGroupSections: (_state, getters) => (groupKey) =>
      getters.allSections.filter(
        ({ isShown, visible, group }) => (isShown && visible && groupKey === group),
      ),
    visibleSections: (_state, getters) =>
      getters.allSections.filter(({ isShown, visible }) => isShown && visible),
  },
  mutations: {
    sectionGroups(state, payload) {
      if (payload?.length) {
        state.sectionGroups = payload
      }
    },
    sections(state, payload) {
      const defaultSections = getDefaultSections()

      if (payload?.length) {
        state.sections = compareAndReassign(payload, defaultSections)
      }
    },
    setSectionGroup(state, idx) {
      logger.info('setSectionGroupIdx', idx)
      state.sectionGroup = state.sectionGroups[idx].key
    },
    setSectionVisibility(state, { section, visible }) {
      state.sections.find((s) => s.key === section).visible = !!visible
      state.sections = [...state.sections]
    },
    switchSpendCumulative(state, payload) {
      state.showSpendCumulative = !!payload
    },
    loaded(state, payload) {
      state.loaded = payload
    },
  },
  actions: {
    async addSectionGroup({ dispatch, state }, { color, label, update }) {
      const { data } = await addSectionGroup({
        color,
        group: update ? state.sectionGroup : undefined,
        view: state.key,
        label,
      })
      await dispatch('get')
      logger.info('addSectionGroup response', data)
      return data.success
    },
    async get({ commit, state }) {
      const {
        data: { showSpendCumulative, sectionGroups, sections },
      } = await getViewAttributes(state.key)

      logger.info('View:get', {
        sectionGroups
      })
      commit('sections', sections)
      commit('sectionGroups', sectionGroups)
      commit('switchSpendCumulative', showSpendCumulative)
      commit('loaded', true)
    },
    async removeSectionGroup({ commit, dispatch, state }, group = state.sectionGroup) {
      const { data } = await removeSectionGroup({
        group,
        view: state.key,
      })
      commit('setSectionGroup', 0)
      await dispatch('get')
      return data.success
    },
    async resetSectionGroups({ dispatch, state }) {
      const { data } = await resetSectionGroups(state.key)
      await dispatch('get')
      return data.success
    },
    async reorderSection(
      { dispatch, commit, state },
      { currentPosition, targetPosition },
    ) {
      const reorderedSections = moveArrayElement(
        state.sections,
        currentPosition,
        targetPosition,
      )

      commit('sections', reorderedSections)
      return dispatch('updateSections')
    },
    async reorderSectionGroup(
      { dispatch, commit, state },
      { currentPosition, targetPosition },
    ) {
      const reorderedGroups = moveArrayElement(
        state.sectionGroups,
        currentPosition,
        targetPosition,
      )

      commit('sectionGroups', reorderedGroups)
      return dispatch('updateGroupOrder')
    },
    async reassignSection(
      { dispatch, commit, state },
      { section, source, target },
    ) {
      commit('setSectionVisibility', {
        section,
        visible: !!target,
      })
      if (source) {
        await updateGroupSection({
          group: source,
          remove: true,
          section,
          view: state.key,
        })
      }
      if (target) {
        await updateGroupSection({
          view: state.key,
          group: target,
          section,
        })
      }
      await dispatch('updateSections')
      await dispatch('get')
    },
    async toggleSection(
      { commit, dispatch, state },
      { key, visible },
    ) {
      const updatedSections = [...state.sections]
      updatedSections.find((s) => s.key === key).visible = visible
      commit('sections', updatedSections)
      if (state.sectionGroup !== 'default') {
        await updateGroupSection({
          view: state.key,
          group: state.sectionGroup,
          remove: !visible,
          section: key,
        })
      }
      await dispatch('updateSections')
      await dispatch('get')
    },
    async updateGroupOrder({ dispatch, getters, state }) {
      const {
        data: { success },
      } = await updateSectionGroupOrder({
        groupOrder: getters.sectionGroupKeys,
        view: state.key,
      })
      await dispatch('get')
      return success
    },
    async updateSections({ state }) {
      const {
        data: { success },
      } = await updateView({
        key: state.key,
        sections: state.sections,
      })

      return success
    },
    async updateShowCumulative({ state, commit }, to) {
      commit('switchSpendCumulative', to)
      const {
        data: { success },
      } = await updateView({
        key: state.key,
        showSpendCumulative: to,
      })

      return success
    },
  },
}
