import { mountVuePage } from '../mountVuePage'
import LoginUi from './LoginUi.vue'
import { CurrentUser } from '../users/stores/CurrentUser'
import { routes } from './routes'

export const login = mountVuePage({
  rootComponent: LoginUi,
  routes,
  store: {
    modules: {
      currentUser: {
        namespaced: true,
        ...CurrentUser,
      },
    }
  },
  title: 'Login',
})
