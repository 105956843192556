import Ui from './FactoryUi.vue'
import { FactoryCombinedStore } from './FactoryCombinedStore'
import { mountVuePage } from '../mountVuePage'
import { routes } from './routes'

mountVuePage({
  faviconName: 'factory',
  rootComponent: Ui,
  routes,
  store: FactoryCombinedStore,
  title: 'Factory',
})
