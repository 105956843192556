<template lang="pug">
span
  template(v-if='textMode')
    | {{ statusName }}
  template(v-else)
    Icon.campaign-status-indicator(
      v-k-popover.hover.top='status.title'
      :class='status.className'
      :name='status.icon'
      :title='status.title'
    )
</template>

<script>
import { statusIndicators } from '../campaign-constants'
import { Icon } from '@/components/Icon.vue'
import { KPopover } from '@/directives'

export const CampaignStatusIndicator = {
  components: {
    Icon,
  },
  directives: {
    KPopover,
  },
  computed: {
    status() {
      const status = statusIndicators.find(status => {
        return this.campaign[status.type] === true
      })
      // If there is no returned status, use 'unknown' status type
      return status || statusIndicators[statusIndicators.length - 1]
    },
    statusName() {
      return this.campaign.status_name
    },
  },
  props: ['campaign', 'textMode'],
}
export default CampaignStatusIndicator
</script>

<style>
.campaign-status-indicator {
  margin-left: 5px;
  margin-right: 5px;
}
</style>