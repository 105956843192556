import { list } from '../campaigns/actions/flagSheets'

export const FlagsStore = {
  state: {
    flags: [],
    flagged: [],
    loading: false,
  },
  getters: {
    isFlagged: ({ flagged }) => ({ id, key }) => !!flagged.find(
      (m) => (m.key === key && m.id === id)
    ),
    loaded: ({ flags }) => flags?.length > 0,
  },
  mutations: {
    addFlagged(state, { id, key }) {
      state.flagged = [...state.flagged, { id, key }]
    },
    setFlags(state, payload = []) {
      state.flags = payload
    },
    setLoading(state, value = false) {
      state.loading = !!value
    },
  },
  actions: {
    async submitFlag({ commit }, { campaignId, key }) {},
    async getFlags({ commit, getters: { loaded }, state }) {
      if (loaded || state.loading) return false
      commit('setLoading', true)
      const {
        data: { flagSheets },
      } = await list()
      commit('setFlags', flagSheets)
      commit('setLoading', false)
    },
  },
}
