export const routes = [
  {
    path: '/',
    component: () => import('../ranking/components/RankingEpochNew.vue'),
  },
  {
    path: '/:routedEpochPath',
    component: () =>
      import('../ranking/components/RankingEpochsBoard.vue'),
  },
]
