<template lang="pug">
b-tooltip(
  :placement='placement'
  :target='target'
  :title='title'
  :triggers='triggers'
  :variant='variant'
)
  slot
</template>

<script>
import { popperPlacements } from './constants'

export const Tooltip = {
  props: {
    placement: {
      type: String,
      default: 'top',
      validator: p => popperPlacements.includes(p),
    },
    target: {
      type: [HTMLElement, SVGElement, Function, Object, String],
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    triggers: {
      type: [Array, String],
      default: 'hover focus',
    },
    variant: {
      type: String,
      default: null,
    },
  },
}
export default Tooltip
</script>
