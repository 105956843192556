const focusClass = 'feature-focus'
const focusOpenClass = 'feature-focus-open'
const focusOverlayClass = 'feature-focus-overlay'
const modalClass = 'modal'

export const KFocus = {
  inserted(el, binding, vnode) {
    if (!el.hasAttribute('tabindex')) {
      // Ensure the element retains focus to prevent ancestor
      // escape keydown events from being triggered
      el.setAttribute('tabindex', -1)
    }

    el.addEventListener('keydown', (event) => {
      if (
        event.key === 'Escape' &&
        vnode.context[binding.expression] === true
      ) {
        event.stopPropagation()
        vnode.context[binding.expression] = false
      }
    })

    el.parentNode.addEventListener('click', (event) => {
      if (
        event.target === el.parentNode &&
        vnode.context[binding.expression] === true
      ) {
        vnode.context[binding.expression] = false
      }
    })
  },
  update(el, binding) {
    const classAction = (binding.value === true) ? 'add' : 'remove'
    el.classList[classAction](focusClass)
    el.parentNode.classList[classAction](focusOverlayClass)

    const container = el.closest('.' + modalClass) || document.body
    container.classList[classAction](focusOpenClass)
  },
}
