export const routes = [
  {
    path: '/',
    title: 'Optim',
    component: () => import('./OptimView.vue'),
  },
  {
    path: '/budgets',
    title: 'Budgets',
    component: () => import('../budgetMoves/BudgetMovesView.vue'),
  },
  {
    path: '/global-blocklist',
    title: 'Global blocklist',
    component: () =>
      import('../globalBlocklist/GlobalBlocklistView.vue'),
  },
  {
    path: '/client-blocklists',
    title: 'Client blocklists',
    component: () =>
      import('../clientBlocklists/ClientBlocklistsView.vue'),
  },
  { href: '/lookalikes', title: 'Third-party lookalikes' },
]
