<template lang="pug">
b-alert(
  :dismissible='dismissible'
  :variant='variant'
  :show='show'
)
  slot
</template>
<script>
export const Alert = {
  props: {
    dismissible: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: 'info',
    },
  },
}
export default Alert
</script>