<template lang="pug">
BCard(
  :bg-variant='bgVariant'
  :body-class='bodyClass'
  :border-variant='borderVariant'
  :header='header'
  :no-body='noBody'
  :title='title'
  :flush='flush'
)
  template(
    v-if='$slots["header"]'
    #header
  )
    slot(name='header')
  template(
    v-if='$slots["footer"]'
    #footer
  )
    slot(name='footer')
  slot
</template>
<script>
export const Card = {
  props: {
    bgVariant: String,
    bodyClass: [Array, Object, String],
    borderVariant: String,
    flush: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: '',
    },
    noBody: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    textVariant: String,
  },
}
export default Card
</script>
