<template lang="pug">
List
  ListItem.p-0.border-0
    Button(
      block
      :class='buttonClasses'
      :disabled='disabled'
      :size='size'
      @click='show=!show'
    )
      Icon.float-left(
        v-if='show'
        name='arrows-collapse'
      )
      Icon.float-left(
        v-else
        name='arrows-expand'
      )
      .mx-auto {{ label }}
        Badge.ml-1(
          variant='primary'
          v-if='count > 0'
        ) {{ count }}
        PopoverIcon.ml-1(
          v-if='description'
          v-k-popover.hover.top='description'
        )
  Collapse(v-model='show')
    slot(v-if='show')
</template>
<script>
import { Badge } from './Badge.vue'
import { Button } from './Button'
import { Collapse } from './Collapse.vue'
import { Icon } from './Icon.vue'
import { List } from './List.vue'
import { ListItem } from './ListItem.vue'
import { PopoverIcon } from './PopoverIcon.vue'
import { KPopover } from '@/directives/KPopover'

export const Expanding = {
  components: {
    Badge,
    Button,
    Collapse,
    Icon,
    List,
    ListItem,
    PopoverIcon,
  },
  directives: {
    KPopover,
  },
  computed: {
    buttonClasses() {
      let classes = ''
      if (this.size === 'lg') {
        classes += ' py-4'
      }
      if (this.show) {
        classes += ' text-primary'
      }
      return classes
    },
  },
  data() {
    return {
      show: !!this.value,
    }
  },
  methods: {
    syncShow() {
      if (this.value !== this.show) {
        this.show = this.value
      }
    },
  },
  mounted() {
    this.syncShow()
  },
  props: {
    count: {
      type: Number | String,
    },
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: String,
    size: {
      type: String,
      default: 'md',
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  watch: {
    show(val) {
      if (val) this.$emit('show', val)
      this.$emit('input', val)
    },
    value: 'syncShow',
  },
}

export default Expanding
</script>
<style scoped>
.btn-lg {
  font-size: 200%;
}
</style>
