<template lang="pug">
BListGroupItem(
  :active='active'
  :button='button'
  :variant='variant'
  v-on='$listeners'
)
  slot
</template>
<script>
export const ListItem = {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    button: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
  },
}
export default ListItem
</script>
