export function getRandomColor() {
  const letters = '0123456789ABCDEF'
  let color = '#'

  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

// Solution via:
// https://stackoverflow.com/questions/8132081/generate-a-random-seeded-hex-color
export function getSeededColor(seed) {
  const colorNum = Math.floor((Math.abs(Math.sin(seed) * 16777215)) % 16777215)
  let colorStr = colorNum.toString(16)
  // pad any colors shorter than 6 characters with leading 0s

  while (colorStr.length < 6) {
    colorStr = '0' + colorStr
  }

  return `#${colorStr}`
}
