import { server } from '../server'

export const addCampaigns = async ({ campaignIds }) =>
  server.post('/spend/campaigns', { campaignIds })
export const loadCampaigns = async ({ includeCsv }) =>
  server.post('/spend/load', { includeCsv })
export const getCampaignIds = async () =>
  server.get('/spend/campaigns')
export const updateCampaigns = async ({ campaignIds }) =>
  server.put('/spend/campaigns', { campaignIds })
export const resetCampaigns = async () =>
  server.delete(`/spend/campaigns`)
export const removeCampaign = async campaignId =>
  server.delete(`/spend/${campaignId}`)
