<template lang="pug">
Button.text-primary(
  :href='autoReportLink'
  :size='size'
  target='_blank'
  title='Open campaign and its reports in a new tab'
)
  CampaignAutoReportIcon
</template>

<script>
import { Button } from '@/components/Button'
import CampaignAutoReportIcon from './CampaignAutoReportIcon.vue'
import { componentSizes } from '@/components/constants'
import { getCampaignAutoReportHref } from '../getCampaignAutoReportLink'

export const CampaignAutoReportButton = {
  components: {
    Button,
    CampaignAutoReportIcon,
    getCampaignAutoReportHref,
  },
  computed: {
    autoReportLink() {
      return getCampaignAutoReportHref(this.campaignId)
    },
  },
  props: {
    campaignId: {
      type: Number,
      required: true,
    },
    size: {
      type: String,
      default: null,
      validator: s => componentSizes.includes(s),
    },
  },
}
export default CampaignAutoReportButton
</script>
