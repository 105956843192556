import formatNumber from '../../format/number'
import SpendFormatter from './cells/spendFormatter'
import PerformanceFormatter from './cells/performanceFormatter'
import ActionFormatter from './cells/actionFormatter'
import AdsFormatter from './cells/adsFormatter'
import AttentionFormatter from './cells/attentionFormatter'
import NameLinkFormatter from './cells/nameLinkFormatter'
import ImpactFormatter from './cells/impactFormatter'
import RefreshFormatter from './cells/refreshFormatter'
import DetailFormatter from './cells/detailFormatter'
import NoteFormatter from './cells/noteFormatter'
import { marginOverridesFormatter } from './cells/marginOverridesFormatter'
import BidFormatter from './cells/bidFormatter'
import SlackUserFormatter from './cells/slackUserFormatter'
import ReportLinkFormatter from './cells/reportLinkFormatter'
import CampaignIsLostCauseFormatter from './cells/campaignIsLostCauseFormatter'
import CampaignSubTypeFormatter from './cells/campaignSubTypeFormatter'
import CommentCountFormatter from './cells/commentCountFormatter'
import HistoryFormatter from './cells/historyFormatter'
import ServingFormatter from './cells/servingFormatter'
import { yesNoControlFormatter } from './cells/yesNoControlFormatter'
import { unavailableLabel } from '../../format/string'
import { lastEditFormatter } from './cells/lastEditFormatter'
import { formatPercentWithPrecision } from '@/utilities/formatPercentWithPrecision'
import { groupBudgetFormatter } from './cells/groupBudgetFormatter'

const truthyVals = ['true', true, 1, '1', 'Yes', 'yes']
const integerFilterConversion = (v) => parseInt(v)
const yesNoFilterConversion = (v) =>
  truthyVals.includes(v) ? 'Yes' : 'No'
const defaultFormatter = (v) => formatNumber.fPrec(v)
const dollarCellFormatter = (v) => `$${defaultFormatter(v)}`

const getDefaultFields = () => [
  {
    key: 'campaign_id',
    label: 'ID',
    sortable: false,
    visible: true,
    width: 70,
    filterEnabled: true,
    filterConversion: integerFilterConversion,
  },
  {
    key: 'name',
    label: 'Name',
    sortable: false,
    visible: true,
    width: 200,
    formatter: NameLinkFormatter,
    filterEnabled: true,
  },
  {
    key: 'type_name',
    description: 'Campaign type',
    label: 'Type',
    sortable: false,
    visible: true,
    width: 100,
    filterEnabled: true,
  },
  {
    key: 'goal_type',
    description: 'Campaign goal',
    label: 'Goal',
    sortable: false,
    visible: true,
    width: 70,
    filterEnabled: true,
  },
  {
    key: 'media_type',
    label: 'Media type',
    sortable: false,
    visible: false,
    width: 75,
    filterEnabled: true,
  },
  {
    key: 'bid_multiplied',
    description: 'Max bid with multiplier information',
    label: 'Bid',
    sortable: false,
    visible: true,
    width: 70,
    filterEnabled: true,
    formatter: BidFormatter,
  },
  {
    key: 'status_name',
    label: 'Status',
    sortable: false,
    visible: true,
    filterEnabled: true,
    width: 80,
  },
  {
    key: 'organizationServiceType',
    description: 'Campaign organization service type',
    label: 'Service type',
    sortable: true,
    visible: false,
    filterEnabled: true,
  },
  {
    key: 'adops_control_ui',
    description: 'Yes/No for AdOps control',
    label: 'Adops control',
    sortable: false,
    visible: false,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    width: 60,
    formatter: yesNoControlFormatter,
  },
  {
    key: 'adops_move_budgets_ui',
    description: 'Yes/No for budget moves control',
    label: 'Budget moves',
    sortable: false,
    visible: false,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    width: 60,
    formatter: yesNoControlFormatter,
  },
  {
    key: 'daysInSheet',
    description:
      'How many days has the campaign been in selected sheet',
    label: 'Days in sheet',
    sortable: true,
    visible: false,
    filterEnabled: true,
    formatter: (val) => (isNaN(val) ? unavailableLabel : val),
  },
  {
    key: 'adops_fcap_control_ui',
    description: 'Yes/No for frequency cap control',
    label: 'Fcap control',
    sortable: false,
    visible: false,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    width: 60,
    formatter: yesNoControlFormatter,
  },
  {
    key: 'fcapCombinedLabel',
    description: 'Frequency cap',
    label: 'Fcap',
    sortable: true,
    visible: false,
    filterEnabled: true,
  },
  {
    key: 'groupBudget',
    description: 'Group budget (% of the limit spent)',
    label: 'Group budget (spent)',
    sortable: true,
    visible: false,
    filterEnabled: true,
    formatter: (val, { item }) => groupBudgetFormatter(val, item),
  },
  {
    key: 'marginOverridesEnabledUi',
    description: 'Yes/No for margin overrides state',
    label: 'Margin overrides',
    sortable: false,
    visible: false,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    formatter: (_v, { item, plainText }) =>
      marginOverridesFormatter(item, plainText),
    width: 60,
  },
  {
    key: 'modelEnabledUi',
    description: 'Yes/No for if model is enabled',
    label: 'Model ON',
    sortable: false,
    visible: false,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    width: 60,
  },
  {
    key: 'modelTypeName',
    description:
      'Model type for campaign (Decile bidding / Factor multipliers)',
    label: 'Model type',
    sortable: false,
    visible: false,
    filterEnabled: true,
    width: 110,
  },
  {
    key: 'matchingActiveUi',
    description: 'Yes/No for for broad match being active',
    label: 'Broad match',
    limitedFeature: true,
    sortable: false,
    visible: false,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    width: 60,
  },
  {
    key: 'is42Ui',
    description: 'Yes/No for campaign being marked as 42L case',
    label: '42L',
    sortable: false,
    visible: false,
    hideInAssignment: true,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    width: 60,
    formatter: (_v, { item, plainText }) =>
      CampaignIsLostCauseFormatter({
        subType: '42l',
        data: item,
        plainText,
      }),
  },
  {
    key: 'adops_mass_changes_ui',
    description: 'Yes/No for campaign mass changes',
    label: 'Mass changes',
    sortable: false,
    visible: false,
    hideInAssignment: true,
    filterEnabled: true,
    width: 50,
    formatter: yesNoControlFormatter,
  },
  {
    key: 'is21Ui',
    description: 'Yes/No for campaign being marked as 21L case',
    label: '21L',
    sortable: false,
    visible: false,
    hideInAssignment: true,
    filterEnabled: true,
    filterConversion: yesNoFilterConversion,
    width: 60,
    formatter: (_v, { item, plainText }) =>
      CampaignIsLostCauseFormatter({
        subType: '21l',
        data: item,
        plainText,
      }),
  },
  {
    key: 'hierarchyNameUi',
    description: 'Client hierarchy name',
    label: 'Hierarchy',
    sortable: false,
    visible: false,
    filterEnabled: true,
    width: 140,
  },
  {
    key: 'potential_spend',
    description: 'Daily spend limit value',
    label: 'Limit: Day',
    sortable: false,
    formatter: dollarCellFormatter,
    visible: true,
    width: 90,
  },
  {
    key: 'daily_budget',
    description: 'Explicitly recorded daily budget value',
    label: 'Budget: Day',
    sortable: false,
    formatter: (val) =>
      val > 0 ? dollarCellFormatter(val) : unavailableLabel,
    visible: false,
    width: 100,
  },
  {
    key: 'budgetYLeftoverPercent5Inc',
    description: 'Daily spend limit leftover for yesterday',
    label: 'Limit: Y left',
    sortable: false,
    visible: true,
    formatter: (_v, { item, plainText }) =>
      SpendFormatter({
        absolute: item.potential_spend_left_y,
        relative: item.leftover_percent_y,
        plainText,
      }),
    filterEnabled: true,
    width: 120,
  },
  {
    key: 'potential_spend_left_t',
    description: 'Daily spend limit leftover for today',
    label: 'Limit: T left',
    sortable: false,
    visible: true,
    width: 120,
    formatter: (val, { item, plainText }) =>
      SpendFormatter({
        absolute: val,
        relative: item.leftover_percent_t,
        plainText,
      }),
  },
  {
    filterEnabled: true,
    description: 'Displays information about campaign serving status',
    key: 'servingLabel',
    label: 'Serving',
    sortable: false,
    visible: true,
    formatter: (val, { item, plainText }) =>
      ServingFormatter({
        active: item.currentlyActive,
        hoursLeft: item.serving_hours_left,
        label: val,
        serving: item.serving_current_hour,
        spendLabel: item.spend_label,
        spent: item.spent_perc_topped_t >= 99,
      }, plainText),
    width: 100,
  },
  {
    filterEnabled: false,
    description: 'Last edit author and timestamp',
    key: 'lastEditAt',
    label: 'Last edit',
    sortable: true,
    visible: false,
    formatter: (_v, { item, plainText }) => lastEditFormatter(item, plainText),
    width: 120,
  },
  {
    key: 'spend_t',
    description: 'Progress of today daily spend limit value',
    label: 'Limit: T spend',
    sortable: false,
    visible: true,
    formatter: (val, { item, plainText }) =>
      SpendFormatter({
        absolute: val,
        relative: item.spent_perc_topped_t,
        plainText,
      }),
    width: 120,
  },
  {
    key: 'spent_perc_topped_y',
    description: 'Yesterday daily spend limit progress',
    label: 'Limit: Y spend',
    sortable: false,
    visible: false,
    formatter: (_v, { item, plainText }) =>
      SpendFormatter({
        absolute: item.spend_y,
        relative: item.spent_perc_topped_y,
        plainText,
      }),
    width: 120,
    filterConversion: integerFilterConversion,
    filterEnabled: true,
  },
  {
    key: 'leftover_projection_dollars',
    description: 'Daily limit leftover projection for today',
    label: 'Projected: T left',
    sortable: false,
    visible: true,
    formatter: (val, { item, plainText }) =>
      SpendFormatter({
        absolute: val,
        relative: item.leftover_projection,
        plainText,
      }),
    width: 120,
  },
  {
    isPartial: true,
    key: 'maxPotentialSpendMultiplier',
    description:
      'How many times could campaign spend in full over 24h',
    label: 'SpendX',
    sortable: true,
    visible: false,
    filterEnabled: true,
    formatter: (_v, { item }) => item.spendMultiplierLabel,
  },
  {
    isPartial: true,
    key: 'spendThrottle',
    description:
      'Average spend throttling by system over the last 24h',
    label: '24h throttle',
    sortable: true,
    visible: false,
    formatter: (v) => (v > 0 ? `${v}%` : unavailableLabel),
  },
  {
    isPartial: true,
    key: 'winRate',
    description: 'Campaign win rate over the last 7 days',
    label: 'Win rate',
    sortable: true,
    visible: false,
    formatter: (v) => (v > 0 ? formatPercentWithPrecision(v) : '-'),
  },
  {
    key: 'spend_projection',
    description: 'Projected spend for today',
    label: 'Projected: T spend',
    sortable: false,
    visible: true,
    formatter: (val, { item, plainText }) =>
      SpendFormatter({
        absolute: item.spend_projection_dollars,
        relative: val,
        plainText,
      }),
    width: 120,
  },
  {
    key: 'goal_progress_t',
    description: 'Progress on today performance goal',
    label: 'T performance',
    sortable: false,
    visible: false,
    formatter: (val, { field, plainText }) =>
      PerformanceFormatter({
        loaded: field.partialFieldLoaded,
        value: val,
        plainText,
      }),
    width: 120,
  },
  {
    key: 'goal_progress_y',
    description: 'Progress on yesterday performance goal',
    label: 'Y performance',
    sortable: false,
    visible: false,
    formatter: (val, { field, plainText }) =>
      PerformanceFormatter({
        loaded: field.partialFieldLoaded,
        value: val,
        plainText,
      }),
    width: 120,
  },
  {
    key: 'potential_spend_total',
    description: 'Total campaign spend limit',
    label: 'Limit: Total',
    sortable: false,
    formatter: (val) => (val > 0 ? dollarCellFormatter(val) : unavailableLabel),
    visible: true,
    width: 100,
  },
  {
    key: 'total_budget',
    description: 'Explicitly recorded total budget value',
    label: 'Budget: Total',
    sortable: false,
    formatter: (val) =>
      val > 0 ? dollarCellFormatter(val) : unavailableLabel,
    visible: false,
    width: 110,
  },
  {
    key: 'potential_spend_monthly',
    description: 'Monthly campaign spend limit',
    label: 'Limit: Month',
    sortable: false,
    formatter: (val) => (val > 0 ? dollarCellFormatter(val) : unavailableLabel),
    visible: true,
    width: 110,
  },
  {
    key: 'monthly_budget',
    description: 'Explicitly recorded monthly budget value',
    label: 'Budget: Month',
    sortable: false,
    formatter: (val) =>
      val > 0 ? dollarCellFormatter(val) : unavailableLabel,
    visible: false,
    width: 120,
  },
  {
    key: 'impactLabel',
    description:
      'Projected impact (difference to earlier projected spend) on spend for today',
    label: 'Projected impact',
    sortable: false,
    visible: false,
    hideInAssignment: true,
    formatter: ImpactFormatter,
    filterEnabled: true,
    width: 120,
  },
  {
    key: 'adops_assignment_name',
    description: 'AdOps assignee',
    label: 'AdOps',
    sortable: false,
    visible: true,
    width: 120,
    filterEnabled: true,
    formatter: (val, { item, plainText }) =>
      SlackUserFormatter({
        fullName: item.adops_assignment_id > 0 ? val : null,
        userId: item.adops_assignment_id,
        isMyAssignment: item.isMyAssignment,
        plainText
      }),
  },
  {
    key: 'cs_assignment_name',
    description: 'Client service assignee',
    label: 'CS',
    sortable: false,
    visible: true,
    width: 120,
    filterEnabled: true,
    formatter: (val, { item, plainText }) =>
      SlackUserFormatter({
        fullName: item.cs_assignment_id > 0 ? val : null,
        userId: item.cs_assignment_id,
        plainText,
      }),
  },
  {
    key: 'dfs_count',
    description: 'Campaign start info',
    label: 'Start',
    sortable: false,
    visible: true,
    width: 100,
    filterConversion: integerFilterConversion,
    filterEnabled: true,
    formatter: (_v, { item }) => (item.dfs ? item.dfs : null),
  },
  {
    key: 'dte_count',
    description: 'Campaign end info',
    label: 'End',
    sortable: false,
    visible: true,
    width: 100,
    filterConversion: integerFilterConversion,
    filterEnabled: true,
    formatter: (_v, { item }) => (item.dte ? item.dte : null),
  },
  {
    key: 'historyLabel',
    description: 'Campaign edit history',
    label: 'History',
    sortable: false,
    visible: true,
    width: 100,
    filterEnabled: true,
    formatter: HistoryFormatter,
  },
  {
    key: 'commentCount',
    description:
      'Displays number of comments, opens comment dialog on click',
    label: 'Comments',
    sortable: false,
    visible: false,
    filterEnabled: false,
    hideInAssignment: true,
    cssClass: 'grid-trigger-comments text-center',
    formatter: CommentCountFormatter,
  },
  {
    key: 'noteCount',
    description:
      'Displays number of notes, opens note dialog on click',
    label: 'Notes',
    sortable: false,
    visible: false,
    filterEnabled: false,
    hideInAssignment: true,
    formatter: NoteFormatter,
  },
  {
    key: 'activeAdsLabel',
    description: 'Displays number of active ads',
    label: 'Ads',
    sortable: true,
    visible: false,
    filterEnabled: true,
    hideInAssignment: true,
    formatter: AdsFormatter,
  },
  {
    key: 'kawaUrl',
    description: 'Kawa URL for opening automatic reports',
    label: 'Kawa',
    visible: true,
    sortable: false,
    formatter: ReportLinkFormatter,
    width: 60,
  },
  {
    key: 'switchDetails',
    description: 'Display full campaign details',
    label: 'Details',
    cssClass: 'grid-trigger-details text-center',
    visible: true,
    sortable: false,
    hideInAssignment: true,
    formatter: DetailFormatter,
    width: 80,
  },
  {
    key: 'actions',
    description:
      'Campaign actions control (Take / Mark done / Release)',
    label: 'Actions',
    cssClass: 'grid-actions text-center',
    visible: true,
    sortable: false,
    noVisibilityChanges: true,
    filterEnabled: true,
    formatter: (_v, { item, plainText }) => ActionFormatter(item, plainText),
    width: 120,
  },
  {
    key: 'attention',
    description: 'Campaign attention control (Ignore / Acknowledge)',
    label: 'Attention',
    cssClass: 'grid-actions text-center',
    visible: true,
    filterEnabled: true,
    sortable: false,
    formatter: (_v, { item, plainText }) => AttentionFormatter(item, plainText),
    width: 120,
  },
  {
    key: 'sysDetails',
    description: 'Reload displayed campaign data',
    label: 'Refresh',
    hideInAssignment: true,
    cssClass: 'grid-trigger grid-trigger-refresh text-center',
    visible: true,
    sortable: false,
    formatter: RefreshFormatter,
    width: 60,
  },
  {
    key: 'currentSubType',
    description: 'Sub type (21L / 42L / Other)',
    label: 'Subtype',
    filterEnabled: true,
    formatter: CampaignSubTypeFormatter,
    hideInAssignment: true,
    visible: false,
    sortable: true,
    width: 40,
  },
]

export { defaultFormatter, getDefaultFields }
