<template lang="pug">
InputGroup(:size='size')
  InputGroupPrepend(v-if='label')
    InputGroupText {{ label }}
  BInput(
    :disabled='disabled'
    @input='handleInput'
    type='range'
    v-model='valueInput'
    :max='max'
    :min='min'
    :step='step'
  )
  FormInput.manual-input(
    v-if='manual'
    :disabled='disabled'
    @input='handleInput'
    @keydown.enter.prevent="$emit('enter')"
    type='text'
    v-model='valueInput'
  )
  template(#append)
    InputGroupText(
      v-if='!$slots["append"] && !manual'
      is-text
    ) {{ valueInput }}
    slot(name='append')
</template>

<script>
import { FormInput } from './FormInput.vue'
import {
  InputGroup,
  InputGroupPrepend,
  InputGroupText,
} from './InputGroup'

export const RangeInput = {
  components: {
    FormInput,
    InputGroup,
    InputGroupPrepend,
    InputGroupText,
  },
  data() {
    return {
      valueInput: this.value,
    }
  },
  methods: {
    handleInput(newValue) {
      if (+newValue >= this.min && +newValue <= this.max) {
        this.$emit('input', +newValue)
        return
      }
      this.valueInput = this.value
    },
  },
  props: {
    disabled: Boolean,
    label: String,
    manual: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 100,
    },
    min: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'md',
    },
    step: {
      type: Number | String,
      default: 1,
    },
    value: Number,
  },
  watch: {
    value(newValue, prevValue) {
      if (newValue === prevValue) {
        return
      }
      this.valueInput = newValue
    },
  },
}

export default RangeInput
</script>

<style>
.manual-input {
  max-width: 4em;
}
</style>