import { HasukiCombinedStore } from './HasukiCombinedStore'
import Ui from './Ui.vue'
import { mountVuePage } from '@/mountVuePage'
import { routes } from './routes'

mountVuePage({
  container: '#container',
  rootComponent: Ui,
  routes,
  store: HasukiCombinedStore,
  title: 'Hasuki bulk tools',
})
