<template lang="pug">
div(:style='`width: ${width > 0 ? width + "px"  : "100%"};`')
  div(
    :class='gridClasses'
    :id='elementId'
    :style="`width: 100%; height: ${height}px;`"
  )
  .kawa-grid-footer.text-center(
    v-if="showFooter"
    style='width: 100%;'
  )
    slot(name='footer')
</template>
<script>
import { Button, ButtonGroup, Dropdown, DropdownItem } from '@/components'
import {
  SlickGridMenu,
  SlickGrid,
  SlickCellExternalCopyManager,
  SlickCellSelectionModel,
  EditorLock,
  SlickRowSelectionModel,
} from 'slickgrid'
import 'slickgrid/dist/styles/css/slick-icons.css'
import { rangesToRowIndexes } from 'NEW/components/Grid/utils/rangesToRowIndexes'
import log from '@/utilities/log'
import { applyGridSortCommand } from 'NEW/components/Grid/utils/applyGridSortCommand'
import { getGridMenuOptions } from 'NEW/components/Grid/utils/getGridMenuOptions'
import { getFilteredRows } from 'NEW/components/Grid/utils/getFilteredRows'
import { getSortedRows } from 'NEW/components/Grid/utils/getSortedRows'
import { HeaderMenuWithFilter } from 'NEW/components/Grid/utils/HeaderMenuWithFilter'
import {
  DEFAULT_SELECTION_MODE,
  EVENT_SELECTION_MODE_CHANGED,
  MULTI_ROW_SELECTION_MODE,
  RANGE_SELECTION_MODE,
  SELECTION_MODES,
  UNIQUE_SEPARATOR,
  COMMANDS,
} from 'NEW/components/Grid/constants'
import { getGridColumns } from 'NEW/components/Grid/utils/getGridColumns'
import { isDeepEqual } from 'NEW/utilities/isDeepEqual'
import { downloadCSVContent } from 'NEW/utilities/download/downloadCSVContent'
import { isMatchingQuery } from 'NEW/components/Grid/utils/isMatchingQuery'
import { getColumnValueOptions } from 'NEW/components/Grid/utils/getColumnValueOptions'

const logger = log('Grid')

export const Grid = {
  components: {
    ButtonGroup,
    Button,
    Dropdown,
    DropdownItem,
  },
  computed: {
    downloadableCsvFields() {
      if (!Array.isArray(this.downloadable)) {
        return this.fields
      }
      return this.downloadable.map((key) =>
        this.fields.find((field) => field.key === key)
      )
    },
    filtersActive() {
      return (
        Object.values(this.filters).filter(
          (values) => values?.length > 0,
        ).length > 0
      )
    },
    gridClasses() {
      return {
        'slick-container': true,
        'kawa-grid': true,
      }
    },
    isDownloadable() {
      return !!this.downloadable && this.items.length > 0
    },
    selectionModeLabel() {
      return this.currentSelectionMode
    },
    selectedRows() {
      return this.selectedRowIdxs.map((r) => this.grid.getDataItem(r))
    },
    sortColumns() {
      return this.grid?.getSortColumns() ?? []
    },
    visibleColumns() {
      return this.columns.filter((c) => c.visible !== false)
    },
  },
  data() {
    const view = this

    return {
      columns: [],
      currentSelectionMode: view.selectionMode,
      filters: {},
      grid: null,
      gridMenu: null,
      gridOptions: {
        autosizeColsMode: view.autosizeColumns ? 'FCV' : 'NOA',
        editable: true,
        enableCellNavigation: true,
        enableColumnReorder: false,
        editorLock: new EditorLock(),
        gridMenu: getGridMenuOptions(view),
      },
      headerMenu: null,
      selectedRowIdxs: [],
      SELECTION_MODES,
    }
  },
  methods: {
    addMatchingFilterValues({ column, query }) {
      const { [column.id]: existingValues = [], ...otherFilters } =
        this.filters
      const rows = getFilteredRows({
        columns: this.getColumnDefs(),
        filters: otherFilters,
        rows: getSortedRows({
          rows: this.items.slice(0),
          sortColumns: this.sortColumns,
        }),
      })

      const values = getColumnValueOptions({
        key: column.id,
        filterConversion: column.filterConversion,
        rows,
      }).filter((value) => {
          if (existingValues.includes(value)) {
            return false
          }
          return isMatchingQuery(query, value)
      })
      logger.info('addMatchingFilterValues', { values })
      this.setFilters(
        {
          ...this.filters,
          [column.id]: [...existingValues, ...values],
        },
        column.id,
      )
    },
    assignFieldsToColumns() {
      this.columns = [...this.fields]
    },
    columnOf(id) {
      return this.grid.getColumns().find((c) => c.id === id)
    },
    filterValue({ col, value }) {
      const column = this.columnOf(col)
      if (!column.filterable) {
        logger.warn(`filterValue: column[${col}] is not filterable`)
        return
      }
      let filterValues = (this.filters[col] ?? []).map((v) => `${v}`)

      // Let's remove already filtered value
      filterValues = filterValues.includes(value)
        ? filterValues.filter((v) => v !== value)
        : [...filterValues, value]

      // Let's remove empty filter
      // or a filter with all possible values
      if (
        !filterValues.length ||
        getColumnValueOptions({
          key: col,
          filterConversion: this.columnOf(col).filterConversion,
          rows: this.items,
        }).every((v) => filterValues.includes(v))
      ) {
        this.resetFilter(col)
        return
      }

      // Let's add new filter
      this.setFilters(
        {
          ...this.filters,
          [col]: filterValues,
        },
        col,
      )
    },
    getColumnDefs() {
      return getGridColumns({
        columns: this.visibleColumns,
        filters: this.filters,
        rows: this.items,
      })
    },
    getGridRows() {
      const rows = getFilteredRows({
        columns: this.getColumnDefs(),
        filters: this.filters,
        rows: getSortedRows({
          rows: this.items.slice(0),
          sortColumns: this.sortColumns,
        }),
      })
      rows.getItemMetadata = (idx) => {
        const item = rows[idx]
        return this.rowFormatter ? this.rowFormatter(item) : null
      }

      return rows
    },
    handleColumnsResizing() {
      this.grid.onColumnsResized.subscribe(
        (event, { triggeredByColumn }) => {
          this.$emit('column-resized', event, {
            columnId: triggeredByColumn,
            column: this.grid
              .getColumns()
              .find((c) => c.id === triggeredByColumn),
            grid: this.grid,
          })
        },
      )
    },
    handleGridClick() {
      this.grid.onClick.subscribe(
        (event, { row: rowIdx, cell: cellIdx }) => {
          const item = this.grid.getDataItem(rowIdx)
          const column = this.getColumnDefs()[cellIdx]

          this.$emit('grid-click', event, {
            cellIdx,
            column,
            grid: this.grid,
            item,
            rowIdx,
            value: item?.[column.id],
          })
        },
      )
    },
    handleSelection(newSelectionMode, prevSelectionMode) {
      let selectionModel
      if (this.selectedRowIdxs.length) {
        this.unselectRows()
      }
      if (this.currentSelectionMode === RANGE_SELECTION_MODE) {
        selectionModel = new SlickCellSelectionModel()
        this.grid.setSelectionModel(selectionModel)
        this.grid.registerPlugin(
          new SlickCellExternalCopyManager({
            readOnlyMode: false,
            includeHeaderWhenCopying: false,
          }),
        )
      }
      if (this.currentSelectionMode === MULTI_ROW_SELECTION_MODE) {
        selectionModel = new SlickRowSelectionModel({
          dragToSelect: true,
        })
        this.grid.setSelectionModel(selectionModel)
      }

      selectionModel.onSelectedRangesChanged?.subscribe(
        (event, ranges) => {
          const rowIndexes = rangesToRowIndexes(ranges)
          const previousSelectedRows = [...this.selectedRowIdxs]
          this.selectedRowIdxs = rowIndexes
          const rowIds = this.selectedRows.map((r) => r[this.idKey])
          this.$emit('selected-ranges-changed', event, {
            grid: this.grid,
            ranges,
            rowIds,
            rowIndexes,
            rows: this.selectedRows,
          })
          this.$emit('selected-rows-changed', event, {
            grid: this.grid,
            rowIds,
            rowIndexes,
            previousSelectedRows,
            rows: this.selectedRows,
          })
        },
      )
      if (newSelectionMode !== prevSelectionMode) {
        this.$emit(EVENT_SELECTION_MODE_CHANGED, {
          grid: this.grid,
          newSelectionMode,
          prevSelectionMode,
        })
      }
    },
    setColumnVisibility(id, visible) {
      const column = this.columns.find((c) => c.key === id)
      if (!column) {
        logger.warn('setColumnVisibility: column not found', id)
        return
      }
      this.grid.setSortColumns([])
      column.visible = !!visible
      logger.info('setColumnVisibility', { id, visible })
      this.$emit('column-changed', {
        column,
        columnId: id,
        grid: this.grid,
        payload: { visible: column.visible },
      })
    },
    initializeGrid() {
      this.assignFieldsToColumns()
      this.grid = new SlickGrid(
        `#${this.elementId}`,
        this.getGridRows(),
        this.getColumnDefs(),
        this.gridOptions,
      )
      this.handleColumnsResizing()
      this.handleGridClick()
      this.handleSelection()
      this.initializeHeaderMenu()
      this.initializeGridMenu()
      this.updateGrid('initializeGrid')
    },
    initializeGridMenu() {
      this.gridMenu = new SlickGridMenu([], this.grid, this.gridOptions)
    },
    initializeGridTopPanel() {
      const panel = this.grid.getTopPanel()
      return panel
    },
    initializeHeaderMenu() {
      this.headerMenu = new HeaderMenuWithFilter()
      this.grid.registerPlugin(this.headerMenu)
      this.headerMenu.onHeaderCommand.subscribe(
        (_event, { column, command, item, query }) => {
          logger.info('header menu command', {
            column,
            command,
            item,
            query,
            _event,
          })
          if (command === COMMANDS.HIDE) {
            this.setColumnVisibility(column.id, false)
          }
          if (command === COMMANDS.SELECT_ALL_FOUND) {
            this.addMatchingFilterValues({ column, query })
          }
          if (command.includes(COMMANDS.SORT)) {
            applyGridSortCommand({
              column,
              command,
              grid: this.grid,
            })
            this.$emit('sort-changed', {
              column,
              command,
              grid: this.grid,
            })
          }
          if (
            command.includes(`${COMMANDS.FILTER}${UNIQUE_SEPARATOR}`)
          ) {
            const [_, value] = command.split(UNIQUE_SEPARATOR)
            this.filterValue({
              col: column.id,
              value,
            })
          }
          if (command === COMMANDS.CLEAR_FILTER) {
            this.resetFilter(column.id)
          }
          if (command === COMMANDS.INVERT_FILTER) {
            this.invertFilter(column.id)
          }
          // this.updateGrid('headerMenu.onCommand')
        },
      )
    },
    onDownload() {
      return downloadCSVContent({
        fields: this.downloadableCsvFields,
        items: this.items,
      })
    },
    onFiltersChanged(col) {
      if (col) {
        this.$emit('filters-changed', {
          columnId: col,
          grid: this.grid,
          filters: this.filters,
          values: this.filters[col],
        })
      }
      this.updateGrid('onFiltersChanged')
      // this.updateHeaderMenu()
    },
    invertFilter(col) {
      logger.info(`invertFilter [${col}]`)
      const currentValues = this.filters[col]
      const column = this.columnOf(col)
      const valueOptions = getColumnValueOptions({
        key: col,
        filterConversion: column.filterConversion,
        rows: this.items,
      })

      this.setFilters(
        {
          ...this.filters,
          [col]: valueOptions.filter(
            (v) => !currentValues.includes(v),
          ),
        },
        col,
      )
    },
    resetColSize() {
      this.grid.autosizeColumns()
    },
    resetFilter(col) {
      logger.info(`resetFilter [${col}]`)
      const { [col]: _values, ...filters } = this.filters
      this.setFilters(filters, col)
    },
    resetFilters() {
      this.setFilters({})
    },
    selectRowsByIds(ids) {
      const indexes = ids.map((id) =>
        this.grid.getData().findIndex((r) => r[this.idKey] === id),
      )
      this.selectRowsByIndexes(indexes)
    },
    selectRowsByIndexes(indexes) {
      this.grid.setSelectedRows(indexes)
    },
    setFilters(payload = {}, columnId) {
      logger.info('setFilters', { columnId, payload })
      this.filters = payload
      this.onFiltersChanged(columnId)
    },
    switchSelectionMode(mode) {
      logger.info('switchSelectionMode', mode)
      this.currentSelectionMode = mode
    },
    unselectRows() {
      this.selectRowsByIndexes([])
      this.selectedRowIdxs = []
      logger.info('unselectRows', this.grid)
      this.grid.setSelectedRanges?.([])
    },
    updateRow(row) {
      const idx = this.items.findIndex(
        (r) => r[this.idKey] === row[this.idKey],
      )
      if (idx < 0) return false
      this.items.splice(idx, 1, row)
      return true
    },
    updateGrid(source) {
      const now = Date.now()
      logger.info(`updateGrid [${this.elementId}>${source}]`)
      if (!this.grid) return
      this.grid.setColumns(this.getColumnDefs())
      this.grid.setData(this.getGridRows())
      if (this.autosizeColumns) {
        this.resetColSize()
      }
      this.grid.invalidate()
      logger.info(`updateGrid [>${source}]:took`, ((Date.now() - now) / 1000).toFixed(3), 's')
    },
    updateHeaderMenu() {
      logger.warn('updateHeaderMenu')
      this.headerMenu.rerender()
    },
  },
  mounted() {
    this.initializeGrid()
  },
  props: {
    autosizeColumns: {
      type: Boolean,
      default: false,
    },
    downloadable: {
      type: [Array, Boolean],
      default: false,
    },
    fields: {
      type: Array,
      required: true,
    },
    elementId: {
      type: String,
      default: 'custom-grid',
    },
    height: {
      type: Number,
      default: 500,
    },
    idKey: {
      type: String,
      default: 'id',
    },
    rowFormatter: {
      type: Function,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    selectionMode: {
      type: String,
      default: () => DEFAULT_SELECTION_MODE,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: null,
    },
  },
  watch: {
    currentSelectionMode: 'handleSelection',
    fields(newValue, previousValue) {
      if (isDeepEqual(newValue, previousValue)) return
      this.assignFieldsToColumns()
      return this.updateGrid('watch:fields')
    },
    items() {
      return this.updateGrid('watch:items')
    },
    sortColumns(newValue, previousValue) {
      logger.info(`watch:sortColumns`, { newValue, previousValue })
      // if (isDeepEqual(newValue, previousValue)) return
      this.updateGrid('watch:sortColumns')
    },
  },
}
export default Grid
</script>
<style lang="scss">
@import './grid.scss';
</style>
