<template lang="pug">
span(:title='title || label')
  BIcon(:icon='iconName')
  span.ml-1(v-if='label && !isSmallView') {{ label }}
</template>

  <script>
  import {
    BIcon,
    BIconArrowBarDown,
    BIconArrowBarUp,
    BIconArrowCounterclockwise,
    BIconArrowDown,
    BIconArrowRepeat,
    BIconArrowsAngleContract,
    BIconArrowsAngleExpand,
    BIconArrowsCollapse,
    BIconArrowsExpand,
    BIconArrowUp,
    BIconAsterisk,
    BIconBackspace,
    BIconBell,
    BIconBookmark,
    BIconBookmarkDashFill,
    BIconBookmarkStarFill,
    BIconBoxArrowRight,
    BIconCalendar,
    BIconCardImage,
    BIconCardList,
    BIconCardText,
    BIconCaretDown,
    BIconCaretDownFill,
    BIconCaretRight,
    BIconCaretRightFill,
    BIconCaretRightSquareFill,
    BIconCaretUpFill,
    BIconChatLeftTextFill,
    BIconCheckCircleFill,
    BIconCheck,
    BIconCheck2,
    BIconCheck2All,
    BIconChevronBarRight,
    BIconChevronRight,
    BIconChevronLeft,
    BIconChevronBarLeft,
    BIconCircleHalf,
    BIconClipboardPlus,
    BIconCloudArrowUpFill,
    BIconClock,
    BIconCode,
    BIconCollection,
    BIconDiagram2,
    BIconDownload,
    BIconEraser,
    BIconEraserFill,
    BIconEyeFill,
    BIconEyeSlashFill,
    BIconExclamationCircleFill,
    BIconFileEarmarkX,
    BIconFileEarmarkPlus,
    BIconFileEarmarkSpreadsheetFill,
    BIconFilePlus,
    BIconFileRuledFill,
    BIconFileSpreadsheetFill,
    BIconFilterCircle,
    BIconFilterCircleFill,
    BIconFlagFill,
    BIconFolderX,
    BIconGear,
    BIconGearFill,
    BIconGrid,
    BIconGrid3x2,
    BIconHandThumbsUp,
    BIconHeartFill,
    BIconHouse,
    BIconInfo,
    BIconInfoCircle,
    BIconInfoCircleFill,
    BIconInfoSquare,
    BIconInputCursor,
    BIconJournalBookmark,
    BIconJournalBookmarkFill,
    BIconJournalCheck,
    BIconLayoutThreeColumns,
    BIconLightningChargeFill,
    BIconLightningFill,
    BIconLink,
    BIconLink45deg,
    BIconList,
    BIconListUl,
    BIconListOl,
    BIconMoonStarsFill,
    BIconNodeMinus,
    BIconNodePlus,
    BIconPause,
    BIconPauseCircle,
    BIconPauseFill,
    BIconPencil,
    BIconPencilSquare,
    BIconPeople,
    BIconPersonCircle,
    BIconPersonFill,
    BIconPersonPlus,
    BIconPersonX,
    BIconPlay,
    BIconPlayBtn,
    BIconPlayFill,
    BIconPlus,
    BIconSearch,
    BIconShieldFillPlus,
    BIconShift,
    BIconSquare,
    BIconSquareHalf,
    BIconSquareFill,
    BIconStopCircle,
    BIconStopFill,
    BIconSunFill,
    BIconToggleOff,
    BIconToggleOn,
    BIconTrash,
    BIconTrophy,
    BIconTrophyFill,
    BIconUpload,
    BIconViewList,
    BIconWrench,
    BIconX,
    BIconXCircle,
    BIconXCircleFill,
  } from 'bootstrap-vue'
  import { bootstrapIconMap as bIconMap } from './constants'
import { useViewSize } from '@/utilities/useViewSize'

  export const Icon = {
    mixins: [useViewSize],
    computed: {
      iconName() {
        return bIconMap[this.name] ?? this.name
      },
    },
    components: {
      BIcon,
      BIconArrowBarDown,
      BIconArrowBarUp,
      BIconArrowCounterclockwise,
      BIconArrowDown,
      BIconArrowRepeat,
      BIconArrowsAngleContract,
      BIconArrowsAngleExpand,
      BIconArrowsCollapse,
      BIconArrowsExpand,
      BIconArrowUp,
      BIconAsterisk,
      BIconBackspace,
      BIconBell,
      BIconBookmark,
      BIconBookmarkDashFill,
      BIconBookmarkStarFill,
      BIconBoxArrowRight,
      BIconCalendar,
      BIconCardImage,
      BIconCardList,
      BIconCardText,
      BIconCaretDown,
      BIconCaretDownFill,
      BIconCaretRight,
      BIconCaretRightFill,
      BIconCaretRightSquareFill,
      BIconCaretUpFill,
      BIconCheck,
      BIconCheck2,
      BIconCheck2All,
      BIconChatLeftTextFill,
      BIconCheckCircleFill,
      BIconChevronBarRight,
      BIconChevronRight,
      BIconChevronLeft,
      BIconChevronBarLeft,
      BIconCircleHalf,
      BIconClipboardPlus,
      BIconClock,
      BIconCloudArrowUpFill,
      BIconCode,
      BIconCollection,
      BIconDiagram2,
      BIconDownload,
      BIconEraser,
      BIconEraserFill,
      BIconEyeFill,
      BIconEyeSlashFill,
      BIconExclamationCircleFill,
      BIconFileEarmarkX,
      BIconFileEarmarkPlus,
      BIconFileEarmarkSpreadsheetFill,
      BIconFilePlus,
      BIconFileRuledFill,
      BIconFileSpreadsheetFill,
      BIconFilterCircle,
      BIconFilterCircleFill,
      BIconFlagFill,
      BIconFolderX,
      BIconGear,
      BIconGearFill,
      BIconGrid,
      BIconGrid3x2,
      BIconHandThumbsUp,
      BIconHeartFill,
      BIconHouse,
      BIconInfo,
      BIconInfoCircle,
      BIconInfoCircleFill,
      BIconInfoSquare,
      BIconInputCursor,
      BIconJournalBookmark,
      BIconJournalBookmarkFill,
      BIconJournalCheck,
      BIconLayoutThreeColumns,
      BIconLightningChargeFill,
      BIconLightningFill,
      BIconLink,
      BIconLink45deg,
      BIconList,
      BIconListUl,
      BIconListOl,
      BIconMoonStarsFill,
      BIconNodeMinus,
      BIconNodePlus,
      BIconPause,
      BIconPauseFill,
      BIconPauseCircle,
      BIconPencil,
      BIconPencilSquare,
      BIconPeople,
      BIconPersonCircle,
      BIconPersonFill,
      BIconPersonPlus,
      BIconPersonX,
      BIconPlay,
      BIconPlayBtn,
      BIconPlayFill,
      BIconPlus,
      BIconSearch,
      BIconShieldFillPlus,
      BIconShift,
      BIconSquare,
      BIconSquareHalf,
      BIconSquareFill,
      BIconStopCircle,
      BIconStopFill,
      BIconSunFill,
      BIconToggleOff,
      BIconToggleOn,
      BIconTrash,
      BIconTrophy,
      BIconTrophyFill,
      BIconUpload,
      BIconViewList,
      BIconWrench,
      BIconX,
      BIconXCircle,
      BIconXCircleFill,
    },
    props: {
      label: String,
      name: String,
      title: String,
    },
  }

  export default Icon
  </script>
