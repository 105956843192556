<template lang="pug">
b-skeleton(
  :animation='animation'
  :height='height'
  :size='size'
  :type='type'
  :variant='variant'
  :width='width'
)
</template>

<script>
export const Skeleton = {
  props: {
    animation: {
      type: String,
      default: 'wave',
    },
    height: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
      validator: value => ['text', 'avatar', 'input', 'button'].includes(value),
    },
    variant: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
  },
}
export default Skeleton
</script>