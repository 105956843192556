<template lang="pug">
Navbar.kawa-nav(
  :toggleable='breakpoint'
  :variant='variant'
  :type='type'
)
  NavbarBrand
    KawaMenuDropdown(
      v-if='isLoaded'
      size='md'
    )
  NavbarToggle(:target='navigationId')
  Collapse(
    :id='navigationId'
    is-nav
  )
    NavbarNav
      span(
        v-for='menuItem of items'
        :key='`route-${menuItem.path || menuItem.href }`'
      )
        NavItem(
          v-if='menuItem.path'
          :active='isRouteActive(menuItem)'
          :class='menuItemClass(menuItem)'
          :to='menuItem.path'
          @click='onRouteClick(menuItem)'
        ) {{ menuItem.title }}
        NavItem.navigation-path(
          v-else
          :href='menuItem.href'
        ) {{ menuItem.title }}
      template(v-if='$slots.collapse')
        slot(name="collapse")
    slot
    NavbarNav
      NavItem(:href='getNewClientPath("/profile")')
        Icon(name='person-fill')
      ThemeSwitcher

</template>
<script>
import { getNewClientPath } from '@/utilities/getNewClientPath'
import { Collapse } from './Collapse.vue'
import { Icon } from './Icon.vue'
import { NavItem } from './Nav'
import {
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavbarToggle,
} from './Navbar'
import ThemeSwitcher from './ThemeSwitcher.vue'
import { mapGetters, mapState } from 'vuex'

export const KawaNavigation = {
  components: {
    Collapse,
    Icon,
    KawaMenuDropdown: () => import('./KawaMenuDropdown.vue'),
    NavItem,
    Navbar,
    NavbarBrand,
    NavbarNav,
    NavbarToggle,
    ThemeSwitcher,
  },
  computed: {
    ...mapState('currentUser', ['isLoaded', 'email']),
    ...mapGetters('currentUser', ['isAuthorizedFor']),
    currentRoute() {
      return (
        this.$route.matched &&
        this.$route.matched[0]
      ) ? this.$route.matched[0]
        : this.$route
    },
    currentRoutePath() {
      return this.currentRoute.path || '/'
    },
    currentRouteTitle() {
      const r = this.items.find(
        (i) => this.currentRoutePath === i.path,
      )
      if (!r) return 'Unknown'
      return r.title
    },
    items() {
      return this.routes.filter(
        (r) => !!r.title && this.isAuthorizedFor({
          path: `${document.location.pathname}#${r.path ?? '/'}` ?? r.href
        })
      )
    },
    navigationId() {
      return `kawa-navigation`
    },
  },
  methods: {
    getNewClientPath,
    isRouteActive(menuItem) {
      if (menuItem.path === '/') {
        return (
          this.currentRoutePath === menuItem.path ||
          // Get expected active styling for a menu item with a '/' path
          // when the current route path is more specific but related
          this.currentRoutePath.includes(menuItem.relatedPath)
        )
      }
      // Match a current route path to an identical menu item path, or a
      // specific current route path to a more general menu item path
      return this.currentRoutePath.includes(menuItem.path)
    },
    menuItemClass(menuItem) {
      return this.isRouteActive(menuItem)
        ? 'navigation-title'
        : 'navigation-path'
    },
    onRouteClick(route) {
      this.$emit('routed', route)
    },
  },
  props: {
    breakpoint: {
      type: String,
      default: 'md',
    },
    routes: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'dark',
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
}

export default KawaNavigation
</script>

<style>
.navigation-title .nav-link {
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
}
.kawa-nav.navbar {
  padding: 0.2em 0.4em;
}
</style>