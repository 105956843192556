import { getSampleData } from 'NEW/components/Grid/utils/getSampleData.js'

const { fields } = getSampleData()

// const NEW_STATUS = 'new'

export const TestSheetStore = {
  state: {
    filters: {
      // status: [NEW_STATUS]
    },
    version: 0,
    rows: [],
    updateInterval: null,
  },
  actions: {
    getRows({ commit }) {
      commit('updateRows', getSampleData(100).items)
      commit('updateVersion')
    },
    liveUpdate({ commit, state }) {
      commit('interval', () => {
        const idxToUpdate = Math.floor(
          Math.random() * state.rows.length,
        )
        const row = state.rows[idxToUpdate]

        commit('updateRow', {
          id: row.id,
          payload: {
            time: new Date(),
            version: row.version + 1,
            status: 'update',
          },
        })
      })
    },
    addRows({ commit, state }, count) {
      const [topId] = state.rows
        .map(({ id }) => id)
        .sort((a, b) => b - a)
      const rows = getSampleData(count, topId + 1).items

      commit(
        'appendRows',
        rows.map((row) => ({
          ...row,
          status: 'added',
        })),
      )
      commit('updateVersion')
    },
  },
  getters: {
    fields: (state) => fields.map((field) => ({
      ...field,
      name: field.label,
      visible: true,
    })),
    columnKeys: () => fields.map(({ key }) => key),
  },
  mutations: {
    updateVersion(state, vector = 1) {
      state.version += vector
    },
    appendRows(state, rows) {
      state.rows = [...state.rows, ...rows]
    },
    setFilters(state, payload) {
      state.filters = { ...payload }
    },
    updateRow(state, { id, payload }) {
      const rowIdx = state.rows.findIndex((row) => row.id === id)
      const rowToUpdate = { ...state.rows[rowIdx], ...payload }

      state.rows.splice(rowIdx, 1, rowToUpdate)
    },
    updateRows(state, rows) {
      state.rows = rows
      state.version += 1
    },
    interval(state, fn) {
      if (state.updateInterval) {
        clearInterval(state.updateInterval)
      }

      state.updateInterval = setInterval(fn, 60000)
    },
  },
}
