export default (_v, { item: data, plainText }) => {
  const href = `/auto/${data.campaign_id}`

  if (plainText) {
    return href
  }
  return (
    `<b>` +
      `<a href='${href}' ` +
        `target='_blank' ` +
        `title='Run a report in a new tab for this campaign.'>&#9654;` +
      `</a>` +
    `</b>`
  )
}
