<template lang="pug">
b-nav-item-dropdown(
  :disabled='disabled'
  :id='id'
  :lazy='lazy'
  :menu-class='menuClass'
  :popper-opts='popperOpts'
  :right='right'
)
  //- Button content
  template(
    v-if='$slots["button-content"]'
    #button-content
  )
    slot(name='button-content')

  //- Dropdown menu content
  slot
</template>

<script>
export const NavItemDropdown = {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    menuClass: {
      type: [Array, Object, String],
      default: null,
    },
    popperOpts: {
      type: Object,
      default: () => ({}),
    },
    right: {
      type: Boolean,
      default: false,
    },
  },
}
export default NavItemDropdown
</script>
