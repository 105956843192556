export const componentSizes = ['sm', 'md', 'lg']
export const modalSizes = [...componentSizes, 'xl']

export const navbarAlign = ['start', 'left', 'center', 'right', 'end']
export const navbarBreakpoints = ['sm', 'md', 'lg', 'xl']
export const navbarFixedPos = ['top', 'bottom']
export const navbarTypes = ['light', 'dark']

export const layoutAlignX = [
  'start',
  'center',
  'end',
  'around',
  'between',
]
export const layoutAlignY = [
  'start',
  'center',
  'end',
  'baseline',
  'stretch',
]

export const popperPlacements = [
  'top',
  'bottom',
  'right',
  'left',
  'topleft',
  'topright',
  'bottomleft',
  'bottomright',
  'lefttop',
  'leftbottom',
  'righttop',
  'rightbottom',
]

export const bootstrapIconMap = {
  bars: 'list',
  bolt: 'lightning-fill',
  'caret-right': 'caret-right-fill',
  'check-circle': 'check-circle-fill',
  cog: 'gear',
  columns: 'layout-three-columns',
  eraser: 'eraser-fill',
  eye: 'eye-fill',
  'eye-slash': 'eye-slash-fill',
  'exclamation-circle': 'exclamation-circle-fill',
  filter: 'filter-circle-fill',
  home: 'house',
  'info-circle': 'info-circle-fill',
  link: 'link45deg',
  'list-alt': 'card-list',
  pause: 'pause-fill',
  'pause-circle': 'pause-circle',
  play: 'play-fill',
  'play-outline': 'play',
  'sign-out': 'box-arrow-right',
  'stop-circle': 'stop-circle',
  sync: 'arrow-repeat',
  times: 'x',
  'times-circle': 'x-circle-fill',
  'toggle-on': 'toggle-on',
  'toggle-off': 'toggle-off',
  trophy: 'trophy-fill',
  undo: 'arrow-counterclockwise',
  'user-circle': 'person-circle',
  'user-plus': 'person-plus',
  'user-times': 'person-x',
  users: 'people',
  views: 'view-list',
}
