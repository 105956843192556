import { mountVuePage } from '../mountVuePage'
import { OptimCombinedStore } from './OptimCombinedStore'
import { routes } from './routes'

mountVuePage({
  faviconName: 'optim',
  store: OptimCombinedStore,
  rootComponent: () => import('./OptimUi.vue'),
  routes,
})
