<template lang="pug">
span
  Link(
    :disabled='gettingDownloadId'
    @click='onDownloadRequest'
  )
    span.mx-1(v-if='gettingDownloadId')
      Spinner(small).mr-1
      | Downloading...
    span(
      v-else-if='label'
    ) {{ label }}
    slot
  DownloadUrl(
    hidden
    :downloadId='downloadId'
    ref='download-ref'
  )

</template>

<script>
import DownloadUrl from './DownloadUrl.vue'
import { Link } from './Link.vue'
import { Spinner } from './Spinner.vue'

export const DownloadTwoStep = {
  components: {
    DownloadUrl,
    Link,
    Spinner,
  },
  computed: {
    getterIsDefined() {
      return typeof this.getDownloadId === 'function'
    },
  },
  data() {
    return {
      gettingDownloadId: false,
      downloadId: null,
    }
  },
  methods: {
    async onDownloadRequest() {
      if (this.gettingDownloadId) return
      this.gettingDownloadId = true
      this.$notify({
        message: 'Downloading...',
        type: 'primary',
      })
      if (this.getterIsDefined) {
        this.downloadId = await this.getDownloadId()
        setTimeout(() => {
          const link = this.$refs['download-ref']
          link.$el.click()
        }, 100)
      }
      this.gettingDownloadId = false
    },
  },
  props: {
    getDownloadId: Function,
    label: String,
  },
}

export default DownloadTwoStep
</script>

<style></style>
