import { listCampaigns } from './actions'

const getNewState = () => ({
  campaigns: [],
  listing: false,
})

export const BroadMatchStore = {
  state: getNewState(),
  getters: {},
  mutations: {},
  actions: {
    async listCampaigns({ state }) {
      state.listing = true
      const {
        data: { matches },
      } = await listCampaigns()
      state.campaigns = matches
      state.listing = false
      return matches
    },
  },
}
