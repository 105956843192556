/**
 * Returns copy of the array with changed order
 * @param {Array} array - the array to move element within
 * @param {*} from - index to move from
 * @param {*} to - index to move to
 * @returns {Array}
 */

export function moveArrayElement(array, from, to) {
  const copy = array.slice(0)

  copy.splice(to, 0, copy.splice(from, 1)[0])
  return copy
}
