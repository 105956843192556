const defaultCTROptions = {
  initial: 0.05,
  label: 'CTR',
  min: 0.01,
  max: 0.6,
  step: 0.01,
  format: (value) => `${value}%`,
}
const defaultVCROptions = {
  initial: 60,
  label: 'VCR',
  min: 1,
  max: 100,
  step: 1,
  format: (value) => `${value}%`,
}
const defaultImpressionsOptions = {
  initial: 3000,
  label: 'Impressions',
  min: 1000,
  max: 10000,
  step: 500,
}
const defaultSpendOptions = {
  initial: 90,
  label: 'Spend',
  min: 1,
  max: 100,
  step: 1,
  format: (value) => `${value}%`,
}
const factoryConstants = {
  DEFAULT_PROFILE_KEY: 'default_profile',
  controlConfiguration: {
    blocklisting: {
      text:
        'A domain performance report and campaign details are ' +
        'automatically pulled several times a day. ' +
        'If the report is valid (sanity checks, has domains in it) ' +
        'and spend threshold has been reached the day before ' +
        'by campaign, Kawa will go through each domain in the ' +
        'report and blocklist it if this domain has more ' +
        'than impression threshold, AND less than CTR or VCR threshold AND ' +
        'less than current total CTR or VCR for the report.',
      ctr: {
        ...defaultCTROptions,
        text: 'CTR below which domain will be blocklisted.',
      },
      vcr: {
        ...defaultVCROptions,
        text: 'VCR below which domain will be blocklisted.',
      },
      impressions: {
        ...defaultImpressionsOptions,
        text: 'Impressions above which domain will be blocklisted.',
      },
      spend: {
        ...defaultSpendOptions,
        text:
          'Campaign spend (of daily limit), above which ' +
          'domains will be blocklisted.',
      },
    },
    ad_pacing: {
      text:
        'Ad performance report and campaign details are ' +
        'automatically pulled for active campaign every ~12 hrs. ' +
        'If the report is valid (sanity checks, has ads in it) and ' +
        'if spend threshold has been reached the day before, ' +
        'activity will go through each ad in the report and ' +
        'decrease / increase pacing depending on ad impressions, ' +
        'CTR or VCR (and average reported CTR or VCR), and ' +
        'minimum value thresholds.',
      ctr: {
        ...defaultCTROptions,
        text: 'CTR below which ad will be paced.',
      },
      vcr: {
        ...defaultVCROptions,
        text: 'VCR below which ad will be paced.',
      },
      impressions: {
        ...defaultImpressionsOptions,
        text: 'Impressions above which ad will be paced.',
      },
      spend: {
        ...defaultSpendOptions,
        text:
          'Campaign spend (of daily limit), above which ' +
          'ads will be paced.',
      },
      min_value: {
        initial: 1,
        label: 'Min value',
        min: 0.1,
        max: 99.9,
        step: 0.1,
        format: (value) => `${value}%`,
        text: 'Minimum value for individual ad pacing.',
      },
    },
    bid_alignment: {
      text:
        'A keyword performance report and campaign details are ' +
        'automatically pulled for an active campaign several times ' +
        'per day. If the report is valid ' +
        '(sanity checks, has keywords in it, campaign goal is CTR or VCR) ' +
        'and if spend threshold has been reached the day before ' +
        'by campaign, Kawa will go through each keyword ' +
        'in the report and adjust ' +
        'bid if this keyword has more than impression threshold, ' +
        'according to below formula, AND only if the new bid ' +
        'difference from the previous is more than $0.1, ' +
        'to avoid frequent small updates.',
      impressions: {
        ...defaultImpressionsOptions,
        text: 'Impressions above which keyword bid will be adjusted.',
      },
      spend: {
        ...defaultSpendOptions,
        text:
          'Campaign spend (of daily limit), above which ' +
          'keyword bids will be adjusted.',
      },
      max_multiplier: {
        initial: 2,
        label: 'Max multiplier',
        min: 1.1,
        max: 3,
        step: 0.1,
        format: (value) => `${value}x`,
        text:
          'Maximum bid multiplier applied ' +
          'over default campaign max bid',
      },
    },
  },
}

factoryConstants.activities = Object.keys(
  factoryConstants.controlConfiguration,
)

module.exports = factoryConstants
