<template lang="pug">
BFormGroup(
  :description="description"
  :disabled="disabled"
  :invalid-feedback="invalidFeedback"
  :label="label"
  :label-cols="labelCols"
  :state="state"
)
  slot
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
export const FormGroup = {
  components: {
    BFormGroup,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalidFeedback: {
      type: String,
      default: '',
    },
    label: String,
    labelCols: {
      type: String | Number,
    },
    state: {
      type: Boolean,
      default: true,
    },
  },
}
export default FormGroup
</script>
