export default (_v, { item: data, plainText }) => {
  if (plainText) {
    return '-'
  }
  return (
    `<button class='button-unstyled' ` +
      `title='Expand more details and actions.' ` +
      `type='button'>&equiv;` +
    `</button>` +
    `<b class='ml-3'>` +
      `<a href='/details/${data.campaign_id}' ` +
        `target='_blank' ` +
        `title='Show campaign details in a new tab.'>&#9655;` +
      `</a>` +
    `</b>`
  )
}
