<template lang="pug">
div
  KawaNavigation(
    :routes='factoryRoutes'
    variant='light'
    type='light'
  )
    NavbarNav
      NavItem(
        disabled
        :active='factoryStateActive'
      ) Factory ({{ factoryStateLabel }})
    NavbarNav.ml-auto
      NavItemDropdown(
        lazy
        right
      )
        template(#button-content)
          span.text-primary Actions
        DropdownItem.text-success(
          @click='onFactoryStateToggle(true)'
          :disabled='isOpen'
        ) Open
        DropdownItem.text-danger(
          :disabled='!isOpen'
          @click='onFactoryStateToggle(false)'
        ) Close
  br
  VueNotifications
  Container(
    fluid
    v-if='!loading'
  )
    router-view
  Loader(v-if='loading')
</template>

<script>
import { campaignIdIsValid } from '../campaigns/campaignIdIsValid'
import { mapGetters, mapActions, mapState } from 'vuex'
import {
  Container,
  DropdownItem,
  Loader,
  NavItem,
  NavItemDropdown,
  NavbarNav,
} from '@/components'
import KawaNavigation from '@/components/KawaNavigation.vue'
import { VueNotifications } from '../vueNotifications/VueNotifications.vue'
import { routes as factoryRoutes } from './routes'

export default {
  components: {
    Container,
    DropdownItem,
    CampaignIdInput: () =>
      import('../campaigns/components/CampaignIdInput.vue'),
    KawaNavigation,
    Loader,
    NavItem,
    NavItemDropdown,
    NavbarNav,
    VueNotifications,
  },
  data() {
    return {
      factoryRoutes,
      loading: false,
    }
  },
  watch: {
    routedCampaignId(campaignId) {
      if (campaignIdIsValid(campaignId)) {
        this.select({ campaignId })
        return
      }
      if (this.$route.path !== '/') {
        this.$router.push({ path: '/' })
        return
      }
    },
    campaignId(value) {
      if (this.routedCampaignId === value) return
      this.$router.push({
        path: `/campaign/${value}`,
      })
    },
  },
  computed: {
    ...mapGetters('campaign', [
      'campaignId',
      'campaignProperties',
      'propertiesLoaded',
    ]),
    ...mapState('factoryState', ['isOpen']),
    factoryStateActive() {
      return this.isOpen
    },
    factoryStateLabel() {
      return this.isOpen ? 'Open' : 'Closed'
    },
    routedCampaignId() {
      return +this.$route.params.campaignId
    },
    routedCampaignIdIsValid() {
      return campaignIdIsValid(this.routedCampaignId)
    },
  },
  methods: {
    ...mapActions('view', {
      loadCurrentView: 'get',
    }),
    ...mapActions('currentUser', {
      loadCurrentUser: 'get',
    }),
    ...mapActions('factoryWorkers', ['getProfiles']),
    ...mapActions('factoryState', {
      loadFactoryState: 'get',
      setFactoryOpen: 'setTo',
    }),
    ...mapActions('campaign', ['clear', 'select']),
    clearCampaignSelection() {
      this.$router.push({ path: '/' })
      this.clear()
    },
    async onFactoryStateToggle(value) {
      await this.setFactoryOpen(value)
    },
    async preload() {
      this.loading = true
      await this.loadCurrentUser()
      await this.getProfiles()
      await this.loadCurrentView()
      await this.loadFactoryState()
      this.loading = false
    },
  },
  created() {
    this.preload()
  },
  mounted() {
    if (this.routedCampaignIdIsValid) {
      this.select({ campaignId: this.routedCampaignId })
    }
  },
}
</script>

<style></style>
