<template lang="pug">
b-input-group-append(
  :id='id'
  :is-text='isText'
)
  slot
</template>
<script>
export const InputGroupAppend = {
  props: {
    id: {
      type: String,
      default: null,
    },
    isText: {
      type: Boolean,
      default: false,
    },
  },
}
export default InputGroupAppend
</script>
