import {
  addComment,
  getComments,
  removeComment,
} from './actions'
import log from '../utilities/log'

const logger = log('CommentsStore')
const COMMENT_CHAR_LIMIT = 600

export const CommentsStore = {
  state: {
    campaignId: null,
    comments: [],
    commentText: '',
    loading: false,
  },
  getters: {
    newCommentTextValid: ({ commentText }) =>
      commentText.length > 1 &&
      commentText.length < COMMENT_CHAR_LIMIT,
  },
  mutations: {
    setCampaignId(state, id) {
      state.campaignId = id
      state.comments = []
    },
    setCommentText(state, payload) {
      state.commentText = payload
    },
    setComments(state, payload) {
      state.comments = payload
    },
    setLoading(state, value) {
      state.loading = !!value
    },
  },
  actions: {
    async addComment({ commit, dispatch, state }) {
      commit('setLoading', true)
      const { data } = await addComment({
        campaignId: state.campaignId,
        comment: state.commentText,
      })
      logger.info('addComment', data)
      commit('setLoading', false)
      dispatch('getComments')
      return true
    },
    async getComments({ commit, state }) {
      commit('setLoading', true)
      const { data: { comments } } = await getComments(state.campaignId)
      commit('setComments', comments)
      commit('setLoading', false)
      return comments
    },
    async removeComment({ commit, dispatch, state }, { commentLine }) {
      commit('setLoading', true)
      await removeComment({
        campaignId: state.campaignId,
        commentLine,
      })
      await dispatch('getComments')
      commit('setLoading', false)
      return true
    },
  },
}
