import formatTimeLocal from '../../../format/timeLocal'

/**
 * Returns a formatted cell value for row attention triggers
 */

export default ({
  ignoredBy,
  ignoredByAll,
  ignoredByMe,
  ignoreByMe,
  hasReminder,
  reminder,
}, plainText) => {
  const res = (
    ignoredByAll
      ? {
          label: 'Ignored',
          class: 'all-ignored'
        }
      : (
          ignoredByMe
            ? {
                label: 'Unignore',
                class: 'my-ignored'
              }
            : {
                label: `Ignore`,
                class: 'available'
              }
        )
  )
  const isIgnored = (ignoredBy && ignoredBy.length)
  const title = (
    isIgnored
      ? (
          ignoredByAll
            ? 'Ignored by all (see campaign notes for more information)'
            : (ignoredByMe
                ? `Ignored by me until ${formatTimeLocal(ignoreByMe.until)}`
                : `Ignored by: ${ignoredBy.join(', ')}`
              )
        )
      : `Not ignored by anyone`
  ) + (
    hasReminder
      ? `, with a reminder (Due @ ${reminder.remindAtUi}).`
      : ''
  )
  const badge = (
    ignoredByAll
      ? ` (all)`
      : (
          isIgnored ? ` (${ignoredBy.length})` : ''
        )
  ) + (hasReminder ? ' ®' : '')
  if (plainText) {
    return `${res.label}${badge}`
  }
  return (
    `<span ` +
      `class='grid-attention-label grid-attention-state-${res.class}' ` +
      `title="${title}"` +
    `>${res.label}${badge}` +
    `</span>`
  )
}
