<template lang="pug">
div
  KawaFooter
  KawaNavigation(
    variant='light'
    type='light'
    :routes='matcherRoutes'
  )
    NavbarNav.ml-auto(
      v-if='campaignId'
    )
      NavText
        Button(
          title='Click to enter a different campaign'
          @click='clearCampaignSelection'
        )
          Icon(name='x-circle')
          span.ml-1 Campaign #[span.text-primary {{ campaignId }}]
            span(v-if='propertiesLoaded')  {{ campaignProperties.name_short }}
    NavbarNav.ml-auto(v-else)
      NavItemDropdown(
        lazy
        right
      )
        template(#button-content)
          span.text-primary Select campaign
        DropdownForm(
          style='min-width: 300px'
        )
          CampaignIdInput(input-size='sm')
        RecentList(
          component='broadMatch'
          title='Recently matched'
        )
  br
  VueNotifications
  Container.mt-5(
    fluid
  )
    router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { campaignIdIsValid } from '../campaigns/campaignIdIsValid'
import { Button } from '@/components/Button'
import { Container } from '@/components/Layout'
import { DropdownForm } from '@/components/Dropdown'
import { Icon } from '@/components/Icon.vue'
import { NavItemDropdown, NavText } from '@/components/Nav'
import { NavbarNav } from '@/components/Navbar'
import { KawaNavigation } from '@/components/KawaNavigation.vue'
import { KawaFooter } from '@/components/KawaFooter.vue'
import { routes } from './routes'
import { createRouter } from '@/routes/createRouter'

export default {
  components: {
    Button,
    Container,
    DropdownForm,
    Icon,
    CampaignIdInput: () =>
      import('../campaigns/components/CampaignIdInput.vue'),
    KawaFooter,
    KawaNavigation,
    NavItemDropdown,
    NavText,
    NavbarNav,
    RecentList: () => import('@/components/RecentList.vue'),
  },
  data() {
    return {
      matcherRoutes: routes,
    }
  },
  watch: {
    routedCampaignId(campaignId) {
      const currentPath = this.$route.path
      if (campaignIdIsValid(campaignId)) {
        return this.select({ campaignId })
      }
      if (currentPath !== '/') {
        return this.$router.push({ path: '/' })
      }
    },
    campaignId(value) {
      const campaignPath = `/${value}`
      if (this.$route.path.includes(campaignPath)) {
        return
      }
      this.$router.push({
        path: campaignPath,
      })
    },
  },
  computed: {
    ...mapGetters('campaign', [
      'campaignId',
      'campaignProperties',
      'propertiesLoaded',
    ]),
    routedCampaignId() {
      return parseInt(this.$route.params.id)
    },
    routedCampaignIdIsValid() {
      return campaignIdIsValid(this.routedCampaignId)
    },
  },
  methods: {
    ...mapActions('view', {
      loadCurrentView: 'get',
    }),
    ...mapActions('currentUser', {
      loadCurrentUser: 'get',
    }),
    ...mapActions('campaign', ['clear', 'select']),
    clearCampaignSelection() {
      this.$router.push({ path: '/' })
      this.clear()
    },
    async preload() {
      await this.loadCurrentUser()
      await this.loadCurrentView()
    },
  },
  created() {
    this.preload()
  },
  mounted() {
    if (this.routedCampaignIdIsValid) {
      this.select({ campaignId: this.routedCampaignId })
    }
  },
}
</script>
