import { server } from '../../server'

export const getData = ({
  campaignId,
  dimensions,
  range,
  start,
}) => server.post(
  `/campaign/${campaignId}/dimensions/`, {
    dimensions,
    range,
    start,
  }
)
