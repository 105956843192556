<template lang="pug">
b-skeleton-img(
  :animation='animation'
  :aspect='aspect'
  :card-img='cardImg'
  :height='height'
  :no-aspect='noAspect'
  :variant='variant'
  :width='width'
)
</template>

<script>
export const SkeletonImg = {
  props: {
    animation: {
      type: String,
      default: null,
    },
    aspect: {
      type: String,
      default: '16:9',
    },
    cardImg: {
      type: String,
      default: null,
      validator: value => ['top', 'bottom', 'left', 'right'].includes(value),
    },
    height: {
      type: String,
      default: null,
    },
    noAspect: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
  },
}
export default SkeletonImg
</script>
