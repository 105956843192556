<template lang="pug">
b-jumbotron(
  :header='header'
  :lead='lead'
)
  slot
</template>
<script>
export const Jumbotron = {
  props: {
    header: {
      type: String,
      default: '',
    },
    lead: {
      type: String,
      default: '',
    },
  },
}
export default Jumbotron
</script>
