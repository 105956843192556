<template lang="pug">
BLink(
  :disabled='disabled'
  :href='href'
  :target='target'
  :title='title'
  :to='to'
  v-on='$listeners'
)
  slot
</template>

<script>
export const Link = {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    }
  },
}
export default Link
</script>
