import { render, staticRenderFns } from "./KawaNavigation.vue?vue&type=template&id=69842fa6&lang=pug"
import script from "./KawaNavigation.vue?vue&type=script&lang=js"
export * from "./KawaNavigation.vue?vue&type=script&lang=js"
import style0 from "./KawaNavigation.vue?vue&type=style&index=0&id=69842fa6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports