<template lang="pug">
div
  mixin pagination()
    .text-center.mt-1
      ButtonGroup(v-if='isSearchable && items.length > 1')
        FormInput(
          placeholder='Quick search'
          type='text'
          v-model='searchInput'
        )
        Button(
          v-if='searchInput.length'
          @click='searchInput = ""'
          title='Erase current search'
          variant='primary'
        )
          Icon(name='eraser')
      ButtonGroup(v-if='isPaginated')
        b-pagination(
          v-if='rowItems.length > itemsPerPage'
          v-model='page'
          :per-page='itemsPerPage'
          :total-rows='rowItems.length'
          style='margin-bottom:0;'
          @page-click='onPaginationInput'
        )
        Dropdown.ml-1(
          v-if='itemsPerPageOptions.length'
          :disabled='itemsPerPageOptions.length === 1'
        )
          template(#button-content)
            span(v-if='rowItems.length < itemsPerPage') All rows
            span(v-else) {{ itemsPerPage }} per page
          DropdownItemButton(
            v-for='option of itemsPerPageOptions'
            :key='`itemsPerPage-${option}`'
            :active='option === itemsPerPage'
            :disabled='option === itemsPerPage'
            @click='onSelectItemsPerPage(option)'
          )
            Icon.mr-1(
              v-if='option === itemsPerPage'
              name='check'
            )
            span(v-if='option > rowItems.length') All rows
            span(v-else) {{ option }} rows
      ButtonGroup.ml-1(
        v-if='!hideTotal'
      )
        Caption {{ items.length }} total
          i.ml-1(v-if='filtersActive || searchActive') ({{ foundItems.length }} filtered)
      ButtonGroup.ml-1(
        v-if='filtersActive'
      )
        Caption.mr-np Filters active ({{ activeFilterCount }})
        Button.text-primary(
          @click='onClearFilters'
        )
          Icon(name='x' label='Clear')
      Caption.ml-1.mr-np(
        v-if='selectable && selected.length'
      )
        Link(@click='onUnselectAll')
          Icon(name='x' :label='`Selected (${selected.length})`')
      DownloadCsv.mx-1(
        v-if='downloadable && rowItems.length'
        :fields='downloadableCsvFields'
        :items='rowItems'
      )
      slot(name='controls')
    .spacer
  +pagination
  .kawa-table-container(
    :style='containerStyle'
  )
    BTable.kawa-b-table(
      :borderless='borderless'
      :ref='tableName'
      :current-page='isPaginated ? page : undefined'
      :per-page='isPaginated ? itemsPerPage : undefined'
      :fields='fieldOptions'
      :fixed='fixed'
      :foot-clone='footClone'
      :items='rowItems'
      no-select-on-click
      :selectable='selectable'
      @row-selected='onRowSelected'
      @row-clicked='(data) => $emit("row-clicked", data)'
      @head-clicked='(data) => $emit("head-clicked", data)'
      :small='small'
      :table-class='tableClasses'
      :tbody-tr-class='rowClasses'
      :tfoot-tr-class='footerClasses'
      :striped='striped'
      :stacked='stacked'
    )
      template(
        v-if='isFilterable'
        #thead-top
      )
        b-th.filter-header(
          :class='fieldFilterHeaderClass(field)'
          v-for='field of fieldOptions'
          :key='`filterable-header-${field.key}`'
        )
          span(
            v-if='field.filterable'
          )
            Dropdown(
              size='sm'
              :disabled='field.valueOptions.length == 1'
              :variant='isFiltered(field.key) ? "primary" : "secondary"'
              v-if='field.valueOptions'
              style='width: 100%'
            )
              template(#button-content)
                Icon(
                  v-if='isFiltered(field.key)'
                  name='filter-circle-fill'
                )
                Icon(
                  v-else
                  name='filter-circle'
                )
              .scrollable
                DropdownText.text-muted #[i {{ field.label }}] values
                DropdownItem(
                  v-for='option in field.valueOptions'
                  :key='`filterable-${field.key}-${option}`'
                  @click='onFilterBy({ key: field.key, option })'
                )
                  Icon.text-primary(
                    v-if='isFilteredFor({ key: field.key, option })'
                    name='check2'
                  )
                  span.ml-1(v-if='typeof field.formatter === "function"') {{ field.formatter(option) }}
                  span.ml-1(v-else) {{ option }}
                DropdownDivider(v-if='isFiltered(field.key)')
                DropdownItem(
                  v-if='isFiltered(field.key)'
                  @click='onClearFilter(field.key)'
                )
                  Icon.text-primary.mr-1(name='x' label='Clear')
                  i.text-primary Clear filter
      slot(v-for="(_, name) in $slots" :name="name" :slot="name")
      template(
        v-if='loadable'
        #head(loading)='data'
      )
        span
      template(
        v-if='selectable'
        #head(selected)='data'
      )
        Dropdown(
          size='sm'
          v-if='isAllSelectable && selected.length'
        )
          DropdownItemButton(
            :disabled='!isAllSelectable'
            size='sm'
            title='Select all rows'
            @click='onSelectAll'
          ).text-primary
            Icon(name='check2')
            span.ml-1 Select all rows
          DropdownItemButton(
            v-if='selected.length'
            title='Unselect all'
            @click='onUnselectAll'
          ).text-primary
            Icon(name='x' label='Unselect rows')
        template(v-else)
          Button(
            v-if='selected.length'
            size='sm'
            title='Unselect all'
            @click='onUnselectAll'
          ).text-primary
            Icon(name='x')
          Button(
            v-if='isAllSelectable'
            size='sm'
            title='Select all rows'
            @click='onSelectAll'
          ).text-primary
            Icon(name='check2-all')

      template(
        v-if='loadable'
        #cell(loading)='data'
      )
        span.bg-primary(
          v-if='data.value'
          style='height: 100%; width: 100%;'
        )
      template(
        v-if='selectable'
        #cell(selected)='data'
      )
        .select-cell(
          @click='onRowSelectionToggle(data)'
          size='sm'
        )
          b.text-primary(v-if='data.rowSelected')
            Icon(name='check2')
          span.text-secondary(v-else)
            Icon(name='check2')

      template(
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="data"
      )
        slot(:name="name" v-bind="data")
  +pagination
</template>

<script>
// import wrapper ui/components used in template
import { BTable } from 'bootstrap-vue'
import { Button, ButtonGroup } from './Button'
import { Caption } from './Caption.vue'
import { DownloadCsv } from './DownloadCsv.vue'
import { FormInput } from './FormInput.vue'
import { Icon } from './Icon.vue'
import { Link } from './Link.vue'
import {
  Dropdown,
  DropdownDivider,
  DropdownItem,
  DropdownItemButton,
  DropdownText,
} from './Dropdown'
import {
  capitaliseFirstLetter,
  standardiseString,
} from '../../format/string'

const pageOptions = [5, 10, 25, 50, 100, 250, 500, 1000]

export const Table = {
  components: {
    BTable,
    Button,
    ButtonGroup,
    Caption,
    DownloadCsv,
    Dropdown,
    DropdownDivider,
    DropdownItem,
    DropdownItemButton,
    DropdownText,
    FormInput,
    Icon,
    Link,
  },
  computed: {
    activeFilterCount() {
      return Object.keys(this.filters).filter(
        (key) => this.filterOptions(key).length > 0,
      ).length
    },
    containerStyle() {
      let style = ''
      if (this.hasMaxHeight) {
        style += `max-height:${this.maxHeight}px;overflow: auto;`
      }
      return style
    },
    downloadableCsvFields() {
      if (!Array.isArray(this.downloadable)) {
        return this.fieldsWithLabel
      }
      return this.downloadable.map((key) =>
        this.fieldsWithLabel.find(field => field.key === key),
      )
    },
    fieldsWithLabel() {
      return this.fields.map(field => ({
        label: field.label ?? capitaliseFirstLetter(field.key),
        ...field
      }))
    },
    fieldOptions() {
      const fields = this.fieldsWithLabel?.length
        ? this.fieldsWithLabel
        : this.items?.length
        ? Object.keys(this.items[0]).map((key) => ({
            key,
            label: standardiseString(key),
          }))
        : []
      return [
        { key: 'selected', omit: !this.selectable },
        { key: 'loading', omit: !this.loadable },
        ...fields,
      ]
        .filter((f) => !f.omit)
        .map((field, idx) => {
          field.class =
            field.class ||
            (this.selectable && idx === 0
              ? 'table-selectable-cell'
              : 'table-cell')
          return this.isFilterable && field.filterable
            ? {
                ...field,
                filteredFor: (option) =>
                  this.filters[field.key] &&
                  this.filters[field.key].includes(option),
                valueOptions: this.fieldValueOptions(field.key).sort(
                  (a, b) => `${a}`.localeCompare(`${b}`),
                ),
              }
            : field
        })
    },
    filteredItems() {
      const filterKeys = Object.keys(this.filters)
      return this.filtersActive
        ? this.items.filter((item) =>
            filterKeys.every(
              (key) =>
                this.filterOptions(key).length === 0 ||
                this.filterOptions(key).includes(item[key]),
            ),
          )
        : this.items
    },
    filtersActive() {
      return Object.keys(this.filters).some(
        (key) => this.filterOptions(key).length > 0,
      )
    },
    foundItems() {
      return this.searchInputValid
        ? this.filteredItems.filter(({ searchString: str }) =>
            this.searchInputArrayed.every((term) =>
              new RegExp(term, 'gi').test(str),
            ),
          )
        : this.filteredItems
    },
    hasMaxHeight() {
      return this.maxHeight > 0
    },
    isAllSelectable() {
      return this.rowItems.length > this.selected.length
    },
    isFilterable() {
      return this.filterable === true && this.plainView === false
    },
    isPaginated() {
      return this.paginated === true && this.plainView === false
    },
    isSearchable() {
      return this.searchable === true && this.plainView === false
    },
    itemsPerPageOptions() {
      const pageItems = pageOptions.filter(
        (n) => this.rowItems.length > n,
      )

      return pageItems.length === pageOptions.length
        ? pageItems
        : pageOptions.slice(0, pageItems.length + 1)
    },
    rowItems() {
      return this.foundItems
    },
    searchActive() {
      return (
        this.searchInputValid &&
        this.filteredItems.length >= this.foundItems.length
      )
    },
    searchInputArrayed() {
      return this.searchInputDebounced
        .split(' ')
        .map((s) => s.trim())
        .filter((s) => s?.length > 0)
    },
    searchInputValid() {
      return (
        this.isSearchable &&
        this.searchInputDebounced.length > 1 &&
        this.filteredItems.length &&
        this.filteredItems[0].searchString
      )
    },
    tableName() {
      return (
        this.name || this.fieldOptions.map((f) => f.key).join('-')
      )
    },
    tableRef() {
      return this.$refs[this.tableName]
    },
  },
  created() {
    if (this.perPage) {
      this.itemsPerPage = this.perPage
    }
  },
  data() {
    return {
      countDebouncer: null,
      filters: {},
      itemsPerPage: 100,
      lastEvent: null,
      page: 1,
      searchInput: '',
      searchInputDebounce: null,
      searchInputDebounced: '',
      selected: [],
    }
  },
  methods: {
    fieldFilterHeaderClass(field) {
      return field.filterable
        ? `filterable-header${
            this.isFiltered(field.key) ? ' filterable-filtered' : ''
          }`
        : 'non-filterable-header'
    },
    fieldValueOptions(key) {
      return Array.from(new Set(this.items.map((i) => i[key])))
    },
    filterOptions(key) {
      return this.filters[key] || []
    },
    isFiltered(key) {
      return this.filterOptions(key).length > 0
    },
    isFilteredFor({ key, option }) {
      return this.filterOptions(key).includes(option)
    },
    onClearFilter(key) {
      this.filters[key] = []
    },
    onClearFilters() {
      this.filters = {}
      this.$emit('filter', {})
    },
    onFilterBy({ key, option }) {
      let options = this.filters[key] || []

      if (options.includes(option)) {
        options.splice(options.indexOf(option), 1)
      } else {
        options = [...options, option]
      }
      // Let's clean the filter if it's for all options on field
      if (options.length === this.fieldValueOptions(key).length) {
        options = undefined
      }
      this.filters = {
        ...this.filters,
        [key]: options,
      }
      this.$emit('filter', { key, options, filters: this.filters })
    },
    onCountChange() {
      clearTimeout(this.countDebouncer)
      this.countDebouncer = setTimeout(() => {
        if (!this.itemsPerPageOptions.includes(this.itemsPerPage)) {
          this.onSelectItemsPerPage(
            pageOptions.find((n) => n > this.rowItems.length) ||
              pageOptions[pageOptions.length - 1],
          )
        }
      }, 500)
    },
    onPaginationInput() {
      this.$nextTick(() => {
        this.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    },
    onRowSelected(items) {
      const previous = [...this.selected]
      this.selected = [...items]
      this.$emit('selected', {
        items,
        previous,
        lastEvent: this.lastEvent,
      })
    },
    onRowSelectionToggle(data) {
      if (!data.rowSelected) {
        this.lastEvent = 'rowSelected'
        data.selectRow()
      } else {
        this.lastEvent = 'rowUnselected'
        data.unselectRow()
      }
    },
    onSelectAll() {
      this.lastEvent = 'rowsSelected'
      this.tableRef.selectAllRows()
    },
    onSelectByIds({ idKey = 'id', ids }) {
      for (const id of ids) {
        const itemIndex = this.tableRef.computedItems.findIndex(
          (i) => i[idKey] === id,
        )
        if (itemIndex >= 0) {
          this.tableRef.selectRow(itemIndex)
        }
      }
    },
    onSelectItemsPerPage(value) {
      this.itemsPerPage = value
      if (this.page > this.items.length / this.itemsPerPage) {
        this.page = Math.ceil(this.items.length / this.itemsPerPage)
      }
    },
    onUnselectAll() {
      this.lastEvent = 'rowsUnselected'
      this.tableRef.clearSelected()
    },
  },
  mounted() {
    this.onCountChange()
  },
  props: {
    autoSelectAll: Boolean,
    borderless: Boolean,
    downloadable: [Array, Boolean],
    fields: Array,
    filterable: Boolean,
    fixed: Boolean,
    footClone: Boolean,
    footerClasses: [Array, Object, String],
    hideTotal: Boolean,
    items: Array,
    loadable: Boolean,
    maxHeight: Number,
    name: String,
    paginated: Boolean,
    perPage: Number,
    plainView: Boolean,
    rowClasses: Function,
    searchable: Boolean,
    selectable: Boolean,
    small: Boolean,
    stacked: Boolean,
    striped: Boolean,
    tableClasses: [Array, Object, String],
  },
  watch: {
    autoSelectAll(newVal) {
      if (newVal === true) {
        this.$nextTick(() => this.onSelectAll())
      } else {
        this.onUnselectAll()
      }
    },
    plainView(newVal) {
      if (newVal === true) {
        this.onClearFilters()
      }
    },
    rowItems(newItems, prevItems) {
      const newCount = newItems.length
      const prevCount = prevItems.length
      if (newCount !== prevCount) {
        this.onCountChange()
      }
    },
    searchInput(newVal) {
      clearTimeout(this.searchInputDebounce)
      this.searchInputDebounce = setTimeout(() => {
        this.searchInputDebounced = this.searchInput
      }, 300)
    },
  },
}

export default Table
</script>

<style>
.filterable-header {
  text-align: center;
  padding: 0px;
}

.kawa-b-table .filter-header {
  padding: 0px;
}
.filter-header .btn {
  border-radius: 0px;
}

.kawa-b-table tbody .table-active td.table-cell {
  background-color: white !important;
}

.select-cell {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>
