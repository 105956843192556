import log from '@/utilities/log'
import { nonAuthorizationPaths, unauthorizedPath } from './constants'

const logger = log('AuthPlugin')
const isAuthorizedToAccessInstance = ({
  instance,
  path,
}) => instance.$store?.getters[
  'currentUser/isAuthorizedFor'
]({
  method: 'client',
  path: `${document.location.pathname}#${path}`,
})

export const AuthPlugin = {
  install(Vue) {
    Vue.prototype.$initializeAuth = async function() {
      // Load the current user if not already loaded
      logger.info('AuthPlugin:Initializing', typeof this.$router)
      const loaded = await this.$store?.dispatch('currentUser/getIfNotLoaded')
      if (!this.$router || !loaded) return false
      // Make sure that the route we are currently on is authorized
      const currentAuthorized = isAuthorizedToAccessInstance({
        instance: this,
        path: this.$router.currentRoute.path,
      })
      logger.info('AuthPlugin:currentAuthorized', {
        currentAuthorized,
        path: this.$router.currentRoute.path,
      })
      if (!currentAuthorized) {
        this.$router.push(unauthorizedPath)
      }
      // make sure to authorize all route transitions
      this.$router.beforeEach((to, from, next) => {
        const authorized = isAuthorizedToAccessInstance({
          instance: this,
          path: to.path,
        })
        logger.info(`AuthPlugin:Navigating to ${to.path} from ${from.path}`, authorized)
        return authorized
          ? next()
          : nonAuthorizationPaths.includes(to.path)
            ? next()
            : next(unauthorizedPath)
      })
    }
  },
}
