<template lang="pug">
Row(align-h='center')
  Col(
    :cols='cols'
    :sm='sm'
    :md='md'
    :lg='lg'
    :xl='xl'
  )
    slot
</template>

<script>
import { Col } from './Col.vue'
import { Row } from './Row.vue'

export const BasicLayout = {
  components: {
    Col,
    Row,
  },
  props: {
    cols: {
      type: [Number, String],
      default: null,
    },
    sm: {
      type: [Boolean, Number, String],
      default: null,
    },
    // `md` is set with a default value to lessen prop usage
    md: {
      type: [Boolean, Number, String],
      default: 10,
    },
    lg: {
      type: [Boolean, Number, String],
      default: null,
    },
    xl: {
      type: [Boolean, Number, String],
      default: null,
    },
  },
}
export default BasicLayout
</script>
