<template lang="pug">
BFormRadioGroup(
  :buttons="buttons"
  :options="options"
  :name="name"
  v-model="valueInput"
  :stacked='stacked'
)
</template>
<script>
export const RadioGroupInput = {
  data() {
    return {
      valueInput: this.value,
    }
  },
  methods: {
    syncValue(v) {
      if (this.valueInput === v) return
      this.valueInput = v
    },
  },
  props: {
    buttons: {
      type: Boolean,
      default: false,
    },
    name: String,
    options: Array,
    stacked: {
      type: Boolean,
      default: false,
    },
    value: String,
  },
  watch: {
    valueInput() {
      this.$emit('input', this.valueInput)
    },
    value: 'syncValue',
  },
}
export default RadioGroupInput
</script>
