<template lang="pug">
div
  DropdownHeader {{ title || "Recent items" }}
  DropdownItem(
    v-if='loading'
    variant='primary'
  )
    Loader
  DropdownItem(
    disabled
    v-if='items.length === 0 && !loading'
    variant='primary'
  ) No recent matches
  RecentListItem(
    v-if='items.length > 0'
    v-for='item in items'
    v-bind='item'
    :component='component'
    :key='`recent-${component}-${item.id}`'
    @select='$emit("select", item.id)'
  )
  DropdownItem
    Button(
      @click='handleClear'
      size='sm'
      v-if='items.length > 0'
      title='Click to clear recent items'
    )
      Icon(name='trash')
      span.ml-1 Clear

</template>
<script>
import { server } from '../server'
import { Button } from './Button'
import { DropdownHeader, DropdownItem } from './Dropdown'
import { Icon } from './Icon.vue'
import { Loader } from './Loader.vue'
import { RecentListItem } from './RecentListItem.vue'

export const RecentList = {
  components: {
    Button,
    DropdownHeader,
    DropdownItem,
    Icon,
    Loader,
    RecentListItem,
  },
  computed: {
    baseMapper() {
      return ({ id, link, text, attributes }) => ({
        attributes,
        id,
        linkHref: link || `/${this.component}/${id}`,
        linkTo: `/${id}`,
        text,
      })
    },
    items() {
      return this.rows.map(this.rowMapper)
    },
    rowMapper() {
      if (typeof this.mapper === 'function')
        return (item) => ({
          ...this.baseMapper(item),
          ...this.mapper(item),
        })
      return this.baseMapper
    },
  },
  created() {
    this.getItems()
  },
  data() {
    return {
      loading: false,
      rows: [],
    }
  },
  props: ['component', 'limit', 'mapper', 'title'],
  methods: {
    async getItems() {
      this.loading = true
      const { data } = await server.get(
        `/recents/${this.component}`,
        {
          params: { limit: this.limit || 10 },
        },
      )
      this.rows = data.recents ?? []
      this.loading = false
    },
    async handleClear() {
      this.rows = []
      await server.delete(`/recents/${this.component}`)
    },
  },
}

export default RecentList
</script>
