import { server } from '../../server'

export const addSectionGroup = ({ color, group, label, view }) => {
  return server.post(`/view/group`, { color, group, label, view })
}

export const removeSectionGroup = async ({ group, view }) =>
  server.post('/view/group/removal', {
    group,
    view,
  })
export const resetSectionGroups = async (view) =>
  server.delete(`/view/${view}/groups`)
export const updateGroupSection = ({
  group,
  remove,
  section,
  view,
}) => {
  return server.put(`/view/group/section`, {
    group,
    view,
    remove,
    section,
  })
}

export const getViewAttributes = (key) => {
  return server.get(`/campaigns/view/${key}`)
}

export const updateView = ({ key, ...payload }) => {
  return server.put(`/campaigns/view/${key}`, payload)
}

export const removeView = (key) => {
  return server.delete(`/campaigns/view/${key}`)
}

export const updateSectionGroupOrder = async ({
  groupOrder,
  view,
}) => server.put(
  `/view/groups`,
  {
    groupOrder,
    view,
  }
)
