export default ({ fullName, userId, isMyAssignment, plainText }) => {
  if (plainText) {
    return fullName
  }
  const title = 'Click to open direct slack message with the assigned person.'

  return fullName
    ? (
        isMyAssignment
          ? (`<b class='row-my-assignment'>Me</b>`)
          : (
        `<a ` +
          `href='/user/${userId}/slack' target='_blank' title="${title}"` +
        `>${fullName}</a>`
            )
      )
    : ''
}
