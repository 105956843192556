import dateLocal from '../../../format/dateLocal'
import {
  standardiseString,
  unavailableLabel,
} from '../../../format/string'

export const lastEditFormatter = (
  { lastEdit, lastEditAt },
  plainText,
) => {
  return lastEdit
    ? plainText
      ? `${dateLocal(lastEditAt)}`
      : `<span title="Category: ` +
        `${standardiseString(lastEdit.category)} / ` +
        `${lastEdit.group}, by ${lastEdit.userEmail}">` +
        `${dateLocal(lastEditAt)}` +
        `</span>`
    : unavailableLabel
}
