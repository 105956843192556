<template lang="pug">
b-nav-form(
  :form-class='formClass'
  :id='id'
)
  slot
</template>

<script>
export const NavForm = {
  props: {
    formClass: {
      type: [Array, Object, String],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
}
export default NavForm
</script>
