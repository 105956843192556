<template lang="pug">
b-navbar-toggle(
  :disabled='disabled'
  :target='target'
)
  slot
</template>
<script>
export const NavbarToggle = {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    target: {
      type: [Array, String],
      required: true,
    },
  },
}
export default NavbarToggle
</script>
