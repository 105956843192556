import { server } from '../../server'

export const startWorkSession = sheetId => server.post(
  `/sheet/${sheetId}/work-session/start`
)
export const stopWorkSession = sheetId => server.post(
  `/sheet/${sheetId}/work-session/stop`
)
export const getWorkSessionDetails = sheetId => server.get(
  `/sheet/${sheetId}/work-session`
)
export const updateWorkSessionPreferences = preferences => server.put(
  `/sheet/work-session/preferences`, preferences
)
