import { server } from '../server'

export const removeComment = async ({
  campaignId,
  commentLine,
}) => server.delete(`/campaign/${campaignId}/comment/${commentLine}`)

export const addComment = async ({ campaignId, comment }) =>
  server.post(`/campaign/${campaignId}/comments`, { comment })

export const updateComments = async ({ campaignIds, comments }) =>
  server.put(`/campaigns/comments`, { campaignIds, comments })

export const getComments = async (campaignId) =>
  server.get(`/campaign/${campaignId}/comments`)
