import { Campaign } from '../campaigns/stores/Campaign'
import { CurrentUser } from '../users/stores/CurrentUser'
import { View } from '../campaigns/stores/View'
import { BroadMatchStore } from '../broadMatch/BroadMatchStore'
import { ApplicationStore } from '../applicationPerformance/ApplicationStore'
import { SocketStore } from '../utilities/vueSockets/SocketStore'

export const MatcherCombinedStore = {
  modules: {
    application: {
      namespaced: true,
      ...ApplicationStore,
    },
    broadMatch: {
      namespaced: true,
      ...BroadMatchStore,
    },
    campaign: {
      namespaced: true,
      ...Campaign,
    },
    view: {
      namespaced: true,
      ...View,
    },
    currentUser: {
      namespaced: true,
      ...CurrentUser
    },
    socket: {
      namespaced: true,
      ...SocketStore(),
    },
  }
}
