import { server } from '../server'

export const getCampaignFactors = async (id) =>
  server.get(`/campaign/${id}/factors`)

export const updateCampaignFactorMultiplier = async ({
  campaignId,
  factors,
}) =>
  server.post(`/campaign/${campaignId}/factors`, {
    factors,
  })
export const addFactorMultipliersToCampaigns = async ({
  campaignIds,
  factors,
}) =>
  server.post(`/campaigns/factors`, {
    campaignIds,
    factors,
  })
export const initializeFactorMultipliers = async (campaignId) => server.post(
  `/campaign/${campaignId}/factors_record`,
)
export const setModelFactorsEnabled = async ({
  campaignId,
  enable,
}) =>
  server.put(
    `/campaign/${campaignId}/factors/${
      enable ? 'enable' : 'disable'
    }`,
  )

export const setCampaignModelEnabled = async ({
  campaignId,
  enable,
}) => server.post(`/campaign/${campaignId}/model_enabled`, { enable })

export const setFactorModelAttribute = async ({
  attribute,
  campaignId,
  ...options
}) =>
  server.post(`/campaign/${campaignId}/factors/${attribute}`, options)

export const setCampaignsFactorModelAttribute = async ({
  attribute,
  ...options
}) =>
  server.post(`/campaigns/factors/${attribute}`, options)

export const setCampaignModelType = async ({
  campaignId,
  modelType,
}) => server.post(`/campaign/${campaignId}/model_type`, { modelType })

export const setCampaignsModelType = async ({
  campaignIds,
  modelType,
}) =>
  server.post(`/campaigns/model_type`, {
    campaignIds,
    modelType,
  })
