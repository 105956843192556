import {
  getFactoryState,
  setFactoryState,
} from './actions'
import Logger from '../utilities/log'

const logger = Logger('FactoryStateStore')

export const FactoryStateStore = {
  state: {
    isBusy: null,
    isOpen: null,
    lastRun: null,
    loading: false,
  },
  mutations: {
    loading(state, val) {
      state.loading = !!val
    },
    factoryState(
      state,
      { isBusy = false, isOpen = false, lastRun = 0 }
    ) {
      state.isBusy = isBusy
      state.isOpen = isOpen
      state.lastRun = lastRun
    },
  },
  actions: {
    async get({ commit }) {
      const { data } = await getFactoryState()
      logger.info('get', data)
      commit('factoryState', data)
      return data.isOpen
    },
    async setTo({ dispatch }, value) {
      const { data: { success } } = await setFactoryState(value)
      await dispatch('get')
      return success
    },
  },
}
