<template lang="pug">
b-skeleton-wrapper(:loading='loading')
  //- Content shown when `loading` is `true`
  //- Skipping `v-if=$slots.loading` check. In some cases,
  //- default slot content with `v-if` causes `$slots.loading`
  //- to be undefined at the time of evaluation.
  template(#loading)
    slot(name='loading')

  //- Content shown when `loading` is `false`
  slot
</template>

<script>
export const SkeletonWrapper = {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
export default SkeletonWrapper
</script>
