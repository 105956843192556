import { sendMessageToSocket } from '../sendMessageToSocket'
import { getSocket } from '../vueSockets/getSocket'
import Logger from '../log'

export const SocketStore = () => {
  const logger = Logger(`SocketStore`)
  const socketActionNamePrefix = `socket_`
  logger.info('initialized', { socketActionNamePrefix })

  return {
    state() {
      return {
        component: 'common',
        socketConnected: false,
        socketId: null,
      }
    },
    getters: {
      id: state => state.socketId,
    },
    mutations: {
      socketConnected(state, payload) {
        state.socketConnected = payload
      },
      socketId(state, payload) {
        state.socketId = payload
      },
    },
    actions: {
      send(_context, { channel, ...data }) {
        logger.info(`send`, data)
        return sendMessageToSocket({
          channel,
          data,
        })
      },
      [`${socketActionNamePrefix}disconnect`]: {
        root: true,
        handler({ commit }) {
          logger.info('disconnect')
          commit('socketConnected', false)
        }
      },
      [`${socketActionNamePrefix}connect`]: {
        root: true,
        handler({ dispatch }, event) {
          logger.info('connect', event)
          return dispatch(`${socketActionNamePrefix}reconnect`, event, { root: true })
        }
      },
      [`${socketActionNamePrefix}reconnect`]: {
        root: true,
        handler({ commit, state }) {
          logger.info('reconnect')
          const socket = getSocket()
          logger.info('reconnect', socket.id)
          commit('socketId', socket.id)
          commit('socketConnected', true)
          socket.emit('authentication', {
            time: Date.now(),
            user: 'kawa-user',
            component: 'common',
          })
        }
      }
    },
  }
}
