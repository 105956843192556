/**
 * Returns a formatted cell value for row actions
 */
import formatNumber from '../../../format/number'

const defaultFormatter = v => formatNumber.fPrec(v)
export default (_v, { item, plainText }) => {
  const val = item.impact
  const valueValid = (
    val &&
    val.defined &&
    val.relative &&
    val.absolute &&
    !isNaN(val.absolute) &&
    !isNaN(val.relative)
  )

  if (valueValid) {
    const valPositive = (val.relative > 0)
    const relativeLabel = `${valPositive ? '+' : ''}${parseInt(val.relative * 100)}%`
    const absoluteLabel = `$${defaultFormatter(val.absolute)}`
    if (plainText) {
      return `${absoluteLabel} | ${relativeLabel}`
    }
    const labelClass = valPositive ? 'text-success' : 'text-warning'
    const string = (
      `${absoluteLabel}, ` +
      `<span class='${labelClass}'>${relativeLabel}</span>`
    )

    return string
  } else {
    return '-'
  }
}
