import { routes as boardRoutes } from '@/_board/routes'
import { routes as campaignerRoutes } from '@/_campaigner/routes'
import { routes as epochRoutes } from '@/_epochs/routes'
import { routes as factoryRoutes } from '@/_factory/routes'
import { routes as keywordsRoutes } from '@/_keywords/routes'
import { routes as matcherRoutes } from '@/_matcher/routes'
import { routes as optimRoutes } from '@/_optim/routes'
import { routes as sheetRoutes } from '@/_sheet/routes'

const genRoute = ({ page, route: { path } }) =>
  path === '/' ? `/${page}/` : `/${page}/#${path}`
const generateRoutes = ({ page, routes }) =>
  routes
    .filter((r) => !!r.path)
    .map((route) => genRoute({ page, route }))

export { createRouter } from './createRouter'
export const routes = [
  // Single route
  ...generateRoutes({ routes: [{ path: '/' }], page: 'blank' }),
  ...generateRoutes({ routes: [{ path: '/' }], page: 'hasuki' }),
  ...generateRoutes({ routes: [{ path: '/' }], page: 'login' }),
  ...generateRoutes({ routes: [{ path: '/' }], page: 'lookeradmin' }),
  // Multi route pages
  ...campaignerRoutes.map((route) => `/#${route.path}`),
  ...generateRoutes({ routes: boardRoutes, page: 'board' }),
  ...generateRoutes({ routes: epochRoutes, page: 'epochs' }),
  ...generateRoutes({ routes: factoryRoutes, page: 'factory' }),
  ...generateRoutes({ routes: keywordsRoutes, page: 'keywords' }),
  ...generateRoutes({ routes: matcherRoutes, page: 'matcher' }),
  ...generateRoutes({ routes: optimRoutes, page: 'optim' }),
  ...generateRoutes({ routes: sheetRoutes, page: 'sheet' }),
]
