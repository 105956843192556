<template lang="pug">
Icon(name='caret-right-square-fill')
</template>

<script>
import { Icon } from '../../components/Icon.vue'
export const CampaignDetailsIcon = {
  components: {
    Icon,
  },
}
export default CampaignDetailsIcon
</script>
