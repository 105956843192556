<template lang="pug">
div
  KawaFooter
  KawaNavigation(
    title='Campaigner'
    variant='primary'
    :routes='campaignerRoutes'
  )
    NavbarNav.ml-auto(
      v-if='isCurrentlyAuthorized'
    )
      NavItem(v-if='campaignId')
        Button(
          title='Click to enter a different campaign'
          @click='clearCampaignSelection'
        )
          Icon(name='x-circle')
          span.ml-1 Campaign #[span.text-primary {{ campaignId }}]
            span(v-if='propertiesLoaded')  {{ campaignProperties.name_short }}
      NavItem
        Dropdown(
          lazy
          right
          variant='primary'
        )
          template(#button-content)
            Icon(
              name='list-ul'
              label='Recently viewed'
            )
          RecentList(
            component='campaign'
            :mapper='mapCampaignLink'
            title='Recently viewed'
          )
          CampaignsActionedList(
            :mapper='mapCampaignLink'
            title='Actioned'
          )
  Container.mt-2.mb-5(fluid)
    VueNotifications
    router-view
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Router from 'vue-router'
import { campaignIdIsValid } from '../campaigns/campaignIdIsValid'
import { Button } from '@/components/Button'
import { Container } from '@/components/Layout'
import { Dropdown } from '@/components/Dropdown'
import { Icon } from '@/components/Icon.vue'
import { KawaNavigation } from '@/components/KawaNavigation.vue'
import { KawaFooter } from '@/components/KawaFooter.vue'
import { NavItem } from '@/components/Nav'
import { NavbarNav } from '@/components/Navbar'
import CampaignIdInput from '../campaigns/components/CampaignIdInput.vue'
import CampaignsActionedList from '../campaigns/components/CampaignsActionedList.vue'
import RecentList from '@/components/RecentList.vue'
import { update as updateTitle } from '@/utilities/title'
import { getCampaignDetailsLink } from '../campaigns/getCampaignDetailsLink'
import { routes } from './routes'

export default {
  components: {
    Button,
    CampaignIdInput,
    CampaignsActionedList,
    Container,
    Dropdown,
    Icon,
    KawaFooter,
    KawaNavigation,
    NavItem,
    NavbarNav,
    RecentList,
  },
  computed: {
    ...mapGetters('campaign', [
      'campaignId',
      'campaignProperties',
      'propertiesLoaded',
    ]),
    ...mapGetters('currentUser', ['isCurrentlyAuthorized']),
    routedCampaignId() {
      return +this.$route.params.id
    },
    routedCampaignIdIsValid() {
      return campaignIdIsValid(this.routedCampaignId)
    },
    routeIsCampaignLink() {
      return this.$route.path.includes('/campaign/')
    },
  },
  created() {
    this.preload()
  },
  data() {
    return {
      campaignerRoutes: routes,
    }
  },
  methods: {
    ...mapActions('campaign', ['clear', 'select']),
    ...mapActions('flags', ['getFlags']),
    ...mapActions('currentUser', {
      loadCurrentUser: 'get',
      loadPreferences: 'getPreferences',
    }),
    ...mapActions('view', {
      loadCurrentView: 'get',
    }),
    clearCampaignSelection() {
      this.$router.push({ path: '/' })
      this.clear()
    },
    mapCampaignLink({ id }) {
      return {
        id,
        linkTo: getCampaignDetailsLink(id),
        linkHref: getCampaignDetailsLink(id),
      }
    },
    async preload() {
      await this.loadCurrentUser()
      await this.loadPreferences('campaigner')
      await this.loadCurrentView()
      await this.getFlags()
    },
  },
  mounted() {
    if (this.routedCampaignIdIsValid) {
      this.select({ campaignId: this.routedCampaignId })
    }
  },
  updated() {
    if (this.campaignId) {
      updateTitle(`${this.campaignId} | Kawa`)
    }
  },
  watch: {
    routedCampaignId(campaignId) {
      if (campaignIdIsValid(campaignId)) {
        return this.select({ campaignId })
      }
      if (
        this.routeIsCampaignLink &&
        !campaignIdIsValid(campaignId)
      ) {
        this.$notify({
          text: 'Campaign ID is invalid',
          type: 'warning',
        })
        return this.clearCampaignSelection()
      }
      if (!this.routeIsCampaignLink) {
        return this.clear()
      }
    },
  },
}
</script>
