const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
}

const rtf = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto',
  style: 'short'
})

export const formatTimeRelative = (d1, d2 = new Date()) => {
  if (!d1) return 'Never'
  const elapsed = d1 - d2
  for (const u of Object.keys(units)) {
    if (Math.abs(elapsed) > units[u] || u === 'second') {
      return rtf.format(Math.round(elapsed / units[u]), u)
    }
  }
}
