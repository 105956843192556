import Ui from './Ui.vue'
import { EpochsCombinedStore } from './EpochsCombinedStore'
import { mountVuePage } from '../mountVuePage'
import { routes } from './routes'

mountVuePage({
  rootComponent: Ui,
  routes,
  store: EpochsCombinedStore,
  title: 'Ranking epochs',
})
