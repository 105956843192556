<template lang="pug">
b-button-group(
  :size='size'
)
  slot
</template>
<script>
import { componentSizes } from '../constants'

export const ButtonGroup = {
  props: {
    size: {
      type: String,
      default: null,
      validator: value => componentSizes.includes(value),
    },
  },
}
export default ButtonGroup
</script>
