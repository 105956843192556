export const getRowQuickSearchString = ({
  activeAdTargetUrl,
  adops_assignment_name,
  campaign_id,
  cs_assignment_name,
  hierarchy_chain,
  hierarchyNameUi,
  name,
}) => (
  `${campaign_id}:${name}:` +
  `${activeAdTargetUrl}:` +
  `${hierarchyNameUi}:` +
  `${hierarchy_chain}:` +
  `${adops_assignment_name}:${cs_assignment_name}`
)
