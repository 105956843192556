<template lang="pug">
BFile(
  :accept="accept"
  :disabled="disabled"
  :required="required"
  :placeholder="placeholder"
  :state="state"
  v-model="valueInput"
)
</template>
<script>
export const FileInput = {
  data() {
    return {
      valueInput: this.value,
    }
  },
  methods: {
    syncValue(v) {
      if (this.valueInput === v) return
      this.valueInput = v
    },
  },
  props: {
    accept: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Select file...',
    },
    required: {
      type: Boolean,
      default: false,
    },
    state: Boolean,
    value: {
      type: String,
    },
  },
  watch: {
    valueInput() {
      this.$emit('input', this.valueInput)
    },
    value: 'syncValue',
  },
}

export default FileInput
</script>
