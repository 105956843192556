<template lang="pug">
BDropdownForm {{ text }}
  slot
</template>
<script>
import { BDropdownForm } from 'bootstrap-vue'

export const DropdownForm = {
  components: {
    BDropdownForm,
  },
  props: {
    disabled: Boolean,
    inline: Boolean,
    text: String,
  }
}
export default DropdownForm
</script>
