export const parseUrlQuery = (url) => {
  const result = {}
  const query = url.split('?')[1]
  if (query) {
    query.split('&').forEach((pair) => {
      const [key, value] = pair.split('=')
      result[key] = value
    })
  }
  return result
}
