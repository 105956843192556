import formatPercentage from '../../format/percentage'
import { dollar } from '../../format/string'
import { getSeededColor } from '../utilities/getColor'

const defaultIntFormat = v => `${parseInt(v)}`

const CONSTANTS = {
  uiAttributesByDimension: {
    clicks: {
      color: getSeededColor(7),
      abbrev: 'CL',
      format: defaultIntFormat,
    },
    cpa_actions: {
      color: getSeededColor(15),
      abbrev: 'CA',
      format: defaultIntFormat,
    },
    cpm: {
      color: getSeededColor(14), abbrev: 'CP', format: dollar
    },
    ctr: {
      color: getSeededColor(13),
      abbrev: 'CT',
      format: v => formatPercentage.float(v * 100)
    },
    daily_limit: {
      abbrev: 'DL',
      affinity: 1,
      color: getSeededColor(11),
      format: dollar,
    },
    edits_all: {
      abbrev: 'EA',
      affinity: 2,
      color: getSeededColor(8),
      format: defaultIntFormat,
    },
    edits_me: {
      abbrev: 'EM',
      affinity: 2,
      color: getSeededColor(9),
      format: defaultIntFormat
    },
    impressions: {
      color: getSeededColor(5), abbrev: 'IM', format: defaultIntFormat
    },
    spend: {
      abbrev: 'SP',
      affinity: 1,
      color: getSeededColor(6),
      format: dollar
    },
    telemetry: {
      color: getSeededColor(10), abbrev: 'TE', format: v => formatPercentage.int(v / 100)
    },
    vcr: {
      color: getSeededColor(12), abbrev: 'VC', format: v => formatPercentage.int(v)
    },
    // 16, 17, 18...
  }
}

export const constants = CONSTANTS
export const dimensions = Object.keys(CONSTANTS.uiAttributesByDimension)
