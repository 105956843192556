import { server } from '../../server'

export default {
  actionCampaign({ name, campaignId, parameters }) {
    return server.post(`/campaign/${campaignId}/action/${name}`, parameters)
  },
  actionCampaigns(actions) {
    return server.post('/campaigns/actions', { actions })
  },
  ignoreCampaign(campaignId, options) {
    return server.post(`/campaign/${campaignId}/action/ignore`, options)
  },
  acknowledgeCampaign(campaignId) {
    return server.post(`/campaign/${campaignId}/action/acknowledge`)
  },
  claimCampaign(campaignId) {
    return server.post(`/campaign/${campaignId}/action/claim`)
  },
  sealCampaign(campaignId) {
    return server.post(`/campaign/${campaignId}/action/seal`)
  },
  unsealCampaign(campaignId) {
    return server.post(`/campaign/${campaignId}/action/unseal`)
  },
}
