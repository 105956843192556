<template lang="pug">
Container
  h2 Listing and removals
  Alert(variant='secondary') Enter campaign ID and view matching campaign keywords (You can add a filter to match keywords, examples: car*, *car*, *car).
  InputGroup
    template(#prepend)
      InputGroupText Campaign ID
      Dropdown(
        :disabled='loading'
        text=''
        v-if='typeof mapCampaignLink === "function"'
      )
        RecentList(
          component='campaign'
          :mapper='mapCampaignLink'
          title='Recent campaigns'
          @select='onSelectRecentCampaign'
        )
    FormInput(
      class="rounded-right"
      :disabled='loading || keywords.length > 0'
      v-model='campaignIdInput'
      placeholder='Campaign ID'
      :state='campaignIdIsValid'
    )
    InputGroupPrepend.ml-2
      InputGroupText.rounded-left Filter
    FormInput(
      :disabled='loading'
      :state='filterInputIsValid'
      placeholder='Leave blank to list all'
      v-model='filterInput'
    )
    template(#append)
      ButtonGroup
        Button(
          @click='handleListing'
          variant='primary'
          :disabled='loading || !inputIsValid'
        ) List
        Button(
          @click='handleClear'
          variant='secondary'
        ) Reset
  Loader(v-if='loading')
  .pt-2(v-if='keywordCount > 0')
    Alert(
      variant='success'
    ) Campaign has keywords ({{ keywordCount }})
    Alert(
      v-if='!keywords.length'
      variant='warning'
    ) No keywords match the filter.
    Table(
      :items='keywordRows'
      :fields='keywordFields'
      v-else
      paginated
      selectable
      @selected='onKeywordsSelected'
    )
      template(#cell(bid)='{ item, value }')
        span {{ value > 0 ? `$${value}` : 'Default' }}
      template(#controls)
        ButtonGroup.ml-2(
          v-if='downloadId > 0'
        )
          Caption
            DownloadUrl(
              :downloadId='downloadId'
            )
          Button(
            v-if='selectedKeywordsWithBids.length'
            :disabled='loading'
            @click='onRemoveSelectedKeywordBids'
            variant='warning'
          ) Remove bids
            Badge.ml-1 {{ selectedKeywordsWithBids.length }}
          Button(
            v-if='selectedKeywords.length'
            :disabled='loading || !canRemoveKeywordCount(selectedKeywords.length)'
            @click='onRemoveSelectedKeywords'
            variant='primary'
          ) Remove
            Badge.ml-1 {{ selectedKeywords.length }}
      template(#cell(actions)='{ item }')
        Button(
          v-if='item.bid !== null'
          :disabled='loading'
          @click='onRemoveKeywordBid(item)'
        )
          Icon.text-warning(
            name='x'
            :title='`Reset keyword bid [$${item.bid}]`'
            :label='`$${item.bid}`'
            style='text-decoration: line-through;'
          )
        Button.text-primary.ml-1(
          :disabled='loading || !canRemoveKeywordCount(1)'
          @click='onRemoveKeyword(item)'
        )
          Icon(name='x' title='Remove keyword')
  Alert(
    v-if='keywordCount === 0'
    variant='danger'
  ) Campaign has no keywords.


</template>
<script>
import { update } from '@/utilities/title'
import { DownloadUrl } from '@/components/DownloadUrl.vue'
import { Alert } from '@/components/Alert.vue'
import { Badge } from '@/components/Badge.vue'
import { Button } from '@/components/Button'
import { ButtonGroup } from '@/components/Button'
import { Caption } from '@/components/Caption.vue'
import { Container } from '@/components/Layout'
import { Dropdown } from '@/components/Dropdown'
import { FormInput } from '@/components/FormInput.vue'
import { Icon } from '@/components/Icon.vue'
import {
  InputGroup,
  InputGroupPrepend,
  InputGroupText,
} from '@/components/InputGroup'
import { Loader } from '@/components/Loader.vue'
import { RecentList } from '@/components/RecentList.vue'
import { Table } from '@/components/Table.vue'
import server from '../server'
import {
  removeKeywordsFromCampaigns,
  resetBids,
} from '../campaigns/actions/keywords'

const addRecentCampaign = async (id) =>
  server.post('/recents/campaign', {
    value: id,
  })

export const KeywordListing = {
  components: {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Caption,
    Container,
    DownloadUrl,
    Dropdown,
    FormInput,
    Icon,
    InputGroup,
    InputGroupPrepend,
    InputGroupText,
    Loader,
    RecentList,
    Table,
  },
  computed: {
    campaignId() {
      return parseInt(this.campaignIdInput)
    },
    campaignIdIsValid() {
      return this.campaignId > 0
    },
    filterInputIsValid() {
      return (
        this.filterInput.length >= 0 && this.filterInput.length < 50
      )
    },
    inputIsValid() {
      return this.campaignIdIsValid && this.filterInputIsValid
    },
    keywordFields() {
      return [
        { key: 'name', label: 'Keyword', sortable: true },
        { key: 'bid', label: 'Bid', sortable: true },
        { key: 'actions', label: '' },
      ]
    },
    keywordRows() {
      return this.keywords
    },
    selectedKeywordsWithBids() {
      return this.selectedKeywords.filter(({ bid }) => bid > 0)
    },
  },
  data() {
    return {
      campaignIdInput: null,
      downloadId: null,
      filterInput: '',
      keywords: [],
      keywordCount: null,
      selectedKeywords: [],
      loading: false,
    }
  },
  methods: {
    canRemoveKeywordCount(count) {
      return this.keywordCount > count
    },
    handleClear() {
      this.loading = false
      this.campaignIdInput = null
      this.filterInput = ''
      this.resetData()
    },
    mapCampaignLink: ({ id }) => ({
      id,
      linkTo: undefined,
      linkHref: undefined,
    }),
    onKeywordsSelected({ items }) {
      this.selectedKeywords = items
    },
    async onRemoveKeyword({ name_raw: keyword, name }) {
      if (!confirm(`Remove keyword [${name}]?`)) return
      this.$notify({
        message: `Removing keyword [${name}]...`,
        type: 'primary',
      })
      this.loading = true
      const {
        data: { message, success },
      } = await removeKeywordsFromCampaigns({
        campaignIds: [this.campaignId],
        keywords: [keyword],
      })
      this.$notify({ message, success })
      this.$emit('removeKeyword', keyword)
      this.loading = false
      return this.handleListing()
    },
    async onRemoveKeywordBid({ md5, name }) {
      this.loading = true
      this.$notify({
        type: 'info',
        message: `Reseting keyword [${name}] bid.`,
      })
      const {
        data: { success, message },
      } = await resetBids({
        campaignId: this.campaignId,
        keywords: [md5],
      })
      this.$notify({ success, message })
      this.loading = false
      return this.handleListing()
    },
    async onRemoveSelectedKeywordBids() {
      const confirmed = confirm(
        `Remove bids for selected keywords with bids ` +
        `(${this.selectedKeywordsWithBids.length})?`,
      )
      if (!confirmed) return
      this.$notify({
        message: 'Removing selected keyword bids...',
        type: 'primary',
      })
      this.loading = true
      const {
        data: { success, message },
      } = await resetBids({
        campaignId: [this.campaignId],
        keywords: this.selectedKeywordsWithBids.map((k) => k.md5),
      })
      this.$notify({ success, message })
      this.loading = false
      return this.handleListing()
    },
    async onRemoveSelectedKeywords() {
      if (
        !confirm(
          `Remove selected keywords (${this.selectedKeywords.length})?`,
        )
      )
        return
      this.$notify({
        message: 'Removing selected keywords...',
        type: 'primary',
      })
      this.loading = true
      const {
        data: { success, message },
      } = await removeKeywordsFromCampaigns({
        campaignIds: [this.campaignId],
        keywords: this.selectedKeywords.map((k) => k.name_raw),
      })
      this.$notify({ success, message })
      this.loading = false
      return this.handleListing()
    },
    onSelectRecentCampaign(id) {
      this.campaignIdInput = id
    },
    async handleListing() {
      this.loading = true
      this.resetData()
      await addRecentCampaign(this.campaignId)
      const {
        data: { downloadId, keywords, keywordCount },
      } = await server.get(
        `/campaign/${this.campaignId}/keywords_view`,
        {
          params: {
            filterBy: this.filterInput,
          },
        },
      )
      this.downloadId = downloadId
      this.keywords = keywords
      this.keywordCount = keywordCount
      this.loading = false
    },
    resetData() {
      this.keywords = []
      this.selectedKeywords = []
      this.keywordCount = null
      this.downloadId = null
    },
  },
  mounted() {
    update('Listing & Removals | Kawa')
  },
  watch: {
    campaignId() {
      this.keywords = []
      this.keywordCount = null
    },
  },
}

export default KeywordListing
</script>
