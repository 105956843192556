import objectHasProperty from '../../utilities/objectHasProperty'

const filterFor = ({ goal, goalTarget, direction }, row) => {
  if (goal === 'ctr') {
    return direction > 0
      ? goalTarget <= row.ctr
      : goalTarget > row.ctr
  } else if (goal === 'cpm') {
    return direction > 0
      ? goalTarget > row.cpm && row.cpm !== 0
      : goalTarget <= row.cpm || row.cpm === 0
  } else if (goal === 'vcr') {
    return direction > 0
      ? goalTarget <= row.vcr
      : goalTarget > row.vcr
  } else if (goal === 'cpc') {
    return direction > 0
      ? goalTarget > row.ecpc && row.ecpc !== 0
      : goalTarget <= row.ecpc || row.ecpc === 0
  } else if (goal === 'ecpa') {
    return direction > 0
      ? goalTarget > row.ecpa && row.ecpa !== 0
      : goalTarget <= row.ecpa || row.ecpa === 0
  } else if (goal === 'none') {
    return true
  } else return false
}

const determineItemIsRemoved = (report, item) => {
  if (report.isKeywordReport) {
    return !item.bid && !item.category
  } else if (report.isDomainReport) {
    return report.domainListType === 'blocklist'
      ? item.listed || item.globallyBlocklisted
      : !(item.listed || item.globallyBlocklisted)
  } else if (report.isAdReport) {
    return item.isDisabled
  } else if (objectHasProperty(item, 'present')) {
    return !item.present
  } else return false
}

export const filterCampaignReportRows = (
  report,
  filterOptions = {}
) => {
  const { enabled, impressionLimit } = filterOptions

  return enabled
    ? report.data.filter(row => {
      const matchesImpressionLimit =
        row.impressions > impressionLimit

      if (
        matchesImpressionLimit &&
        !determineItemIsRemoved(report, row)
      ) {
        return filterFor(filterOptions, row)
      } else return false
    })
    : report.data
}
