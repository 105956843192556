<template lang="pug">
Navbar#kawa-footer.border-0(
  toggleable='md'
  fixed='bottom'
  :variant='variant'
  type='dark'
)
  Popover(
    v-if='infoObtained'
    target='footerInfo'
    placement='top'
    triggers='hover'
  )
    template(#title) Kawa application info
    List(flush)
      ListItem(active) Postgres
      ListItem  {{ postgres.running }} running
      ListItem  {{ formatPercent(postgres.utilization) }}% utilized
      ListItem  {{ formatPercent(postgres.overflow) }}% overflow
      ListItem(active) Vertica
      ListItem  {{ vertica.running }} running
      ListItem  {{ formatPercent(vertica.utilization) }}% utilized
      ListItem  {{ formatPercent(vertica.overflow) }}% overflow
    List(
      v-if='reportingQueueLength > 0'
      flush
    )
      ListItem(active) Reports
      ListItem {{ reportingQueueLength }} running
      ListItem {{ reportsCompleted }} complete
  span(
    v-if='infoObtained'
    id='footerInfo'
  )
    span.ml-2
      Icon(
        name='clock'
        label='Time:'
      ).mr-1
      strong {{ serverTimeLocal }}
    span.ml-2
      Icon(
        name='calendar'
        label='Reporting day:'
      ).mr-1
      strong {{ reportingDay }}
    span.ml-2
      Icon(
        name='square-fill'
        label='Postgres'
        :class='`text-${dbStatusClass("postgres")}`'
      )
    span.ml-2
      Icon(
        name='square-fill'
        label='Vertica'
        :class='`text-${dbStatusClass("vertica")}`'
      )
    span.ml-2(:class='versionClass')
      Icon(
        name="code"
        :label="`v${serverVersion} PID: ${processId}`"
      )
  slot
  SocketConnectedIndicator.ml-auto
</template>
<script>
import { Icon } from './Icon.vue'
import { List } from './List.vue'
import { ListItem } from './ListItem.vue'
import { Navbar } from './Navbar'
import { Popover } from './Popover.vue'
import SocketConnectedIndicator from '../sockets/SocketConnectedIndicator.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export const KawaFooter = {
  components: {
    Icon,
    List,
    ListItem,
    Navbar,
    Popover,
    SocketConnectedIndicator,
  },
  computed: {
    ...mapState('currentUser', [ 'processId' ]),
    ...mapState('socket', [ 'socketConnected' ]),
    ...mapState('application', [
      'postgres',
      'reportingDay',
      'reportingHour',
      'reportingQueueLength',
      'reportsCompleted',
      'serverVersion',
      'vertica',
    ]),
    ...mapGetters('application', [
      'dbStatusClass',
      'infoObtained',
      'serverTimeLocal',
      'versionsMatch',
    ]),
    versionClass() {
      return this.versionsMatch
        ? 'text-secondary'
        : 'text-danger'
    },
  },
  methods: {
    ...mapActions('currentUser', ['getIfNotLoaded']),
    formatPercent(value) {
      return value > 0 ? parseInt(value * 100) : 0
    },
  },
  mounted() {
    this.getIfNotLoaded()
  },
  props: {
    variant: {
      type: String,
      default: 'secondary',
    },
  },
}
export default KawaFooter
</script>