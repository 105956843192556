import { server } from '../server'

export const getFactoryWorkers = async filterOptions =>
  server.get('/factory/workers', {
    params: filterOptions,
  })
export const createFactoryWorkers = async ({
  activities,
  activityProfiles,
  campaignIds,
  overwriteExisting,
}) =>
  server.post('/factory/workers', {
    activities,
    activityProfiles,
    campaignIds,
    overwriteExisting,
  })

export const actionWorker = async ({ action, campaignId }) =>
  server.get(`/factory/worker/${campaignId}/${action}`)
export const removeWorker = async campaignId =>
  server.delete(`/factory/worker/${campaignId}`)
export const removeWorkers = async campaignIds =>
  server.delete(`/automation/workers`, {
    data: {
      campaignIds,
    },
  })
export const removeUserWorkers = async userEmail =>
  server.delete(`/user/workers`, {
    params: {
      userEmail,
    },
  })
export const getWorkerDetails = async campaignId =>
  server.get(`/factory/worker/${campaignId}`)
export const updateActivityProfile = async ({
  activity,
  payload,
  profileKey,
  profileName,
}) =>
  server.put(`/factory/profile/${profileKey}/${activity}`, {
    name: profileName,
    payload,
  })
export const getActivityProfile = async ({ activity, profileKey }) =>
  server.get(`/factory/profile/${profileKey}/${activity}`)
export const getProfiles = async email => server.get(`/factory/profiles`, {
  params: { email },
})
export const getActivityProfiles = async ({ activity, email }) =>
  server.get(`/factory/profiles/${activity}`, {
    params: { email },
  })
export const removeActivityProfile = async ({
  activity,
  profileKey,
}) => server.delete(`/factory/profile/${profileKey}/${activity}`)
