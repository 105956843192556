import server from '../server'

export const KeywordSetsStore = {
  state: {
    keywordSets: [],
    keywordSetsLoading: false,
    myPointers: [],
    foreignPointers: [],
    selectedKeywordSet: null,
  },
  getters: {
    selectedKeywordSetPointer({ myPointers, selectedKeywordSet }) {
      return myPointers.find(
        (p) => p.set === selectedKeywordSet?.setName,
      )
    },
    selectedHasOwnPointer(_state, { selectedKeywordSetPointer }) {
      return !!selectedKeywordSetPointer
    },
  },
  mutations: {
    setKeywordSets(state, keywordSets) {
      state.keywordSets = keywordSets
    },
    setKeywordSetsLoading(state, loading) {
      state.keywordSetsLoading = loading
    },
    setPointers(state, { foreignPointers, myPointers }) {
      state.foreignPointers = foreignPointers
      state.myPointers = myPointers
    },
    setSelectedKeywordSet(state, set) {
      state.selectedKeywordSet = set
    },
  },
  actions: {
    async createKeywordSet({ commit, dispatch }, keywordSet) {
      commit('setKeywordSetsLoading', true)
      const {
        data: { success, message, name },
      } = await server.post('/keywords/set', keywordSet)
      commit('setKeywordSetsLoading', false)
      await dispatch('getKeywordSets')
      await dispatch('selectKeywordSet', name)

      return { success, message, name }
    },
    async createKeywordSetPointer(
      { commit, dispatch },
      { set, title },
    ) {
      commit('setKeywordSetsLoading', true)
      const { message, success } = await server.post(
        '/keywords/set/pointer',
        {
          setName: set,
          pointer: title,
        },
      )
      commit('setKeywordSetsLoading', false)
      await dispatch('reload')

      return { success, message }
    },
    async selectKeywordSet({ commit }, name) {
      commit('setKeywordSetsLoading', true)
      const { data: keywordSet } = await server.get('/keywords/set', {
        params: { name },
      })
      commit('setSelectedKeywordSet', keywordSet)
      commit('setKeywordSetsLoading', false)

      return keywordSet
    },
    async getKeywordSetPointers({ commit }) {
      commit('setKeywordSetsLoading', true)
      const {
        data: { foreignPointers, myPointers },
      } = await server.get('/keywords/set/pointers')
      commit('setPointers', { foreignPointers, myPointers })
      commit('setKeywordSetsLoading', false)
    },
    async getKeywordSets({ commit }) {
      commit('setKeywordSetsLoading', true)
      const {
        data: { keywordSets },
      } = await server.get('/keywords/sets')
      commit('setKeywordSets', keywordSets)
      commit('setKeywordSetsLoading', false)
    },
    async reload({ commit, dispatch }) {
      commit('setKeywordSetsLoading', true)
      await dispatch('getKeywordSets')
      await dispatch('getKeywordSetPointers')
      commit('setKeywordSetsLoading', false)
    },
    async removeSelectedPointer({ commit, getters, dispatch }) {
      commit('setKeywordSetsLoading', true)
      const {
        data: { success, message },
      } = await server.post('/keywords/pointer/remove', {
        name: getters.selectedKeywordSetPointer?.name,
      })
      commit('setKeywordSetsLoading', false)
      await dispatch('reload')
      return { success, message }
    },
  },
}
