<template lang="pug">
b-navbar(
  :fixed='fixed'
  :toggleable='toggleable'
  :type='type'
  :variant='variant'
)
  slot
</template>
<script>
import {
  navbarBreakpoints,
  navbarFixedPos,
  navbarTypes,
} from '../constants'

export const Navbar = {
  props: {
    fixed: {
      type: String,
      default: null,
      validator: f => navbarFixedPos.includes(f),
    },
    toggleable: {
      type: String,
      default: null,
      validator: t => navbarBreakpoints.includes(t),
    },
    type: {
      type: String,
      default: 'light',
      validator: t => navbarTypes.includes(t),
    },
    variant: {
      type: String,
      default: null,
    },
  },
}
export default Navbar
</script>
