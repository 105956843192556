<template lang="pug">
BTab(
  :active='active'
  :disabled='disabled'
  :lazy='lazy'
  :tabindex='tabindex'
  :title='title'
  :title-link-class='titleLinkClass'
  :variant='variant'
  @click='onTabClick'
)
  template(
    v-if='$slots["title"]'
    #title
  )
    slot(name='title')
  slot
</template>
<script>
export const Tab = {
  methods: {
    onTabClick() {
      this.$emit('click')
    },
  },
  props: {
    active: Boolean,
    disabled: Boolean,
    lazy: Boolean,
    tabindex: Number,
    title: {
      type: String,
      default: '',
    },
    titleLinkClass: {
      type: String | Object,
      default: '',
    },
    variant: {
      type: String,
      default: 'secondary',
    },
  },
}
export default Tab
</script>